import { useEffect, useState, isValidElement } from 'react';

import { Typography } from '@mui/material';

import StackedButtonOptions from '../fragments/StackedButtonOptions';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';

interface SelectOptionsDrawerProps extends DrawerComponentProps {
    multiSelect?: boolean;
    keyName?: string;
    options: { value: string; label: string; isSelected?: boolean }[];
    description: string | React.ReactNode;
    content?: React.ReactNode;
}

function SelectOptionsDrawer(props: SelectOptionsDrawerProps) {
    const {
        multiSelect = false,
        keyName = 'value',
        onClose,
        onNext,
        options = [],
        description,
        title = 'Select option',
        content,
    } = props;

    // Only used for multi-select
    const [currentOptions, setCurrentOptions] = useState(options);

    const handleSelectOption = (selected: any) => {
        if (multiSelect) {
            setCurrentOptions(
                currentOptions.map((option) => ({
                    ...option,
                    isSelected: option.value === selected ? !option.isSelected : option.isSelected,
                }))
            );
        } else {
            onNext({ [keyName]: selected });
        }
    };

    const handleMultiSelectConfirm = () => {
        const selectedValues = currentOptions
            .filter((option) => option.isSelected)
            .map((option) => option.value);
        onNext({ value: selectedValues });
    };

    useEffect(() => {
        setCurrentOptions(options);
    }, [options]);

    return (
        <>
            <DrawerTitle title={title}>
                {isValidElement(description) ? (
                    description
                ) : (
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'gray.900',
                        }}
                    >
                        {description}
                    </Typography>
                )}
            </DrawerTitle>
            {content && <DrawerBody>{content}</DrawerBody>}
            <DrawerFooter>
                <StackedButtonOptions
                    cancelAction={multiSelect ? undefined : { onSelect: onClose }}
                    onSelect={handleSelectOption}
                    options={currentOptions}
                />
                {multiSelect && (
                    <StackedButtonOptions
                        cancelAction={{ onSelect: onClose }}
                        onSelect={handleMultiSelectConfirm}
                        options={[{ value: '', label: 'Confirm', isPrimary: true }]}
                    />
                )}
            </DrawerFooter>
        </>
    );
}

export default SelectOptionsDrawer;
