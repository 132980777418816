/* eslint-disable no-param-reassign */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumEvent } from '@datadog/browser-rum';

// Reference: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#modify-the-content-of-a-rum-event
const beforeSend = (event: RumEvent) => {
    // If is a RumActionEvent
    if (event.type === 'action') {
        // If the action name is 'discard action', discard the event
        const actionName = event.action?.target?.name || '';
        if (actionName === 'discard action') {
            return false;
        }

        // If the action name is too long, discard the event
        if (actionName?.length > 30) {
            return false;
        }

        // If the action type is not custom, discard the event
        if (event.action?.type !== 'custom') {
            return false;
        }
    }

    // If is a RumViewEvent
    if (event.type === 'view') {
        if (event.view?.url) {
            // Redace tokens from event.view.url
            event.view.url = event.view.url.replace(/access_token=[^&]*/, 'access_token=REDACTED');
            event.view.url = event.view.url.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        }

        if (event.view?.referrer) {
            // Redact tokens from event.view.referrer
            event.view.referrer = event.view.referrer.replace(
                /access_token=[^&]*/,
                'access_token=REDACTED'
            );
            event.view.referrer = event.view.referrer.replace(
                /id_token=[^&]*/,
                'id_token=REDACTED'
            );
        }
    }

    // If is a RumErrorEvent
    if (event.type === 'error') {
        // Redact tokens from event.error.resource.url
        if (event.error?.resource) {
            event.error.resource.url = event.error.resource.url.replace(
                /access_token=[^&]*/,
                'access_token=REDACTED'
            );
            event.error.resource.url = event.error.resource.url.replace(
                /id_token=[^&]*/,
                'id_token=REDACTED'
            );
        }
    }

    return true;
};

// Reference: https://docs.datadoghq.com/real_user_monitoring/browser/#npm
if (import.meta.env.PROD) {
    datadogRum.init({
        applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        site: import.meta.env.VITE_DATADOG_SITE,
        service: import.meta.env.VITE_DATADOG_SERVICE,
        env: import.meta.env.VITE_ENV,
        version: import.meta.env.VITE_VERSION,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        beforeSend,
        allowedTracingUrls: [
            import.meta.env.VITE_SAGE_ADMIN_BASE_URL,
            import.meta.env.VITE_SAGE_INSIGHTS_ENGINE_BASE_URL,
            import.meta.env.VITE_SAGE_MESSAGE_ORCHESTRATION_BASE_URL,
            import.meta.env.VITE_SAGE_TABULAR_RECORD_SERVICE_BASE_URL,
        ],

        // Session Replay Properties
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        startSessionReplayRecordingManually: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogLogs.init({
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        site: import.meta.env.VITE_DATADOG_SITE,
        service: import.meta.env.VITE_DATADOG_SERVICE,
        env: import.meta.env.VITE_ENV,
        version: import.meta.env.VITE_VERSION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });
}

export default datadogRum;
