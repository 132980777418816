import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import ResidentAvatar, { ResidentAvatarProps } from 'src/component-library/ResidentAvatar';
import HospiceChip from 'src/components/fragments/HospiceChip';
import { CareTypeLabelsAbbreviated } from 'src/constants/CareTypes';
import { HospiceStatus } from 'src/constants/HospiceStatus';
import useUserPermissions from 'src/hooks/useUserPermissions';
import { Unit } from 'src/store/sageAdminApi';
import { isHospice } from 'src/utils/HospiceUtils';

export interface ResidentProps extends ResidentAvatarProps {
    unit?: Unit;
    hospiceStatus?: HospiceStatus;
    showCareType?: boolean;
}

function Resident(props: ResidentProps) {
    const {
        resident,
        unit,
        children,
        hospiceStatus = HospiceStatus.UNKNOWN,
        showCareType = false,
    } = props;

    const { canSeePHI } = useUserPermissions();

    const residentName = `${resident?.firstName || ''} ${resident?.lastName || ''}`.trim();

    const subTitle = useMemo(() => {
        const unitText = unit?.displayName || 'No Unit';

        return showCareType && unit?.careType
            ? `${CareTypeLabelsAbbreviated[unit.careType]} | ${unitText}`
            : unitText;
    }, [showCareType, unit]);

    return (
        <Stack
            direction="row"
            sx={{
                alignItems: 'center',
                gap: 1.5,
            }}
        >
            <ResidentAvatar {...props} />
            <Box>
                <Stack
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Typography
                        variant="body1"
                        component="h4"
                        data-dd-privacy="mask"
                        sx={{
                            fontWeight: 'bold',
                            color: 'grey.900',
                        }}
                    >
                        {residentName}
                    </Typography>
                    {canSeePHI && isHospice(hospiceStatus) && (
                        <HospiceChip hospiceStatus={hospiceStatus} hideLabel />
                    )}
                </Stack>

                <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{
                        color: 'grey.900',
                    }}
                >
                    {subTitle}
                </Typography>
                {children}
            </Box>
        </Stack>
    );
}

export default Resident;
