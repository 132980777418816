import auth0 from 'auth0-js';

import { authorizationParams } from 'src/constants/Auth0';

// manage auth configs
// See https://auth0.com/docs/libraries/auth0js#available-parameters
const webAuth = new auth0.WebAuth({
    ...authorizationParams,
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    redirectUri: authorizationParams.redirect_uri,
    responseType: 'token id_token',
});

export default webAuth;
