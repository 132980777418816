import { type CountryCode } from 'libphonenumber-js';

export const SupportedCountryCodes: CountryCode[] = [
    'AG', // Antigua and Barbuda
    'AI', // Anguilla
    'AS', // American Samoa
    'BB', // Barbados
    'BM', // Bermuda
    'BS', // Bahamas
    'CA', // Canada
    'DO', // Dominican Republic
    'DM', // Dominica
    'GD', // Grenada
    'GU', // Guam
    'JM', // Jamaica
    'KN', // Saint Kitts and Nevis
    'KY', // Cayman Islands
    'MP', // Northern Mariana Islands
    'MS', // Montserrat
    'LC', // Saint Lucia
    'PR', // Puerto Rico
    'SX', // Sint Maarten
    'TC', // Turks and Caicos Islands
    'TT', // Trinidad and Tobago
    'US', // United States
    'VC', // Saint Vincent and the Grenadines
    'VG', // Virgin Islands, British
    'VI', // Virgin Islands, U.S.
];

export const PreferredCountryCodes: CountryCode[] = [
    'US', // United States
];
