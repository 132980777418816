/**
 * Intentially left empty. Endpoints will be injected later as needed.
 * See https://redux-toolkit.js.org/rtk-query/usage/code-generation#usage
 */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from './index';

/**
 * Configures the admin api to be used for generating endpoints.
 */
export const sageBaseLocationQueryApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_SAGE_LOCATION_QUERY_BASE_URL,
        timeout: 30_000,

        // See https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
        prepareHeaders: (headers, { getState }) => {
            const rootState: RootState = getState() as RootState;

            const bearerToken = rootState.authConfig?.bearerToken;
            if (bearerToken) {
                const authHeader = (bearerToken as String).toLowerCase().startsWith('bearer')
                    ? bearerToken
                    : `Bearer ${bearerToken}`;

                headers.set('Authorization', authHeader);
            }

            const profileRid = rootState.appConfig?.staffMemberId;
            if (profileRid) {
                headers.set('x-sage-profile-rid', profileRid);
            }

            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: 'sageLocationQueryApi',
});
