export const NeedsImprovementInsightType = 'NEEDS_IMPROVEMENT' as string;
export const TopPerformerInsightType = 'TOP_PERFORMER' as string;
export const StaffPerformanceInsightTypes = [
    NeedsImprovementInsightType,
    TopPerformerInsightType,
] as const;
export const SupportedInsightTypes = [...StaffPerformanceInsightTypes] as const;
export type InsightType = (typeof SupportedInsightTypes)[number];

export const InsightStatuses = {
    Closed: 'CLOSED',
    Open: 'OPEN',
};

export const WorkflowScopes = {
    Global: 'GLOBAL',
    User: 'USER',
};

export const WorkflowTypes = {
    Dismiss: 'DISMISS',
    NeedsImprovement: 'NEEDS_IMPROVEMENT',
    Recognition: 'RECOGNITION',
    Acknowledge: 'ACKNOWLEDGE',
};

export const ActionTypes = {
    Dismiss: 'DISMISS',
    NeedsImprovement: 'LOG_ACTION_NEEDS_IMPROVEMENT',
    SendHighFive: 'SEND_HIGH_FIVE',
};

export const ActionStatusDetails = {
    InsightIsIncorrect: 'INSIGHT_IS_INCORRECT',
    ITookADifferentAction: 'I_TOOK_A_DIFFERENT_ACTION',
    SomethingElse: 'SOMETHING_ELSE',
    MetWithCaregiver: 'MET_WITH_CAREGIVER',
    RetrainedOnSage: 'RETRAINED_ON_SAGE',
    Other: 'OTHER',
};

export const ActionStatusDetailLabels = {
    [ActionStatusDetails.InsightIsIncorrect]: 'Insight is incorrect',
    [ActionStatusDetails.ITookADifferentAction]: 'I took a different action',
    [ActionStatusDetails.SomethingElse]: 'Something else',
    [ActionStatusDetails.MetWithCaregiver]: 'Met with caregiver',
    [ActionStatusDetails.RetrainedOnSage]: 'Retrained on Sage',
    [ActionStatusDetails.Other]: 'Other',
};

export const FeedbackDetailOptions = [
    ActionStatusDetails.InsightIsIncorrect,
    ActionStatusDetails.ITookADifferentAction,
    ActionStatusDetails.SomethingElse,
];

export const LogActionDetailOptions = [
    ActionStatusDetails.MetWithCaregiver,
    ActionStatusDetails.RetrainedOnSage,
    ActionStatusDetails.Other,
];

export const InsightTypeToLabelMap: Record<InsightType, string> = {
    NEEDS_IMPROVEMENT: 'Needs Improvement',
    TOP_PERFORMER: 'Top Performance',
};

export const InsightTypeBackgrounds: Record<InsightType, string> = {
    [NeedsImprovementInsightType]: `linear-gradient(213.95deg, #FFF2EA 20.12%, #FDBE98 79.88%)`,
    [TopPerformerInsightType]: `linear-gradient(213.95deg, #FFF1BD 20.12%, #FFE583 79.88%)`,
};
