import Chip from '@mui/material/Chip';

import {
    ResuscitationStrategiesDnrChip,
    ResuscitationStrategiesCprChip,
    ResuscitationStrategiesUnknownChip,
} from 'src/components/residents/styles/ResidentChipParameters';
import {
    ResuscitationStrategies,
    ResuscitationStrategyDisplayLabels,
} from 'src/constants/ResuscitationStrategies';

const ResuscitationChip = (props) => {
    const { resuscitationStrategy } = props;

    return (
        <>
            {resuscitationStrategy === ResuscitationStrategies.DNR && (
                <Chip
                    {...ResuscitationStrategiesDnrChip}
                    label={ResuscitationStrategyDisplayLabels.DNR}
                />
            )}

            {resuscitationStrategy === ResuscitationStrategies.CPR && (
                <Chip
                    {...ResuscitationStrategiesCprChip}
                    label={ResuscitationStrategyDisplayLabels.CPR}
                />
            )}

            {resuscitationStrategy === ResuscitationStrategies.UNKNOWN && (
                <Chip
                    {...ResuscitationStrategiesUnknownChip}
                    label={ResuscitationStrategyDisplayLabels.UNKNOWN}
                />
            )}
        </>
    );
};

export default ResuscitationChip;
