import { useContext, useState } from 'react';

import { Button } from '@mui/material';

import RadioGroupOptions from '../fragments/RadioGroupOptions';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { CareTypeLabels } from 'src/constants/CareTypes';

import '../../assets/styles/buttonStyles.scss';

const DrawerName = 'Select Ward Drawer';

interface SelectWardDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
    wards: { value: string; label: string }[];
    preSelectedWard?: string;
}

function SelectWardDrawer(props: SelectWardDrawerProps) {
    const { wards, preSelectedWard, onNext, title = 'Select Type of Care' } = props;
    const { trackEvent } = useContext(AnalyticsContext);
    const [selectedWard, setSelectedWard] = useState(preSelectedWard || '');

    const handleSelectWard = (selectedWard: string) => {
        trackEvent('Ward Selected', { ward: selectedWard, context: DrawerName });
        setSelectedWard(selectedWard);
    };

    const handleContinue = () => {
        trackEvent('Continue Button Clicked', { context: DrawerName, ward: selectedWard });

        onNext({ value: selectedWard });
    };

    return (
        <>
            <DrawerTitle title={title} />

            <DrawerBody>
                <RadioGroupOptions
                    radioGroupProps={{ 'aria-label': 'ward' }}
                    variant="outlined"
                    selectedOption={selectedWard}
                    onSelectOption={handleSelectWard}
                    options={wards.map((ward) => ({
                        value: ward.value,
                        label: CareTypeLabels[ward.label],
                    }))}
                />
            </DrawerBody>

            <DrawerFooter>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={!selectedWard}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectWardDrawer;
