import { Box, Button, Stack, Typography } from '@mui/material';

import ErrorIcon from '../assets/images/error-icons/diamond-exclamation.svg';
import { ZendeskUrl } from 'src/constants/ExternalUrls';

import '../assets/styles/buttonStyles.scss';
import '../assets/styles/containerStyles.scss';

function ErrorPage() {
    return (
        <Box className="errorPageContainer">
            <Stack
                sx={{
                    p: 2.5,
                    gap: 2.5,
                }}
            >
                <Stack
                    sx={{
                        gap: 1,
                        alignItems: 'start',
                    }}
                >
                    <img src={ErrorIcon} alt="" />
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Something went wrong
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'grey.900',
                        }}
                    >
                        Reloading Sage will take care of it most of the time. If you keep ending up
                        here, you can reach out to us from our help center.
                    </Typography>
                </Stack>

                <Button
                    className="ctaButton primary"
                    variant="contained"
                    disableElevation
                    size="large"
                    fullWidth
                    href={`/?t=${Date.now()}`}
                >
                    Reload
                </Button>
                <Button
                    className="ctaButton"
                    variant="outlined"
                    disableElevation
                    size="large"
                    fullWidth
                    href={ZendeskUrl}
                    target="_blank"
                >
                    Get Help
                </Button>
            </Stack>
        </Box>
    );
}

export default ErrorPage;
