import { StackProps, Stack, Typography } from '@mui/material';

import './styles/Drawer.styles.scss';

interface DrawerTitleProps extends StackProps {
    title?: string;
    children?: React.ReactNode;
}

function DrawerTitle(props: DrawerTitleProps) {
    const { title, children, sx = {} } = props;

    return (
        <Stack
            sx={{
                // Default props
                pt: 2.5,
                px: 2.5,
                gap: 2,
                position: 'relative',
                // Custom props
                ...sx,
                // Do not override
                gridArea: 'header',
            }}
        >
            {!!title && (
                <Typography
                    align="center"
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    {title}
                </Typography>
            )}
            {children}
        </Stack>
    );
}

export default DrawerTitle;
