import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bearerToken: null,
};

export const authSlice = createSlice({
    name: 'authConfig',
    initialState,
    reducers: {
        setBearerToken: (state, { payload }) => {
            state.bearerToken = payload;
        },
    },
});

export const { setBearerToken } = authSlice.actions;

export const selectBearerToken = (state) => state.authConfig.bearerToken;

export default authSlice.reducer;
