import { useState } from 'react';

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EnterpriseLogo from '../fragments/EnterpriseLogo';
import SearchInput from '../fragments/SearchInput';
import DrawerBody from './DrawerBody';
import DrawerTitle from './DrawerTitle';
import { StaffMember } from 'src/store/sageAdminApi';
import { pluralize } from 'src/utils/intlUtils';

interface SelectEnterpriseDrawerProps {
    onNext: (data: { staffMemberProfile: StaffMember }) => void;
    currentOption: string;
    options: StaffMember[];
}

function SelectEnterpriseDrawer(props: SelectEnterpriseDrawerProps) {
    const { onNext, currentOption, options = [] } = props;

    const { palette, spacing } = useTheme();

    const [searchValue, setSearchValue] = useState('');

    const filteredOptions =
        options?.filter((option) =>
            option.enterprise?.name?.toLowerCase().includes(searchValue.toLowerCase())
        ) || [];

    const handleSelectOptions = (selectedStaffMemberProfile: StaffMember) => {
        onNext({ staffMemberProfile: selectedStaffMemberProfile });
    };

    return (
        <>
            <DrawerTitle title="Select a company" sx={{ pb: 2 }}>
                <SearchInput
                    searchValue={searchValue}
                    onSearchChange={setSearchValue}
                    placeholder="Search for companies"
                />
            </DrawerTitle>
            <DrawerBody sx={{ gap: 2, pb: 4 }}>
                {filteredOptions.map((staffMemberProfile: StaffMember) => (
                    <Card
                        key={staffMemberProfile.id}
                        onClick={() => handleSelectOptions(staffMemberProfile)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {currentOption && currentOption === staffMemberProfile.id && (
                            <Box
                                sx={{
                                    bgcolor: 'purple.100',
                                    py: 0.25,
                                    px: 1.5,
                                    borderRadius: spacing(1, 1, 0, 0),
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    component="p"
                                    sx={{
                                        color: 'purple.900',
                                    }}
                                >
                                    Current
                                </Typography>
                            </Box>
                        )}
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: 'center',
                                gap: 1.5,
                                p: 2,
                            }}
                        >
                            <EnterpriseLogo
                                enterpriseId={staffMemberProfile?.enterprise?.id!}
                                enterpriseName={staffMemberProfile?.enterprise?.name}
                            />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'primary',
                                    }}
                                >
                                    {staffMemberProfile?.enterprise?.name}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {pluralize(
                                        staffMemberProfile.facilities?.length || 0,
                                        'community',
                                        'communities'
                                    )}
                                </Typography>
                            </Box>
                            <FontAwesomeIcon icon={faArrowRight} color={palette.primary.main} />
                        </Stack>
                    </Card>
                ))}
            </DrawerBody>
        </>
    );
}

export default SelectEnterpriseDrawer;
