import { useMemo } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useSelector } from 'react-redux';

import Groups from 'src/constants/Groups';
import { selectFacilityId, selectStaffMemberId } from 'src/store/configSlice';
import {
    StaffMemberGroupMembership,
    useGetStaffMemberFacilityMetadataQuery,
    useGetStaffMemberQuery,
} from 'src/store/sageAdminApi';

interface UserGroups {
    isInCareStaffGroup: boolean;
    isCareStaffGroupAdmin: boolean;
    isInCulinaryGroup: boolean;
    isCulinaryGroupAdmin: boolean;
    isInHousekeepingGroup: boolean;
    isHousekeepingGroupAdmin: boolean;
    isInMaintenanceGroup: boolean;
    isMaintenanceGroupAdmin: boolean;
    isInManagementGroup: boolean;
    isManagementGroupAdmin: boolean;
    isInNurseMedtechGroup: boolean;
    isNurseMedtechGroupAdmin: boolean;
    isInSageSupportGroup: boolean;
    isSageSupportGroupAdmin: boolean;
    isInTransportationGroup: boolean;
    isTransportationGroupAdmin: boolean;
}

export const DefaultGroupsSummary: UserGroups = {
    isInCareStaffGroup: false,
    isCareStaffGroupAdmin: false,

    isInCulinaryGroup: false,
    isCulinaryGroupAdmin: false,

    isInHousekeepingGroup: false,
    isHousekeepingGroupAdmin: false,

    isInMaintenanceGroup: false,
    isMaintenanceGroupAdmin: false,

    isInManagementGroup: false,
    isManagementGroupAdmin: false,

    isInNurseMedtechGroup: false,
    isNurseMedtechGroupAdmin: false,

    isInSageSupportGroup: false,
    isSageSupportGroupAdmin: false,

    isInTransportationGroup: false,
    isTransportationGroupAdmin: false,
};

export default function useUserPermissions(userId?: string) {
    const isVisible = usePageVisibility();

    const currentUserId = useSelector(selectStaffMemberId);
    const facilityId = useSelector(selectFacilityId);

    const staffMemberId = userId || currentUserId;

    const { data: staffMemberProfile, isLoading: isLoadingStaffMemberProfile } =
        useGetStaffMemberQuery({ staffMemberId }, { skip: !staffMemberId });

    const staffMemberFacilityConfig = useMemo(
        () =>
            staffMemberProfile?.staffMemberFacilityConfigs?.find?.(
                (config) => config.facilityId === facilityId
            ),
        [facilityId, staffMemberProfile]
    );

    const shouldReceiveAllCareMessages =
        staffMemberFacilityConfig?.shouldReceiveAllCareMessages || false;

    const isCareAdmin = staffMemberFacilityConfig?.isCareAdmin || false;

    const { data: staffMemberFacilityMetadata, isLoading: isLoadingStaffMemberFacilityMetadata } =
        useGetStaffMemberFacilityMetadataQuery({ staffMemberId }, { skip: !staffMemberId });

    const staffMemberFacilityGroups = useMemo(
        () =>
            staffMemberFacilityMetadata?.find?.((facility) => facility.facilityId === facilityId)
                ?.groups,
        [facilityId, staffMemberFacilityMetadata]
    );

    const groupsSummary = useMemo(
        () =>
            staffMemberFacilityGroups?.reduce?.(
                (summary: UserGroups, group: StaffMemberGroupMembership) => {
                    const { groupName, isAdmin } = group;

                    switch (groupName) {
                        case Groups.CareStaff:
                            return {
                                ...summary,
                                isInCareStaffGroup: true,
                                isCareStaffGroupAdmin: !!isAdmin,
                            };
                        case Groups.Culinary:
                            return {
                                ...summary,
                                isInCulinaryGroup: true,
                                isCulinaryGroupAdmin: !!isAdmin,
                            };
                        case Groups.Housekeeping:
                            return {
                                ...summary,
                                isInHousekeepingGroup: true,
                                isHousekeepingGroupAdmin: !!isAdmin,
                            };
                        case Groups.Maintenance:
                            return {
                                ...summary,
                                isInMaintenanceGroup: true,
                                isMaintenanceGroupAdmin: !!isAdmin,
                            };
                        case Groups.Management:
                            return {
                                ...summary,
                                isInManagementGroup: true,
                                isManagementGroupAdmin: !!isAdmin,
                            };
                        case Groups.NurseMedTech:
                            return {
                                ...summary,
                                isInNurseMedTechGroup: true,
                                isNurseMedTechGroupAdmin: !!isAdmin,
                            };
                        case Groups.SageSupport:
                            return {
                                ...summary,
                                isInSageSupportGroup: true,
                                isSageSupportGroupAdmin: !!isAdmin,
                            };
                        case Groups.Transportation:
                            return {
                                ...summary,
                                isInTransportationGroup: true,
                                isTransportationGroupAdmin: !!isAdmin,
                            };
                        default:
                            return summary;
                    }
                },
                { ...DefaultGroupsSummary } as UserGroups
            ) || DefaultGroupsSummary,
        [staffMemberFacilityGroups]
    );

    const {
        isInCareStaffGroup,
        isCareStaffGroupAdmin,
        isInCulinaryGroup,
        isInHousekeepingGroup,
        isInMaintenanceGroup,
        isInManagementGroup,
        isManagementGroupAdmin,
        isInNurseMedtechGroup,
        isInTransportationGroup,
    } = groupsSummary || {};

    // Permissions
    const canViewAlerts = isInCareStaffGroup || isInManagementGroup;

    const canSeePHI = isInCareStaffGroup || isInManagementGroup;
    const canEditPHI = isManagementGroupAdmin || isCareStaffGroupAdmin || isCareAdmin;

    const canSeePII = isManagementGroupAdmin;
    const canEditPII = isManagementGroupAdmin;

    /**
     * "Function" & "Supervisor" states
     *
     * These 3 conditional sets are used ONLY for defining visibility
     * requirements (i.e. what a user will see on a page). They do NOT
     * correspond with any backend data model concepts.
     *
     * See: https://www.notion.so/hellosage/Define-all-user-visibility-permutations-that-we-care-about-94b1d896a18c45da8c9b42e923d6f243
     */
    const isOperations =
        !isInCareStaffGroup &&
        !isInNurseMedtechGroup &&
        !isInManagementGroup &&
        (isInCulinaryGroup ||
            isInHousekeepingGroup ||
            isInMaintenanceGroup ||
            isInTransportationGroup);

    const isResponder = (isInCareStaffGroup || isInNurseMedtechGroup) && !isCareStaffGroupAdmin;

    const isResponderSupervisor =
        (isInCareStaffGroup || isInNurseMedtechGroup) && isCareStaffGroupAdmin;

    const shouldPollAlerts =
        isVisible && (isResponder || isResponderSupervisor || isInManagementGroup);

    return {
        isLoading: isLoadingStaffMemberProfile || isLoadingStaffMemberFacilityMetadata,
        staffMemberProfile,
        staffMemberFacilityConfig,
        staffMemberFacilityGroups,
        shouldReceiveAllCareMessages,

        // Return all granular groups booleans
        ...groupsSummary,

        // PII & PHI specific actions
        canViewAlerts,
        canSeePHI,
        canEditPHI,
        canSeePII,
        canEditPII,

        // User visibility definitions
        isOperations,
        isResponder,
        isResponderSupervisor,

        // Utility flags
        shouldPollAlerts,
    };
}
