import { Stack, StackProps } from '@mui/material';

import './styles/Drawer.styles.scss';

interface DrawerFooterProps extends StackProps {
    showShadow?: boolean;
}

function DrawerFooter(props: DrawerFooterProps) {
    const { showShadow = false, sx = {} } = props;

    return (
        <Stack
            sx={{
                // Default props
                pt: 2.5,
                px: 2.5,
                pb: 4,
                // Custom props
                ...sx,
                // Do not override
                gridArea: 'footer',
                boxShadow: showShadow ? 2 : undefined,
            }}
        >
            {props.children}
        </Stack>
    );
}

export default DrawerFooter;
