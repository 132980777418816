import parsePhoneNumber from 'libphonenumber-js';
import _ from 'lodash';

import { UnsetRid } from 'src/constants/UnsetRid';

export const getSentinelAwareValue = (value) => {
    if (value === undefined || value === null || value === UnsetRid) {
        return '';
    }
    return value;
};

export const RemoveEmptyFields = (obj) => {
    const prunedObj = _.cloneDeep(obj);

    function prune(current) {
        _.forOwn(current, (value, key) => {
            if (
                _.isUndefined(value) ||
                _.isNull(value) ||
                _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isObject(value) && _.isEmpty(prune(value)))
            ) {
                // eslint-disable-next-line no-param-reassign
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) {
            _.pull(current, undefined);
        }
        return current;
    }
    return prune(prunedObj);
};

export const Formats = {
    International: 'international',
    National: 'national',
    Uri: 'uri',
};

export const formatPhoneNumber = (
    phoneNumberString,
    format = Formats.International,
    countryCode = 'US'
) => {
    const phoneNumber = parsePhoneNumber(phoneNumberString, countryCode);

    switch (format) {
        case Formats.National:
            return phoneNumber.formatNational();
        case Formats.Uri:
            return phoneNumber.getURI();
        case Formats.International:
        default:
            // libphonenumber-js does not add '-' separators for INTL formatting, so we need to add it ourselves
            return (
                phoneNumber
                    .formatInternational()
                    // Split into chunks without spaces
                    .split(' ')
                    // Add the correct separator for each chunk (' ' for first separator, '-' for the rest)
                    .reduce((previous, current, i) => previous + (i === 1 ? ' ' : '-') + current)
            );
    }
};

export const OnlyDirtyFields = (newData, oldData) => {
    const trimmedData = newData;
    Object.keys(trimmedData).forEach((key) => {
        if (trimmedData[key] === oldData[key]) {
            delete trimmedData[key];
        }
    });
    return trimmedData;
};

export const FormatDobToUs = (dateString) => {
    // Takes a date in ISO8601 format (YYYY-MM-DD) and converts it to MM/DD/YYYY
    const dateParts = dateString.split('-');
    if (dateParts.length === 3) {
        const year = dateParts.shift();
        dateParts.push(year);
        return dateParts.join('/');
    }
    return dateString;
};

export const FormatDobTo8601 = (dateString) => {
    // Takes a date in MM/DD/YYYY format and converts it to ISO8601 (YYYY-MM-DD)
    const dateParts = dateString.split('/');
    if (dateParts.length === 3) {
        const year = dateParts.pop();
        dateParts.splice(0, 0, year);
        return dateParts.join('-');
    }
    return dateString;
};
