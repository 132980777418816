export const AUTH0_CALLBACK_PATH = '/auth_confirmed';
export const LOGIN_METHODS = {
    PASSWORDLESS: 'passwordless',
    ULP: 'ulp',
};

export const LOGIN_SUCCESS_REDIRECT_PATH = '/profile/shift?fromLogin=true';

export const scope =
    'openid profile email app_metadata read:users read:current_user offline_access';

export const authorizationParams = {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    scope,

    /**
     * This value needs to match what @auth0/auth0-react expects so that the `onRedirectCallback`
     * function is called for both passwordless and ULP login flows
     *
     * See https://github.com/auth0/auth0-react/issues/82#issuecomment-711479502
     */
    redirect_uri: `${window.location.origin}${AUTH0_CALLBACK_PATH}`,
};
