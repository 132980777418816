import clsx from 'clsx';
import { fromUnixTime, differenceInHours, addHours } from 'date-fns';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import 'src/components/drawers/styles/Drawer.styles.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { faBellOn } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import DrawerBody from 'src/components/drawers/DrawerBody';
import DrawerFooter from 'src/components/drawers/DrawerFooter';
import DrawerTitle from 'src/components/drawers/DrawerTitle';
import useEnvironment from 'src/hooks/useEnvironment';
import { selectFacility, selectStaffMemberId } from 'src/store/configSlice';
import {
    useCreateOrUpdateWebPushNotificationSubscriptionMutation,
    useDeleteWebPushNotificationSubscriptionsMutation,
    useLazyFindWebPushNotificationSubscriptionsQuery,
    useUpdateMessageSentMutation,
} from 'src/store/sageMessageOrchestrationApi';

export const NotificationResults = {
    Granted: 'granted',
    Denied: 'denied',
    Default: 'default',
};

// !Important! - these values must match the values in public/service-worker.js
const ServiceWorkerMessageTypes = {
    NewNotification: 'New Notification',
    NotificationShown: 'Notification Shown',
    NotificationClicked: 'Notification Clicked',
};

// !Important! -- these values should exist in UpdateMessageSentStatus enum in NotificationService.
const UpdateMessageSentStatusTypes = {
    RECEIVED: 'RECEIVED',
    SHOWN: 'SHOWN',
};

const DefaultSubscriptionExpirationHours = 8;
const NotificationReceivedDelayErrorThresholdSeconds = 30;

const PushNotificationDeliveryMethod = 'PUSH_NOTIFICATION';

export type NotificationOptionsWithExperimentalProperties = NotificationOptions & {
    renotify?: boolean;
    vibrate?: number[];
};

const DefaultNotificationOptions: NotificationOptions = {
    icon: '../assets/images/sage-icon-light.svg',
    badge: '../assets/images/sage-icon.svg',
};

export type NotificationsContextValuesType = {
    areNotificationsSupported: boolean;
    dismissNotificationsByTag: (tag?: string) => void;
    displayNotification: (
        title: string,
        options: NotificationOptionsWithExperimentalProperties
    ) => Promise<boolean>;
    notificationsPermission?: NotificationPermission;
    shouldLoadNotificationsContext: boolean;
    unsubscribeFromPush: (staffMemberId: string, unsubscribeFromAll?: boolean) => void;
};

export const NotificationsContext = createContext<NotificationsContextValuesType>({
    areNotificationsSupported: false,
    dismissNotificationsByTag: (_taskId?: string) => {},
    displayNotification: (
        _title: string,
        _options: NotificationOptionsWithExperimentalProperties
    ) => Promise.resolve(false),
    notificationsPermission: undefined,
    shouldLoadNotificationsContext: false,
    unsubscribeFromPush: (_staffMemberId: string, _unsubscribeFromAll = false) => {},
});

/**
 * First decode the vapid key, which is already base 64 encoded, then encode into Uint8
 * @param {string} vapidPublicKey
 * @returns
 */
const urlB64ToUint8Array = (vapidPublicKey: string) => {
    const padding = '='.repeat((4 - (vapidPublicKey.length % 4)) % 4);
    const base64 = (vapidPublicKey + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const getOrCreateServiceWorkerRegistration = async () => {
    try {
        if (!('serviceWorker' in navigator)) {
            console.warn('Service workers are not supported by this browser');
            return;
        }

        return (
            (await navigator.serviceWorker.getRegistration()) ||
            (await navigator.serviceWorker.register('/service-worker.js'))
        );
    } catch (error: any) {
        datadogLogs.logger.error('Error registering service worker', undefined, error);
        return;
    }
};

/**
 * Gets the number of seconds since the given timestamp
 *
 * @param {string} timestamp
 * @returns {number | undefined}
 */
const getSecondsSinceTimestamp = (timestamp: string) => {
    if (!timestamp) {
        return undefined;
    }

    const createTimestamp = Date.parse(timestamp);
    const now = Date.now();
    return isNaN(createTimestamp) ? undefined : (now - createTimestamp) / 1000;
};

export const AnalyticsPrefix = 'notifications';

function NotificationsProvider(props: PropsWithChildren) {
    const { trackEvent } = useContext(AnalyticsContext);

    const { getIdTokenClaims } = useAuth0();
    const { palette } = useTheme();

    const { isE2E } = useEnvironment();

    const facility = useSelector(selectFacility);
    const staffMemberId = useSelector(selectStaffMemberId);
    const facilityId = facility?.id;

    const areNotificationsSupported =
        'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

    const [notificationsPermission, setNotificationsPermission] = useState<
        NotificationPermission | undefined
    >();
    const [notificationSubscription, setNotificationSubscription] = useState<
        PushSubscription | undefined
    >();
    const [showRequestPermissionsDrawer, setShowRequestPermissionsDrawer] = useState(false);

    const [getStaffMemberSubscriptionsForCurrentDevice] =
        useLazyFindWebPushNotificationSubscriptionsQuery();
    const [createOrUpdatePushNotificationSubscription] =
        useCreateOrUpdateWebPushNotificationSubscriptionMutation();
    const [deleteWebPushNotificationSubscription] =
        useDeleteWebPushNotificationSubscriptionsMutation();
    const [updateMessageSent] = useUpdateMessageSentMutation();

    const dismissNotificationsByTag = useCallback(
        async (tag?: string) => {
            try {
                const swRegistration = await getOrCreateServiceWorkerRegistration();

                if (swRegistration?.active) {
                    const notifications = await swRegistration.getNotifications({ tag });
                    notifications?.forEach((element) => element.close());
                }
            } catch (error: any) {
                datadogLogs.logger.error(
                    'Error dismissing notifications by tag',
                    { staffMemberId, facilityId, tag },
                    error
                );
            } finally {
                return;
            }
        },
        [facilityId, staffMemberId]
    );

    const subscribeToPush = useCallback(async () => {
        try {
            const swRegistration = await getOrCreateServiceWorkerRegistration();

            const subscription =
                (await swRegistration?.pushManager?.getSubscription()) ||
                (await swRegistration?.pushManager?.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
                }));

            setNotificationSubscription(subscription);
        } catch (error: any) {
            datadogLogs.logger.error(
                'Error subscribing to push notifications',
                { staffMemberId, facilityId },
                error
            );
        }
    }, [facilityId, staffMemberId]);

    /**
     * Unsubscribes the user from push notifications.  If unsubscribeFromAll is true, will
     * unsubscribe from all devices instead of just the current one.
     */
    const unsubscribeFromPush = useCallback(
        async (staffMemberId: string, unsubscribeFromAll = false) => {
            try {
                const swRegistration = await getOrCreateServiceWorkerRegistration();
                const subscription = await swRegistration?.pushManager.getSubscription();
                const endpoints = subscription ? [subscription.endpoint] : undefined;

                await deleteWebPushNotificationSubscription({
                    recipientIds: [staffMemberId],
                    endpoints: unsubscribeFromAll ? undefined : endpoints,
                }).unwrap();
            } catch (error: any) {
                datadogLogs.logger.error(
                    'Error unsubscribing from push notifications',
                    { staffMemberId, facilityId },
                    error
                );
            }
        },
        [deleteWebPushNotificationSubscription, facilityId]
    );

    const requestPermissions = useCallback(async () => {
        try {
            if (areNotificationsSupported) {
                const permissionResult = await Notification.requestPermission();

                if (permissionResult === NotificationResults.Granted) {
                    console.info('Notifications permissions allowed by user');
                } else {
                    console.warn('Notifications permissions denied by user');
                }

                trackEvent('Notification Permission Request Acknowledged', {
                    objectType: ObjectTypes.Dialog,
                    permissionResult,
                });
                setNotificationsPermission(permissionResult);
            }
        } catch (error: any) {
            datadogLogs.logger.error(
                'Error requesting permissions',
                { staffMemberId, facilityId },
                error
            );
        }
    }, [areNotificationsSupported, facilityId, staffMemberId, trackEvent]);

    const shouldLoadNotificationsContext = useMemo(
        () => Boolean(facility?.messageDeliveryMethods?.includes(PushNotificationDeliveryMethod)),
        [facility]
    );

    /**
     * Displays a notification to the user.
     * @param {string} title - The title of the notification
     * @param {NotificationOptionsWithExperimentalProperties} options - The options for the notification
     *
     * @returns {Promise<boolean>} - Whether the notification was successfully queued to be displayed
     */
    const displayNotification = useCallback(
        async (title: string, options: NotificationOptionsWithExperimentalProperties) => {
            try {
                const swRegistration = await getOrCreateServiceWorkerRegistration();
                if (areNotificationsSupported && swRegistration?.active) {
                    // Push a message to the service worker
                    await swRegistration.showNotification(title, {
                        ...DefaultNotificationOptions,
                        ...options,
                    });
                    return true;
                } else {
                    return false;
                }
            } catch (error: any) {
                datadogLogs.logger.error(
                    'Error pushing notification',
                    { staffMemberId, facilityId, title },
                    error
                );
                return false;
            }
        },
        [areNotificationsSupported, facilityId, staffMemberId]
    );

    useEffect(() => {
        if (shouldLoadNotificationsContext && areNotificationsSupported) {
            setNotificationsPermission(Notification.permission);
        } else if (shouldLoadNotificationsContext === false && !areNotificationsSupported) {
            console.warn('Notifications not supported');
        }
    }, [areNotificationsSupported, shouldLoadNotificationsContext]);

    useEffect(() => {
        if (
            areNotificationsSupported &&
            shouldLoadNotificationsContext &&
            staffMemberId &&
            !isE2E
        ) {
            getOrCreateServiceWorkerRegistration().then((registration) => {
                if (
                    notificationsPermission === NotificationResults.Default &&
                    registration?.active
                ) {
                    registration.pushManager.getSubscription().then((subscription) => {
                        if (
                            // check for existing push notification subscription in addition to permission is default
                            // since iOS permissions seem to briefly reset on redirecting back to the app
                            // see also https://stackoverflow.com/questions/76590928/pwa-on-ios-notification-permission-return-default-whatever-we-chose
                            !subscription
                        ) {
                            setShowRequestPermissionsDrawer(true);
                        }
                    });
                } else if (notificationsPermission === NotificationResults.Granted) {
                    subscribeToPush();
                }
            });
        }
    }, [
        areNotificationsSupported,
        isE2E,
        notificationsPermission,
        shouldLoadNotificationsContext,
        staffMemberId,
        subscribeToPush,
    ]);

    useEffect(() => {
        if (!trackEvent) {
            return;
        }

        const handleServiceWorkerMessage = async (event: MessageEvent) => {
            try {
                const { type, payload } = event.data;
                const now = new Date().toISOString();

                if (type === ServiceWorkerMessageTypes.NewNotification) {
                    const {
                        isCheckIn,
                        taskId,
                        taskSeverity,
                        messageRid,
                        messageSentRid,
                        notificationCreateTime,
                    } = payload.message?.options?.data || {};
                    const secondsSinceCreation = getSecondsSinceTimestamp(notificationCreateTime);

                    trackEvent('New Notification Received', {
                        objectType: ObjectTypes.Notification,
                        secondsSinceCreation,
                        notificationCreateTime,
                        now,
                        isCheckIn,
                        taskId,
                        taskSeverity,
                    });

                    // ensure call completes
                    await updateMessageSent({
                        messageRid: messageRid,
                        messageSentRid: messageSentRid,
                        body: {
                            status: UpdateMessageSentStatusTypes.RECEIVED,
                            statusTimestamp: now,
                        },
                    }).unwrap();

                    if (
                        secondsSinceCreation &&
                        secondsSinceCreation > NotificationReceivedDelayErrorThresholdSeconds
                    ) {
                        datadogLogs.logger.warn(
                            'Delay in receiving push message for new notification',
                            {
                                notificationCreateTime,
                                currentTimestamp: now,
                                secondsSinceCreation,
                                isCheckIn,
                                taskId,
                                taskSeverity,
                                staffMemberId,
                                facilityId,
                            }
                        );
                    }
                } else if (type === ServiceWorkerMessageTypes.NotificationShown) {
                    const { ...data } = payload.message?.options?.data || {};
                    const secondsSinceCreation = getSecondsSinceTimestamp(
                        data.notificationCreateTime
                    );

                    // ensure call completes
                    await updateMessageSent({
                        messageRid: data.messageRid,
                        messageSentRid: data.messageSentRid,
                        body: {
                            status: UpdateMessageSentStatusTypes.SHOWN,
                            statusTimestamp: now,
                        },
                    }).unwrap();

                    trackEvent('Notification Shown', {
                        objectType: ObjectTypes.Notification,
                        secondsSinceCreation,
                        now,
                        ...data,
                    });
                } else if (type === ServiceWorkerMessageTypes.NotificationClicked) {
                    const { notification } = payload;
                    const { data, timestamp: notificationEventTimestamp } = notification || {};
                    const { notificationCreateTime } = data || {};

                    const secondsSinceCreation = getSecondsSinceTimestamp(notificationCreateTime);

                    trackEvent('Notification Clicked', {
                        objectType: ObjectTypes.Notification,
                        secondsSinceCreation,
                        now,
                        notificationEventTimestamp,
                        ...data,
                    });
                } else {
                    console.warn(`Unknown service worker message type: ${type}`);
                }
            } catch (error: any) {
                datadogLogs.logger.error(
                    'Error handling service worker message',
                    { staffMemberId, facilityId },
                    error
                );
            }
        };

        getOrCreateServiceWorkerRegistration().then((serviceWorkerRegistration) => {
            if (serviceWorkerRegistration) {
                navigator.serviceWorker?.addEventListener('message', handleServiceWorkerMessage);
            }
        });

        return () => {
            navigator.serviceWorker?.removeEventListener('message', handleServiceWorkerMessage);
        };
    }, [facilityId, staffMemberId, trackEvent, updateMessageSent]);

    useEffect(() => {
        if (!notificationSubscription) {
            return;
        }

        const recordSubscription = async () => {
            const existingSubscriptions =
                (
                    await getStaffMemberSubscriptionsForCurrentDevice({
                        recipientIds: [staffMemberId],
                        endpoints: [notificationSubscription.endpoint],
                    }).unwrap()
                )?.subscriptions || [];

            const existingSubscriptionExpiration = existingSubscriptions?.[0]?.expirationTime;
            // push to backend if either no existing or the existing subscription expire time is within 1 hour
            if (
                !existingSubscriptionExpiration ||
                differenceInHours(
                    fromUnixTime(parseInt(existingSubscriptionExpiration)),
                    Date.now()
                ) <= 1
            ) {
                const claims = await getIdTokenClaims();
                const expirationTime = claims?.exp
                    ? fromUnixTime(claims.exp).toISOString()
                    : addHours(Date.now(), DefaultSubscriptionExpirationHours).toISOString();

                const subscriptionAsObject = notificationSubscription.toJSON();
                const { rid: subscriptionId } = await createOrUpdatePushNotificationSubscription({
                    webPushNotificationSubscription: {
                        ...subscriptionAsObject,
                        endpoint: subscriptionAsObject.endpoint!,
                        recipientId: staffMemberId,
                        expirationTime,
                    },
                }).unwrap();

                console.info(
                    `Subscribed to notifications for user ${staffMemberId} with expirationTime ${expirationTime} for id ${subscriptionId}.`
                );
            }
        };

        recordSubscription().catch((error) =>
            datadogLogs.logger.error(
                'Error recording subscription',
                { staffMemberId, facilityId },
                error
            )
        );
    }, [
        createOrUpdatePushNotificationSubscription,
        facilityId,
        getIdTokenClaims,
        getStaffMemberSubscriptionsForCurrentDevice,
        notificationSubscription,
        staffMemberId,
    ]);

    const contextValues: NotificationsContextValuesType = useMemo(
        () => ({
            areNotificationsSupported,
            displayNotification,
            notificationsPermission,
            shouldLoadNotificationsContext,

            ...(areNotificationsSupported && shouldLoadNotificationsContext
                ? {
                      dismissNotificationsByTag,
                      unsubscribeFromPush,
                  }
                : {
                      dismissNotificationsByTag: () => {},
                      unsubscribeFromPush: () => {},
                  }),
        }),
        [
            areNotificationsSupported,
            dismissNotificationsByTag,
            displayNotification,
            notificationsPermission,
            shouldLoadNotificationsContext,
            unsubscribeFromPush,
        ]
    );

    const handleCloseDrawer = () => {
        requestPermissions();
        setShowRequestPermissionsDrawer(false);
    };

    return (
        <NotificationsContext.Provider value={contextValues}>
            {props.children}
            <Drawer
                anchor="bottom"
                classes={{ paper: clsx('drawer-container') }}
                onClose={(_event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        // disable closing on backdrop click or escape key down
                        // see https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-remove-disablebackdropclick-prop
                    }
                }}
                open={shouldLoadNotificationsContext && showRequestPermissionsDrawer}
            >
                <DrawerTitle />

                <DrawerBody sx={{ alignItems: 'flex-start', gap: 2 }}>
                    <FontAwesomeIcon icon={faBellOn} color={palette.primary.main} size="2x" />
                    <Stack
                        sx={{
                            alignItems: 'flex-start',
                            gap: 1,
                        }}
                    >
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                            Allow notifications
                        </Typography>
                        <Typography variant="body2">
                            You must accept the browser notifications on the following screen to
                            enable this phone to receive alerts.
                        </Typography>
                    </Stack>
                </DrawerBody>

                <DrawerFooter>
                    <Button
                        disableElevation
                        fullWidth
                        className="ctaButton"
                        variant="contained"
                        onClick={handleCloseDrawer}
                    >
                        Got It
                    </Button>
                </DrawerFooter>
            </Drawer>
        </NotificationsContext.Provider>
    );
}

export default NotificationsProvider;
