import { sageBaseTabularRecordServiceApi as api } from './sageBaseTabularRecordServiceApi';

export const addTagTypes = ['dataSources', 'queryDefinitions', 'queryExecutions'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getDataSources: build.query<GetDataSourcesApiResponse, GetDataSourcesApiArg>({
                query: (queryArg) => ({
                    url: `/dataSources`,
                    headers: { Authorization: queryArg?.authorization },
                    params: { dialect: queryArg.dialect },
                }),
                providesTags: ['dataSources'],
            }),
            createDataSource: build.mutation<CreateDataSourceApiResponse, CreateDataSourceApiArg>({
                query: (queryArg) => ({
                    url: `/dataSources`,
                    method: 'POST',
                    body: queryArg.createOrUpdateDataSourceRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['dataSources'],
            }),
            getDataSource: build.query<GetDataSourceApiResponse, GetDataSourceApiArg>({
                query: (queryArg) => ({
                    url: `/dataSources/${queryArg.dataSourceRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['dataSources'],
            }),
            updateDataSource: build.mutation<UpdateDataSourceApiResponse, UpdateDataSourceApiArg>({
                query: (queryArg) => ({
                    url: `/dataSources/${queryArg.dataSourceRid}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateDataSourceRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['dataSources'],
            }),
            deleteDataSource: build.mutation<DeleteDataSourceApiResponse, DeleteDataSourceApiArg>({
                query: (queryArg) => ({
                    url: `/dataSources/${queryArg.dataSourceRid}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['dataSources'],
            }),
            getDataSourceRidFromName: build.query<
                GetDataSourceRidFromNameApiResponse,
                GetDataSourceRidFromNameApiArg
            >({
                query: (queryArg) => ({
                    url: `/dataSources/ridFromName/${queryArg.name}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['dataSources'],
            }),
            getQueryDefinitions: build.query<
                GetQueryDefinitionsApiResponse,
                GetQueryDefinitionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions`,
                    headers: { Authorization: queryArg?.authorization },
                    params: { dataSourceRid: queryArg.dataSourceRid },
                }),
                providesTags: ['queryDefinitions'],
            }),
            createQueryDefinition: build.mutation<
                CreateQueryDefinitionApiResponse,
                CreateQueryDefinitionApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions`,
                    method: 'POST',
                    body: queryArg.createOrUpdateQueryDefinitionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryDefinitions'],
            }),
            getQueryDefinition: build.query<
                GetQueryDefinitionApiResponse,
                GetQueryDefinitionApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions/${queryArg.queryDefinitionRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['queryDefinitions'],
            }),
            updateQueryDefinition: build.mutation<
                UpdateQueryDefinitionApiResponse,
                UpdateQueryDefinitionApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions/${queryArg.queryDefinitionRid}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateQueryDefinitionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryDefinitions'],
            }),
            deleteQueryDefinition: build.mutation<
                DeleteQueryDefinitionApiResponse,
                DeleteQueryDefinitionApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions/${queryArg.queryDefinitionRid}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryDefinitions'],
            }),
            getQueryDefinitionRidFromName: build.query<
                GetQueryDefinitionRidFromNameApiResponse,
                GetQueryDefinitionRidFromNameApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryDefinitions/ridFromName/${queryArg.name}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['queryDefinitions'],
            }),
            executeQuery: build.query<ExecuteQueryApiResponse, ExecuteQueryApiArg>({
                query: (queryArg) => ({
                    url: `/queryExecutions`,
                    method: 'POST',
                    body: queryArg.queryExecutionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['queryExecutions'],
            }),
            getQueryExecutionResult: build.query<
                GetQueryExecutionResultApiResponse,
                GetQueryExecutionResultApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryExecutions/${queryArg.queryExecutionResultRid}`,
                    headers: { Authorization: queryArg?.authorization },
                    params: { lastSuccessfulExecution: queryArg.lastSuccessfulExecution },
                }),
                providesTags: ['queryExecutions'],
            }),
            cancelQueryExecution: build.mutation<
                CancelQueryExecutionApiResponse,
                CancelQueryExecutionApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryExecutions/${queryArg.queryExecutionResultRid}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryExecutions'],
            }),
            expireQueryExecutionResult: build.mutation<
                ExpireQueryExecutionResultApiResponse,
                ExpireQueryExecutionResultApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryExecutions/${queryArg.queryExecutionResultRid}`,
                    method: 'PUT',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryExecutions'],
            }),
            searchQueryExecutionResults: build.mutation<
                SearchQueryExecutionResultsApiResponse,
                SearchQueryExecutionResultsApiArg
            >({
                query: (queryArg) => ({
                    url: `/queryExecutions/search`,
                    method: 'POST',
                    body: queryArg.queryExecutionResultSearchRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['queryExecutions'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageTabularRecordServiceApi };
export type GetDataSourcesApiResponse = /** status 200 OK */ DataSource[];
export type GetDataSourcesApiArg = {
    authorization?: string;
    dialect?: string;
};
export type CreateDataSourceApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type CreateDataSourceApiArg = {
    authorization?: string;
    createOrUpdateDataSourceRequest: CreateOrUpdateDataSourceRequest;
};
export type GetDataSourceApiResponse = /** status 200 OK */ DataSource;
export type GetDataSourceApiArg = {
    dataSourceRid: string;
    authorization?: string;
};
export type UpdateDataSourceApiResponse = /** status 200 OK */ RidResponse;
export type UpdateDataSourceApiArg = {
    dataSourceRid: string;
    authorization?: string;
    createOrUpdateDataSourceRequest: CreateOrUpdateDataSourceRequest;
};
export type DeleteDataSourceApiResponse = /** status 200 OK */ DataSource;
export type DeleteDataSourceApiArg = {
    dataSourceRid: string;
    authorization?: string;
};
export type GetDataSourceRidFromNameApiResponse = /** status 200 OK */ RidResponse;
export type GetDataSourceRidFromNameApiArg = {
    name: string;
    authorization?: string;
};
export type GetQueryDefinitionsApiResponse = /** status 200 OK */ QueryDefinition[];
export type GetQueryDefinitionsApiArg = {
    authorization?: string;
    dataSourceRid?: string;
};
export type CreateQueryDefinitionApiResponse = /** status 200 OK */ RidResponse;
export type CreateQueryDefinitionApiArg = {
    authorization?: string;
    createOrUpdateQueryDefinitionRequest: CreateOrUpdateQueryDefinitionRequest;
};
export type GetQueryDefinitionApiResponse = /** status 200 OK */ QueryDefinition;
export type GetQueryDefinitionApiArg = {
    queryDefinitionRid: string;
    authorization?: string;
};
export type UpdateQueryDefinitionApiResponse = /** status 200 OK */ RidResponse;
export type UpdateQueryDefinitionApiArg = {
    queryDefinitionRid: string;
    authorization?: string;
    createOrUpdateQueryDefinitionRequest: CreateOrUpdateQueryDefinitionRequest;
};
export type DeleteQueryDefinitionApiResponse = /** status 200 OK */ QueryDefinition;
export type DeleteQueryDefinitionApiArg = {
    queryDefinitionRid: string;
    authorization?: string;
};
export type GetQueryDefinitionRidFromNameApiResponse = /** status 200 OK */ RidResponse;
export type GetQueryDefinitionRidFromNameApiArg = {
    name: string;
    authorization?: string;
};
export type ExecuteQueryApiResponse = /** status 200 OK */ RidResponse;
export type ExecuteQueryApiArg = {
    authorization?: string;
    queryExecutionRequest: QueryExecutionRequest;
};
export type GetQueryExecutionResultApiResponse = /** status 200 OK */ QueryExecutionResult;
export type GetQueryExecutionResultApiArg = {
    queryExecutionResultRid: string;
    authorization?: string;
    lastSuccessfulExecution?: boolean;
};
export type CancelQueryExecutionApiResponse = unknown;
export type CancelQueryExecutionApiArg = {
    queryExecutionResultRid: string;
    authorization?: string;
};
export type ExpireQueryExecutionResultApiResponse = unknown;
export type ExpireQueryExecutionResultApiArg = {
    queryExecutionResultRid: string;
    authorization?: string;
};
export type SearchQueryExecutionResultsApiResponse =
    /** status 200 OK */ QueryExecutionResultSearchResult;
export type SearchQueryExecutionResultsApiArg = {
    authorization?: string;
    queryExecutionResultSearchRequest: QueryExecutionResultSearchRequest;
};
export type ConnectionProperty = {
    name?: string;
    value?: string;
};
export type DataSource = {
    name?: string;
    rid?: string;
    dialect?: string;
    connectionProperties?: ConnectionProperty[];
    healthStatus?: string;
    healthStatusDetails?: string;
};
export type CreateOrUpdateDataSourceRequest = {
    name?: string;
    dialect?: string;
    connectionProperties?: ConnectionProperty[];
};
export type RidResponse = {
    rid?: string;
};
export type QueryParameter = {
    name?: string;
    type?: string;
    permissionScope?: string;
};
export type QueryParameterValue = {
    parameterName?: string;
    values?: string[];
};
export type QueryDefinition = {
    name?: string;
    rid?: string;
    dataSourceRid?: string;
    template?: string;
    parameters?: QueryParameter[];
    validationParameterValues?: QueryParameterValue[];
    timeoutInMs?: number;
    resultExpirationTimeoutInMs?: number;
    healthStatus?: string;
    healthStatusDetails?: string;
};
export type CreateOrUpdateQueryDefinitionRequest = {
    name?: string;
    dataSourceRid?: string;
    template?: string;
    parameters?: QueryParameter[];
    validationParameterValues?: QueryParameterValue[];
    timeoutInMs?: number;
    resultExpirationTimeoutInMs?: number;
};
export type QueryExecutionRequest = {
    queryDefinitionRid?: string;
    parameterValues?: QueryParameterValue[];
    ignoreResultExpirationTimeout?: boolean;
};
export type Column = {
    name?: string;
    type?: string;
};
export type Table = {
    columns?: Column[];
    data?: string[][];
};
export type QueryExecutionResult = {
    rid?: string;
    queryDefinitionRid?: string;
    executionCount?: number;
    status?: string;
    statusDetails?: string;
    creationTimestamp?: string;
    lastUpdateTimestamp?: string;
    timeoutInMs?: number;
    expirationTimeoutInMs?: number;
    table?: Table;
};
export type QueryExecutionResultSearchResult = {
    queryExecutionResults?: QueryExecutionResult[];
    pageSize?: number;
    numPages?: number;
    numResults?: number;
};
export type QueryExecutionResultSearchRequest = {
    queryDefinitionRids?: string[];
    queryExecutionResultRids?: string[];
    statuses?: string[];
    pageSize?: number;
    pageNumber?: number;
};
export const {
    useGetDataSourcesQuery,
    useLazyGetDataSourcesQuery,
    useCreateDataSourceMutation,
    useGetDataSourceQuery,
    useLazyGetDataSourceQuery,
    useUpdateDataSourceMutation,
    useDeleteDataSourceMutation,
    useGetDataSourceRidFromNameQuery,
    useLazyGetDataSourceRidFromNameQuery,
    useGetQueryDefinitionsQuery,
    useLazyGetQueryDefinitionsQuery,
    useCreateQueryDefinitionMutation,
    useGetQueryDefinitionQuery,
    useLazyGetQueryDefinitionQuery,
    useUpdateQueryDefinitionMutation,
    useDeleteQueryDefinitionMutation,
    useGetQueryDefinitionRidFromNameQuery,
    useLazyGetQueryDefinitionRidFromNameQuery,
    useExecuteQueryQuery,
    useLazyExecuteQueryQuery,
    useGetQueryExecutionResultQuery,
    useLazyGetQueryExecutionResultQuery,
    useCancelQueryExecutionMutation,
    useExpireQueryExecutionResultMutation,
    useSearchQueryExecutionResultsMutation,
} = injectedRtkApi;
