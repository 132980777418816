import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CareTypes } from 'src/constants/CareTypes';
import {
    selectEnterpriseId,
    selectFacilityId,
    selectUserCareZoneIds,
    setFacilityCareZones,
} from 'src/store/configSlice';
import { CareZone, useGetAllCareZonesQuery, useUnitsSearchQuery } from 'src/store/sageAdminApi';

const isCareZoneIndependentLiving = (careZone: CareZone) =>
    careZone?.name?.toLowerCase() === 'independent living' ||
    careZone?.unitTags?.careTypes?.includes(CareTypes.IndependentLiving) ||
    careZone?.unitTags?.wards?.includes(CareTypes.IndependentLiving);

function useCareZones() {
    const dispatch = useDispatch();

    const enterpriseId = useSelector(selectEnterpriseId);
    const facilityId = useSelector(selectFacilityId);
    const userCareZoneIds = useSelector(selectUserCareZoneIds);

    const { data: unitSearchResults, isLoading: isLoadingUnits } = useUnitsSearchQuery(
        { unitSearchRequest: { enterpriseIds: [enterpriseId], facilityIds: [facilityId] } },
        { skip: !enterpriseId || !facilityId }
    );
    const { units } = unitSearchResults || {};

    const { data: facilityCareZones, isLoading: isLoadingCareZones } = useGetAllCareZonesQuery(
        { facilityRid: facilityId },
        { skip: !facilityId }
    );

    const userCareZones = useMemo(() => {
        if (!facilityCareZones || facilityCareZones.length === 0) {
            return [];
        }

        return facilityCareZones.filter((careZone) => userCareZoneIds.includes(careZone.id!));
    }, [facilityCareZones, userCareZoneIds]);

    const { careZonesWithUnits, careZonesWithoutUnits } = useMemo(
        () =>
            (facilityCareZones || []).reduce?.(
                (
                    acc: { careZonesWithUnits: CareZone[]; careZonesWithoutUnits: CareZone[] },
                    careZone
                ) => {
                    const unitsInCareZone = (units || []).filter?.(
                        (unit) =>
                            (unit?.careType &&
                                careZone.unitTags?.careTypes?.includes(unit.careType)) ||
                            (unit?.ward && careZone.unitTags?.wards?.includes(unit?.ward))
                    );

                    if (!unitsInCareZone) return acc;

                    const updatedCareZone = { ...careZone, units: unitsInCareZone };
                    if (unitsInCareZone?.length > 0) {
                        acc.careZonesWithUnits.push(updatedCareZone);
                    } else {
                        acc.careZonesWithoutUnits.push(updatedCareZone);
                    }

                    return acc;
                },
                { careZonesWithUnits: [], careZonesWithoutUnits: [] }
            ),
        [facilityCareZones, units]
    );

    const userCanChangeCareZones = useMemo(() => {
        const userHasSelectedCareZones = userCareZones.length > 0;

        if (
            careZonesWithUnits?.length > 1 ||
            (careZonesWithUnits?.length > 0 && !userHasSelectedCareZones)
        ) {
            return true;
        }

        return careZonesWithUnits?.some?.((facilityCareZone) =>
            userCareZones?.every(
                (userCareZone: CareZone) => userCareZone.id !== facilityCareZone.id
            )
        );
    }, [careZonesWithUnits, userCareZones]);

    const isIndependentLiving = useMemo(
        () => userCareZones.some(isCareZoneIndependentLiving),
        [userCareZones]
    );

    const isOnlyIndependentLiving = useMemo(
        () => userCareZones.every(isCareZoneIndependentLiving),
        [userCareZones]
    );

    const independentLivingCareZones = useMemo(
        () => facilityCareZones?.filter(isCareZoneIndependentLiving),
        [facilityCareZones]
    );

    useEffect(() => {
        if (facilityCareZones) {
            dispatch(setFacilityCareZones(facilityCareZones));
        }
    }, [dispatch, facilityCareZones]);

    return {
        careZones: facilityCareZones,
        careZonesWithUnits,
        careZonesWithoutUnits,
        independentLivingCareZones,
        isLoading: isLoadingUnits || isLoadingCareZones,
        isIndependentLiving,
        isOnlyIndependentLiving,
        userCareZones,
        userCanChangeCareZones,
    };
}

export default useCareZones;
