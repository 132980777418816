import { differenceInSeconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

import { datadogRum } from '@datadog/browser-rum';

import { useLazyGetImageForResourceQuery } from 'src/store/sageAdminApi';

const DAY_IN_SECONDS = 86_400;

/**
 * A static, stable object reference to use as a default value for the optional imageContainerRef.
 * This prevents useIntersection from endlessly re-rendering when imageContainerRef is not provided.
 */
const STATIC_EMPTY_REF = { current: null };

/**
 * @typedef {Object} UseImageCacheOptions
 * @property {React.MutableRefObject} [imageContainerRef] - optional ref to the image container, used to determine if image is visible
 * @property {number} [expiresIn] - number of seconds before image should be refetched
 * @property {boolean} [useLocalStorage] - whether to use localStorage instead of sessionStorage, defaults to false
 *
 * @param {string | undefined} resourceId - the id of the resource to fetch
 * @param {string} [imageValueOverride] - optional image value to use instead of fetching
 * @param {UseImageCacheOptions} [options]
 *
 * @returns {[string, { isLoading: boolean, isFetching: boolean, isError: boolean, refetch: () => void }]}
 */
function useImageCache(resourceId, imageValueOverride, options) {
    const [image, setImage] = useState(undefined);

    const {
        imageContainerRef = STATIC_EMPTY_REF,
        expiresIn = DAY_IN_SECONDS,
        useLocalStorage = false,
    } = options || {};

    const imageIntersection = useIntersection(imageContainerRef, {});
    const isVisible = !!imageIntersection?.isIntersecting;

    const [getMediaResource, { isLoading, isFetching, isError }] =
        useLazyGetImageForResourceQuery();

    const updateAndSyncCache = useCallback(
        (resourceId, imageData) => {
            const value = { imageData, timestamp: Date.now() };
            setImage(value);

            try {
                if (useLocalStorage) {
                    localStorage?.setItem(resourceId, JSON.stringify(value));
                } else {
                    sessionStorage?.setItem(resourceId, JSON.stringify(value));
                }
            } catch (error) {
                datadogRum.addError(error, {
                    message: `Error caching image for resource ${resourceId}`,
                    resourceId,
                    storageType: useLocalStorage ? 'localStorage' : 'sessionStorage',
                });
            }
        },
        [useLocalStorage]
    );

    const fetchAndCacheImage = useCallback(async () => {
        try {
            if (resourceId) {
                const { data } = await getMediaResource({ resourceId });
                updateAndSyncCache(resourceId, data);
            }
        } catch (error) {
            datadogRum.addError(error, {
                message: `Error fetching image for resource`,
                resourceId,
            });
        }
    }, [getMediaResource, resourceId, updateAndSyncCache]);

    const getImage = useCallback(() => {
        const cachedImage = useLocalStorage
            ? localStorage?.getItem(resourceId)
            : sessionStorage?.getItem(resourceId);

        if (cachedImage) {
            let parsedImage;
            try {
                parsedImage = JSON.parse(cachedImage);
            } catch (error) {
                datadogRum.addError(error, {
                    message: `Error parsing cached image for resource`,
                    resourceId,
                    storageType: useLocalStorage ? 'localStorage' : 'sessionStorage',
                });
            }
            const { imageData, timestamp } = parsedImage || {};

            if (
                // If we don't have an image yet and either don't have a ref or the image is visible
                (imageData == null && (imageContainerRef?.current == null || isVisible)) ||
                // Or if we have a timestamp of when the image was last fetched, but it's expired
                (expiresIn && timestamp && differenceInSeconds(Date.now(), timestamp) > expiresIn)
            ) {
                fetchAndCacheImage();
            } else {
                setImage(parsedImage);
            }
        } else if (imageContainerRef?.current == null || isVisible) {
            fetchAndCacheImage();
        }
    }, [expiresIn, fetchAndCacheImage, imageContainerRef, isVisible, resourceId, useLocalStorage]);

    useEffect(() => {
        // If we have an image value override, use that instead of fetching
        if (imageValueOverride) {
            updateAndSyncCache(resourceId, imageValueOverride);
        } else if (resourceId) {
            getImage();
        }
    }, [getImage, imageValueOverride, resourceId, updateAndSyncCache]);

    return [image?.imageData, { isLoading, isFetching, isError, refetch: fetchAndCacheImage }];
}

export default useImageCache;
