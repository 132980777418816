import clsx from 'clsx';
import { isValidElement, ChangeEvent } from 'react';

import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    RadioGroupProps,
    Typography,
} from '@mui/material';

import './styles/GroupOptions.styles.scss';

export const Variants = {
    Default: 'default',
    Card: 'card',
    Outlined: 'outlined',
};

export interface Option {
    label: string | import('react').ReactNode | ((props?: unknown) => JSX.Element);
    value: string;
    disabled?: boolean;
}

interface RadioGroupOptionsProps {
    options: Option[];
    onSelectOption: (selectedOption: string) => void;
    currentOption?: string;
    selectedOption?: string;
    variant?: (typeof Variants)[keyof typeof Variants];
    radioGroupProps?: RadioGroupProps;
}

function RadioGroupOptions(props: RadioGroupOptionsProps) {
    const {
        options = [],
        onSelectOption = () => {},
        currentOption,
        selectedOption = '',
        variant = Variants.Default,
        radioGroupProps = {},
    } = props;

    return (
        <FormControl fullWidth>
            <RadioGroup
                {...radioGroupProps}
                value={selectedOption}
                onChange={(e: ChangeEvent, value: string) => onSelectOption(value)}
                className="group-container"
            >
                {options.map((option) => (
                    <Box
                        key={option.value}
                        className={clsx(
                            'group-option-item',
                            `group-option-item__${variant}`,
                            selectedOption === option.value && 'selected',
                            option.disabled && 'disabled'
                        )}
                    >
                        {currentOption !== undefined && currentOption === option.value && (
                            <Box className="current-option-indicator">
                                <Typography
                                    variant="subtitle1"
                                    component="p"
                                    sx={{
                                        color: 'purple.900',
                                    }}
                                >
                                    Current
                                </Typography>
                            </Box>
                        )}

                        <FormControlLabel
                            disabled={option.disabled}
                            value={option.value}
                            control={<Radio disableRipple={variant === Variants.Default} />}
                            label={
                                isValidElement(option.label)
                                    ? option.label
                                    : typeof option.label === 'function'
                                    ? option.label(option)
                                    : option.label
                            }
                            sx={{
                                width: '100%',
                                mx: 0,
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                gap: 2,
                            }}
                        />
                    </Box>
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioGroupOptions;
