import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';

import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import ConfirmLoginCode from 'src/components/auth/ConfirmLoginCode';
import PhoneNumberLogin from 'src/components/auth/PhoneNumberLogin';

export const AuthState = {
    PhoneNumberLogin: 'phone-number-login',
    ConfirmCode: 'confirm-code',
};

export const LogoutDialogTypes = {
    LoginRequired: 'login-required',
    SessionExpired: 'session-expired',
    TokenError: 'token-error',
};

function LoginPage() {
    const { trackEvent } = useContext(AnalyticsContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const [authState, setAuthState] = useState(AuthState.PhoneNumberLogin);
    const [logoutDialogType, setLogoutDialogType] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const handleOnNext = (phoneNumber) => {
        setPhoneNumber(phoneNumber);
        setAuthState(AuthState.ConfirmCode);
    };

    const handleOnBack = () => {
        setAuthState(AuthState.PhoneNumberLogin);
    };

    const handleConfirmLogout = () => {
        trackEvent('Logout Acknowledged', { objectType: ObjectTypes.Button, logoutDialogType });
        setShowLogoutDialog(false);
        setSearchParams({});
    };

    useEffect(() => {
        const logoutReason = searchParams?.get('logoutReason');
        if (!!logoutReason) {
            setShowLogoutDialog(true);
            setLogoutDialogType(logoutReason);
        }
    }, [searchParams]);

    useEffect(() => {
        // make sure we are scrolled to the top with mobile Safari's collapsing URL bar
        window.scrollTo(0, 0);

        if (authState !== AuthState.PhoneNumberLogin && authState !== AuthState.ConfirmCode) {
            // Reset to phone number entry if we are in an invalid state
            setAuthState(AuthState.PhoneNumberLogin);
        }
    }, [authState]);

    return (
        <Stack
            sx={{
                width: '100%',
                minHeight: '100dvh',
                bgcolor: 'white.main',
            }}
        >
            {authState === AuthState.PhoneNumberLogin && <PhoneNumberLogin onNext={handleOnNext} />}
            {authState === AuthState.ConfirmCode && (
                <ConfirmLoginCode onBack={handleOnBack} phoneNumber={phoneNumber} />
            )}
            <Dialog
                open={showLogoutDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
                PaperProps={{ sx: { borderRadius: 4 } }}
                TransitionProps={{
                    onExited: () => {
                        setLogoutDialogType(null);
                    },
                }}
            >
                <DialogContent className="dialogContentContainer">
                    <Typography
                        variant="h4"
                        align="center"
                        sx={{
                            mb: 1.5,
                        }}
                    >
                        {logoutDialogType === LogoutDialogTypes.LoginRequired && 'Login Required'}
                        {logoutDialogType === LogoutDialogTypes.SessionExpired &&
                            'Session Timed Out'}
                        {logoutDialogType === LogoutDialogTypes.TokenError &&
                            "You've been logged out"}
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{
                            color: 'grey.500',
                        }}
                    >
                        {logoutDialogType === LogoutDialogTypes.LoginRequired &&
                            'Please log in to use the app.'}
                        {logoutDialogType === LogoutDialogTypes.SessionExpired &&
                            'Your session has timed out, please log in again to continue using the app.'}
                        {logoutDialogType === LogoutDialogTypes.TokenError &&
                            'Please log in again to continue using the app.'}
                    </Typography>
                </DialogContent>
                <DialogActions className="dialogButtonContainer">
                    <Button
                        variant="contained"
                        onClick={handleConfirmLogout}
                        disableElevation
                        color="primary"
                        fullWidth
                        size="large"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

export default LoginPage;
