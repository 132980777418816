import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import CareZoneCheckbox from './careZoneCheckbox';
import LoadingState from './loadingState';
import useCareZones from 'src/hooks/useCareZones';
import useFacilityStaff from 'src/hooks/useFacilityStaff';
import { getCareZoneGroups } from 'src/utils/careZoneUtils';

import './styles/careZoneSelection.scss';

/**
 * @typedef CareZoneSelectionProps
 * @property {Object} [containerProps]
 * @property {boolean} [isDisabled]
 * @property {number} [maxHeight]
 * @property {string[]} [selectedIds]
 * @property {function} [setSelectedIds]
 * @property {boolean} [shouldPoll] - If true, will poll for staff on shift
 * @property {boolean} [useDefaultExclusions] - If true, will use the default exclusions defined in careZoneUtils.ts
 *
 * @param {CareZoneSelectionProps} props
 * @returns {React.ReactNode}
 */
export default function CareZoneSelection(props) {
    const {
        containerProps = {},
        isDisabled = false,
        maxHeight,
        selectedIds = [],
        setSelectedIds = () => {},
        shouldPoll = false,
    } = props;

    const { careZonesWithUnits, isLoading } = useCareZones();

    const { careZoneOnShiftMap, isFetching: isFetchingStaff } = useFacilityStaff(true, {
        pollingInterval: shouldPoll ? 5000 : 0,
    });

    /**
     * NOTE: this previously supported carezone exclusions, but that functionality was removed so the
     * careZoneGroups logic is no longer necessary. This is left in place for now to reduce potential bugs and
     * in case we want to add carezone exclusions back in the future.
     *
     * However, we should consider simplifying this if we don't want to add carezone exclusions back.
     */
    const { groups: careZoneGroups, indexMap: careZoneGroupIndex } = useMemo(
        () => getCareZoneGroups(careZonesWithUnits, {}),
        [careZonesWithUnits]
    );

    const handleClickCareZone = (careZone) => {
        if (selectedIds.includes(careZone.id)) {
            setSelectedIds(selectedIds.filter((id) => id !== careZone.id));
        } else {
            const selectedGroupIndex = careZoneGroupIndex.get(careZone.id);
            const selectedCareZoneIdsInGroup = [...selectedIds, careZone.id].filter(
                (id) => careZoneGroupIndex.get(id) === selectedGroupIndex
            );
            setSelectedIds(selectedCareZoneIdsInGroup);
        }
    };

    return (
        <>
            {isLoading && <LoadingState />}
            {!isLoading && (
                <Box
                    {...containerProps}
                    sx={{
                        overflow: 'scroll',
                        maxHeight: maxHeight || 'unset',
                        m: -1,
                        p: 1,
                    }}
                >
                    {careZoneGroups?.map((careZoneGroup, groupIndex) => (
                        <div key={`group-${groupIndex}`}>
                            {groupIndex > 0 && (
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'center',
                                        gap: 2.5,
                                        my: 1.5,
                                    }}
                                >
                                    <hr className="divider-line" />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textTransform: 'uppercase',
                                            color: 'grey.500',
                                        }}
                                    >
                                        OR
                                    </Typography>
                                    <hr className="divider-line" />
                                </Stack>
                            )}
                            {careZoneGroup.map((careZone) => (
                                <CareZoneCheckbox
                                    careZone={careZone}
                                    isChecked={selectedIds.includes(careZone.id)}
                                    isDisabled={isDisabled}
                                    isLoadingMembersOnShift={isFetchingStaff}
                                    key={careZone.id}
                                    membersOnShift={careZoneOnShiftMap?.[careZone.id]?.length}
                                    onClick={handleClickCareZone}
                                />
                            ))}
                        </div>
                    ))}
                </Box>
            )}
        </>
    );
}
