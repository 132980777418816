import clsx from 'clsx';
import {
    ComponentType,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AddTaskNoteDrawer from './AddTaskNoteDrawer';
import CareConfirmationDrawer from './CareConfirmationDrawer';
import CompleteInsightDrawer from './CompleteInsightDrawer';
import ConfirmationDrawer from './ConfirmationDrawer';
import DoorScheduleDrawer from './DoorScheduleDrawer';
import ExploreInsightsDrawer from './ExploreInsightsDrawer';
import PhoneSubscriptionDrawer from './PhoneSubscriptionDrawer';
import ReceiveHighFiveDrawer from './ReceiveHighFiveDrawer';
import ResidentDetailsDrawer from './ResidentDetailsDrawer';
import ResidentPendantsDrawer from './ResidentPendantsDrawer';
import ResolveTaskDrawer from './ResolveTaskDrawer';
import SearchResidentDrawer from './SearchResidentDrawer';
import SelectCareZoneDrawer from './SelectCareZoneDrawer';
import SelectDeviceDrawer from './SelectDeviceDrawer';
import SelectDoorDrawer from './SelectDoorDrawer';
import SelectEmergencyReasonDrawer from './SelectEmergencyReasonDrawer';
import SelectEnterpriseDrawer from './SelectEnterpriseDrawer';
import SelectMultiOutcomeResolutionDrawer from './SelectMultiOutcomeResolutionDrawer';
import SelectOptionsDrawer from './SelectOptionsDrawer';
import SelectPersonDrawer from './SelectPersonDrawer';
import SelectResidentsWithButtonsDrawer from './SelectResidentsWithButtonsDrawer';
import SelectRoomCheckinDrawer from './SelectRoomCheckinDrawer';
import SelectUnitDrawer from './SelectUnitDrawer';
import SelectWardDrawer from './SelectWardDrawer';
import SelectWasFallDrawer from './SelectWasFallDrawer';
import SendHighFiveDrawer from './SendHighFiveDrawer';
import StaffMemberActionsDrawer from './StaffMemberActionsDrawer';
import UnitCheckInDrawer from './UnitCheckInDrawer';
import UnitDetailDrawer from './UnitDetailDrawer';
import omit from 'lodash/omit';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { Drawers, SupportedDrawer } from 'src/constants/Drawers';
import useBlockPageScroll from 'src/hooks/useBlockPageScroll';

import './styles/Drawer.styles.scss';

export type DrawerContextValuesType = {
    openDrawer: (
        drawer: DrawerType,
        handleOnComplete?: Function,
        handleOnCancel?: Function
    ) => void;
    openDrawerWorkflow: (
        drawers: DrawerType[],
        handleOnComplete?: Function,
        handleOnCancel?: Function
    ) => void;
    closeDrawer: () => void;
};

export interface DrawerType {
    type: SupportedDrawer;
    priority?: number;
    disableCloseOnBackdropClick?: boolean;
    disableCloseOnEscapeKeyDown?: boolean;
    fullHeight?: boolean;
    showCloseIcon?: boolean;
    props?: Record<string, unknown>;
}

interface DrawerManagerProps {
    children: React.ReactNode;
}

export type OnClose = (event?: InputEvent | {}, reason?: string) => void;
export type OnNext = (payload?: {}, nextDrawer?: DrawerType) => void;
export type OnPrevious = (addCurrentToFuture?: boolean, propsToOmit?: string[]) => void;

export interface DrawerComponentProps {
    hasPreviousDrawer: boolean;
    hasNextDrawer: boolean;
    onClose: OnClose;
    onNext: OnNext;
    onPrevious: OnPrevious;
    title?: string;
}

const DrawersMap: {
    [key: string]: ((props: any) => ReactNode) | undefined;
} = {
    [Drawers.AddTaskNote]: AddTaskNoteDrawer,
    [Drawers.CareConfirmation]: CareConfirmationDrawer,
    [Drawers.Confirmation]: ConfirmationDrawer,
    [Drawers.CompleteInsight]: CompleteInsightDrawer,
    [Drawers.DoorScheduleDrawer]: DoorScheduleDrawer,
    [Drawers.ExploreInsights]: ExploreInsightsDrawer,
    [Drawers.PhoneSubscription]: PhoneSubscriptionDrawer,
    [Drawers.ReceiveHighFive]: ReceiveHighFiveDrawer,
    [Drawers.ResolveTask]: ResolveTaskDrawer,
    [Drawers.SearchResident]: SearchResidentDrawer,
    [Drawers.SelectCareZone]: SelectCareZoneDrawer,
    [Drawers.SelectDevice]: SelectDeviceDrawer,
    [Drawers.SelectEmergencyReason]: SelectEmergencyReasonDrawer,
    [Drawers.SelectEnterprise]: SelectEnterpriseDrawer,
    [Drawers.SelectMultiOutcomeResolution]: SelectMultiOutcomeResolutionDrawer,
    [Drawers.SelectOptions]: SelectOptionsDrawer,
    [Drawers.SelectPerson]: SelectPersonDrawer,
    [Drawers.SelectResidentsWithButtons]: SelectResidentsWithButtonsDrawer,
    [Drawers.SelectRoomCheckin]: SelectRoomCheckinDrawer,
    [Drawers.SelectWasFall]: SelectWasFallDrawer,
    [Drawers.SendHighFive]: SendHighFiveDrawer,
    [Drawers.StaffMemberActionsDrawer]: StaffMemberActionsDrawer,
    [Drawers.UnitCheckIn]: UnitCheckInDrawer,
    [Drawers.UnitDetail]: UnitDetailDrawer,
    [Drawers.SelectDoor]: SelectDoorDrawer,
    [Drawers.SelectWard]: SelectWardDrawer,
    [Drawers.SelectUnit]: SelectUnitDrawer,
    [Drawers.ResidentPendants]: ResidentPendantsDrawer,
    [Drawers.ResidentDetails]: ResidentDetailsDrawer,
};

export const DrawerContext = createContext({
    openDrawer: (
        _drawer: DrawerType,
        _handleOnComplete?: Function,
        _handleOnCancel?: Function
    ) => {},
    openDrawerWorkflow: (
        _drawers: DrawerType[],
        _handleOnComplete?: Function,
        _handleOnCancel?: Function
    ) => {},
    closeDrawer: () => {},
});

function DrawerManager(props: DrawerManagerProps) {
    const { children } = props;

    const { palette, spacing } = useTheme();

    const { trackEvent } = useContext(AnalyticsContext);

    const [currentDrawer, setCurrentDrawer] = useState<DrawerType | null>();
    const [expectedDrawer, setExpectedDrawer] = useState<DrawerType | null>();
    const [isOpen, setIsOpen] = useState(false);

    const updatePageScrollBlock = useBlockPageScroll();

    const past = useRef<DrawerType[]>([]);
    const future = useRef<DrawerType[]>([]);

    const previousOnCompleteWorkflow = useRef<Function | null>(null);
    const onCompleteWorkflow = useRef<Function | undefined>(undefined);
    const onCancelWorkflow = useRef<Function | undefined>(undefined);

    const disableCloseOnBackdropClick = useRef(false);
    const disableCloseOnEscapeKeyDown = useRef(true);

    /**
     * Used in handleNextDrawer to signal to handleOpenDrawer that the next drawer should be queued instead of
     * replacing the current drawer, or else the new drawer will be closed as part of the drawer completion workflow.
     *
     * This specifically allows the `onCompleteWorkflow` function to add to the drawer queue if needed, which allows
     * us to chain multiple `openDrawer` calls together to create a disparate drawer workflow.
     */
    const isCompletingWorkflow = useRef(false);

    useEffect(() => {
        if (isOpen) {
            updatePageScrollBlock(true, window.scrollY);
        } else {
            updatePageScrollBlock(false, window.scrollY);
        }
    }, [isOpen, updatePageScrollBlock]);

    const resetDrawerState = () => {
        setCurrentDrawer(null);
        setExpectedDrawer(null);
        setIsOpen(false);
        previousOnCompleteWorkflow.current = null;
        onCancelWorkflow.current = undefined;
        onCompleteWorkflow.current = undefined;
        past.current = [];
        future.current = [];
        disableCloseOnBackdropClick.current = false;
        disableCloseOnEscapeKeyDown.current = true;
    };

    const handleCloseDrawer: OnClose = useCallback(
        async (event, reason) => {
            if (
                (!!disableCloseOnBackdropClick.current && reason === 'backdropClick') ||
                (!!disableCloseOnEscapeKeyDown.current && reason === 'escapeKeyDown')
            ) {
                return;
            }

            trackEvent('Drawer Closed', { drawerType: currentDrawer?.type, reason });

            if (onCancelWorkflow.current) {
                await onCancelWorkflow.current?.(currentDrawer?.props || {});
            }
            setExpectedDrawer(null);
            setIsOpen(false);
        },
        [currentDrawer, trackEvent]
    );

    const handleOpenDrawer = useCallback(
        (drawer: DrawerType, onComplete?: Function, onCancel?: Function) => {
            const {
                disableCloseOnBackdropClick: shouldDisableBackdropClickClose = false,
                disableCloseOnEscapeKeyDown: shouldDisableEscapeKeyClose = true,
                priority = 0,
            } = drawer || {};

            if (!!DrawersMap[drawer?.type]) {
                if ((currentDrawer?.priority || 0) > priority) {
                    // If there's a currently opened drawer with higher priority, queue the next drawer
                    datadogLogs.logger.info('Queuing drawer', {
                        currentDrawer: {
                            type: currentDrawer?.type,
                            priority: currentDrawer?.priority,
                        },
                        queuedDrawer: { type: drawer?.type, priority },
                    });
                    future.current.unshift(drawer);
                } else {
                    disableCloseOnBackdropClick.current = !!shouldDisableBackdropClickClose;
                    disableCloseOnEscapeKeyDown.current = !!shouldDisableEscapeKeyClose;

                    if (isCompletingWorkflow.current) {
                        setExpectedDrawer(drawer);
                    } else {
                        setCurrentDrawer(drawer);
                    }

                    if (onCompleteWorkflow.current) {
                        previousOnCompleteWorkflow.current = onCompleteWorkflow.current;
                    }
                    onCompleteWorkflow.current = onComplete;
                    onCancelWorkflow.current = onCancel;
                    setIsOpen(true);
                }
            } else {
                datadogLogs.logger.warn(`Drawer type ${drawer?.type} is not supported`);
                resetDrawerState();
            }
        },
        [currentDrawer]
    );

    /**
     * Begins a drawer workflow and opens the first drawer in the provided list
     */
    const handleOpenDrawerWorkflow = useCallback(
        (drawers: DrawerType[] = [], onComplete?: Function, onCancel?: Function) => {
            if (drawers?.length > 0) {
                const drawer = drawers?.[0];
                future.current = drawers.slice(1);
                handleOpenDrawer(drawer, onComplete, onCancel);
            } else {
                datadogLogs.logger.warn('No drawers passed into workflow');
                resetDrawerState();
            }
        },
        [handleOpenDrawer]
    );

    /**
     * Closes the current drawer and opens the previous drawer in the workflow. If there are no previous drawers,
     * the workflow is completed and the drawer is closed.
     */
    const handlePreviousDrawer: OnPrevious = (addCurrentToFuture = true, propsToOmit) => {
        if (past.current?.length > 0) {
            const drawer = past.current.pop();
            setExpectedDrawer(drawer);
            setIsOpen(false);
            if (addCurrentToFuture) {
                const drawerPropsToKeep = propsToOmit
                    ? omit(currentDrawer?.props || {}, propsToOmit)
                    : currentDrawer?.props || {};
                if (currentDrawer) {
                    future.current.unshift({ ...currentDrawer, props: drawerPropsToKeep });
                }
            } else {
                if (previousOnCompleteWorkflow.current) {
                    onCompleteWorkflow.current = previousOnCompleteWorkflow.current;
                    previousOnCompleteWorkflow.current = null;
                }
            }
        } else {
            console.warn('No previous drawers');
            handleCloseDrawer();
        }
    };

    /**
     * If specified, opens the next drawer, otherwise takes the next drawer from the provided workflow.
     * If there are no next drawers, completes the workflow and closes the drawer.
     *
     * Only properties inside "prop" will be persisted from the current to the next drawer
     */
    const handleNextDrawer: OnNext = async (payload = {}, nextDrawer?: DrawerType) => {
        // Pick out properties that should not be carried over to the next drawer
        const {
            title: _unused_title,
            showCancelAction: _unused_show_cancel_action,
            ...propsToKeep
        } = currentDrawer?.props || {};

        const nextDrawerProps = nextDrawer?.props || {};

        if (nextDrawer && nextDrawer.type) {
            setExpectedDrawer({
                ...nextDrawer,
                props: { ...nextDrawerProps, ...propsToKeep, ...payload },
            });
        } else if (future.current?.length > 0) {
            const next = future.current?.shift?.();

            if (next) {
                const {
                    disableCloseOnBackdropClick: shouldDisableBackdropClickClose,
                    disableCloseOnEscapeKeyDown: shouldDisableEscapeKeyClose,
                    props: nextDrawerProps,
                    ...restDrawerConfigs
                } = next || {};

                if (shouldDisableBackdropClickClose === undefined) {
                    disableCloseOnBackdropClick.current = !!shouldDisableBackdropClickClose;
                }
                if (shouldDisableEscapeKeyClose === undefined) {
                    disableCloseOnEscapeKeyDown.current = !!shouldDisableEscapeKeyClose;
                }

                // Note: the order of the prop spread here is to maintain any user-specified props from future drawers (e.g., title).
                // However, this can have the unintended consequence of overriding props from the current drawer (e.g., resolutionType).
                // TODO: consider a better way to handle this
                setExpectedDrawer({
                    ...restDrawerConfigs,
                    props: { ...propsToKeep, ...payload, ...nextDrawerProps },
                });
            }
        } else {
            isCompletingWorkflow.current = true;
            await onCompleteWorkflow.current?.({ ...propsToKeep, ...payload });
            isCompletingWorkflow.current = false;
        }

        if (currentDrawer) {
            past.current.push(currentDrawer);
            setIsOpen(false);
        }
    };

    const handleDrawerEntered = () => {
        if (expectedDrawer) {
            setCurrentDrawer(expectedDrawer);
            setExpectedDrawer(null);
        }
    };

    const handleDrawerExited = useCallback(() => {
        if (expectedDrawer) {
            setCurrentDrawer(expectedDrawer);
            setIsOpen(true);
        } else {
            resetDrawerState();
        }
    }, [expectedDrawer]);

    const DrawerComponent: ComponentType<any> | undefined = currentDrawer
        ? (DrawersMap[currentDrawer.type] as ComponentType<any>)
        : undefined;
    const { fullHeight = false, showCloseIcon = false, props: drawerProps } = currentDrawer || {};

    const drawerContextValues: DrawerContextValuesType = useMemo(
        () => ({
            openDrawer: handleOpenDrawer,
            openDrawerWorkflow: handleOpenDrawerWorkflow,
            closeDrawer: handleCloseDrawer,
        }),
        [handleCloseDrawer, handleOpenDrawer, handleOpenDrawerWorkflow]
    );

    return (
        <DrawerContext.Provider value={drawerContextValues}>
            <>
                {children}
                <Drawer
                    anchor="bottom"
                    classes={{
                        paper: clsx('drawer-container', !!fullHeight && 'full-height'),
                    }}
                    onClose={handleCloseDrawer}
                    open={isOpen}
                    SlideProps={{
                        onEntered: handleDrawerEntered,
                        onExited: handleDrawerExited,
                        timeout: { enter: 250, exit: 150 },
                    }}
                    data-testid="drawer-container"
                >
                    {DrawerComponent && (
                        <DrawerComponent
                            {...drawerProps}
                            hasPreviousDrawer={!!(past.current?.length > 0)}
                            hasNextDrawer={!!(future.current?.length > 0)}
                            onClose={handleCloseDrawer}
                            onNext={handleNextDrawer}
                            onPrevious={handlePreviousDrawer}
                        />
                    )}

                    {showCloseIcon && (
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDrawer}
                            size="medium"
                            disableRipple
                            sx={{
                                position: 'absolute',
                                top: spacing(2.5),
                                right: spacing(2.5),
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} color={palette.gray?.[900]} />
                        </IconButton>
                    )}
                </Drawer>
            </>
        </DrawerContext.Provider>
    );
}

export default DrawerManager;
