import { fromUnixTime, formatDistanceToNowStrict } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { Typography } from '@mui/material';

import { abbreviateDistance } from 'src/utils/timeUtils';

interface LastPressTimeDisplayProps {
    lastPressTimestamp: number;
}

export default function LastPressTimeDisplay(props: LastPressTimeDisplayProps) {
    const { lastPressTimestamp } = props;
    const [delay, setDelay] = useState<number>();
    const [lastPressDisplay, setLastPressDisplay] = useState();

    const timeAgo = useCallback(() => {
        if (!lastPressTimestamp) {
            return;
        }
        const datetime = fromUnixTime(lastPressTimestamp / 1000);
        const diffToNowInSeconds = (Date.now() - Number(lastPressTimestamp)) / 1000;

        if (diffToNowInSeconds < 60) {
            // recalculate every 10 secs if button press was less than a minute ago
            setDelay(1000);
        } else if (diffToNowInSeconds < 3600) {
            // recalculate every minute if button press was less than an hour ago
            setDelay(60000);
        } else {
            // don't recalculate if button press was MORE than an hour ago
            setDelay(undefined);
        }
        const formattedTime = formatDistanceToNowStrict(datetime);

        setLastPressDisplay(abbreviateDistance(formattedTime));
    }, [lastPressTimestamp]);

    useInterval(() => {
        timeAgo();
    }, delay);

    useEffect(() => {
        timeAgo();
    }, [lastPressTimestamp, timeAgo]);

    return (
        <Typography
            variant="subtitle1"
            sx={{
                color: 'grey.500',
                whiteSpace: 'nowrap',
            }}
        >
            {lastPressDisplay} ago
        </Typography>
    );
}
