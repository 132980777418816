import { sageBaseCareServiceApi as api } from './sageBaseCareServiceApi';

export const addTagTypes = ['residents'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            createLoggedCare: build.mutation<CreateLoggedCareApiResponse, CreateLoggedCareApiArg>({
                query: (queryArg) => ({
                    url: `/loggedCare`,
                    method: 'POST',
                    body: queryArg.createLoggedCareRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
                invalidatesTags: ['residents'],
            }),
            createCareOutcomes: build.mutation<
                CreateCareOutcomesApiResponse,
                CreateCareOutcomesApiArg
            >({
                query: (queryArg) => ({
                    url: `/careOutcomes`,
                    method: 'POST',
                    body: queryArg.createCareOutcomesRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageCareServiceApi };
export type CreateLoggedCareApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type CreateLoggedCareApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createLoggedCareRequest: CreateLoggedCareRequest;
};
export type CreateCareOutcomesApiResponse = /** status 200 OK */ {
    rids?: RidResponse[];
};
export type CreateCareOutcomesApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    createCareOutcomesRequest: CreateCareOutcomesRequest;
};
export type CareProvided =
    | 'EMERGENCY_FALL'
    | 'EMERGENCY_OTHER'
    | 'ADL_TOILETING'
    | 'ADL_ROUTINE_CHECK'
    | 'ADL_ESCORT'
    | 'ADL_DRESSING'
    | 'ADL_TRANSFER'
    | 'ADL_EATING'
    | 'ADL_BATHING'
    | 'ADL_GROOMING'
    | 'REQUEST_MEDTECH'
    | 'REQUEST_OXYGEN'
    | 'REQUEST_REPAIR'
    | 'REQUEST_OTHER'
    | 'HOUSEKEEPING_TRASH'
    | 'HOUSEKEEPING_BED'
    | 'HOUSEKEEPING_LAUNDRY'
    | 'HOUSEKEEPING_BATHROOM'
    | 'HOUSEKEEPING_OTHER'
    | 'NON_EMERGENCY_ACCIDENTAL_PRESS'
    | 'NON_EMERGENCY_QUESTION'
    | 'NON_EMERGENCY_ILLNESS'
    | 'NON_EMERGENCY_FOUND_DEVICE'
    | 'NON_EMERGENCY_NO_CARE_GIVEN';
export type Outcome = 'COMPLETED' | 'NOT_COMPLETED' | 'REFUSED';
export type CareOutcome = {
    careProvided: CareProvided;
    outcome: Outcome;
    /** Rid of associated legacy entity. Will be depreciated after migration. */
    legacyEntityRid?: string;
};
export type CreateLoggedCareRequest = {
    staffMemberRid: string;
    residentRid: string;
    facilityRid: string;
    careOutcomes: CareOutcome[];
    note?: string;
};
export type RidResponse = {
    rid?: string;
};
export type CreatorEntity = 'ALERT' | 'LOGGED_CARE';
export type CreateCareOutcomesRequest = {
    creatorEntity: CreatorEntity;
    /** parent entity that initiated the documentation of the care outcomes */
    creatorEntityRid: string;
    careOutcomes: CareOutcome[];
    /** rid of the staff that documented the care outcomes */
    documentedByRid: string;
    residentRid: string;
    facilityRid: string;
    alertRid?: string;
    plannedCareRid?: string;
};
export const { useCreateLoggedCareMutation, useCreateCareOutcomesMutation } = injectedRtkApi;
