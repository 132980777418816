import { useContext, ReactNode } from 'react';

import { Button, ButtonProps } from '@mui/material';

import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';

interface ConfirmationDrawerProps extends DrawerComponentProps {
    cancelButton?: {
        label?: string;
        buttonProps?: ButtonProps;
        eventTag?: string;
        eventProps?: Record<string, any>;
    };
    confirmButton: {
        label?: string;
        buttonProps?: ButtonProps;
        eventTag?: string;
        eventProps?: Record<string, any>;
    };
    content?: ReactNode;
    propName?: string;
}

function ConfirmationDrawer(props: ConfirmationDrawerProps) {
    const {
        cancelButton,
        confirmButton,
        content,
        onNext,
        onPrevious,
        propName = 'confirmed',
        title,
        ...payload
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const {
        label: confirmButtonLabel = 'Yes',
        buttonProps: confirmButtonProps,
        eventTag: confirmEventTag,
        eventProps: confirmEventProps = {},
    } = confirmButton || {};

    const {
        label: cancelButtonLabel = 'Cancel',
        buttonProps: cancelButtonProps,
        eventTag: cancelEventTag,
        eventProps: cancelEventProps = {},
    } = cancelButton || {};

    const handleClickConfirmAction = () => {
        if (confirmEventTag) {
            trackEvent(confirmEventTag, { ...confirmEventProps, objectType: ObjectTypes.Button });
        }
        onNext({ ...payload, ...{ [propName]: true } });
    };

    const handleClickCancelAction = () => {
        if (cancelEventTag) {
            trackEvent(cancelEventTag, { ...cancelEventProps, objectType: ObjectTypes.Button });
        }
        onPrevious(false);
    };

    return (
        <>
            {title && <DrawerTitle title={title} />}

            <DrawerBody allowShrink>{content}</DrawerBody>

            <DrawerFooter sx={{ gap: 2.5, pb: 4 }}>
                <Button
                    {...confirmButtonProps}
                    color={confirmButtonProps?.color || 'primary'}
                    disableElevation={confirmButtonProps?.disableElevation || true}
                    fullWidth={confirmButtonProps?.fullWidth || true}
                    size={confirmButtonProps?.size || undefined}
                    variant={confirmButtonProps?.variant || 'contained'}
                    onClick={handleClickConfirmAction}
                >
                    {confirmButtonLabel}
                </Button>

                {cancelButton && (
                    <Button
                        {...cancelButtonProps}
                        color={cancelButtonProps?.color || 'primary'}
                        disableElevation={cancelButtonProps?.disableElevation || true}
                        fullWidth={cancelButtonProps?.fullWidth || true}
                        size={cancelButtonProps?.size || undefined}
                        variant={cancelButtonProps?.variant || 'outlined'}
                        onClick={handleClickCancelAction}
                    >
                        {cancelButtonLabel}
                    </Button>
                )}
            </DrawerFooter>
        </>
    );
}

export default ConfirmationDrawer;
