import { useContext, useState } from 'react';

import { Button, Typography } from '@mui/material';

import Person, { PersonProps } from '../fragments/Person';
import RadioGroupOptions from '../fragments/RadioGroupOptions';
import Resident, { ResidentProps } from '../fragments/Resident';
import TeamMember, { TeamMemberProps } from '../fragments/TeamMember';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';

const DrawerName = 'Select Resident Drawer';

function PersonLabel(props: {
    type?: 'resident' | 'staff';
    person: ResidentProps | TeamMemberProps | PersonProps;
}) {
    switch (props.type) {
        case 'resident':
            return <Resident {...(props.person as ResidentProps)} />;
        case 'staff':
            return <TeamMember {...(props.person as TeamMemberProps)} />;
        default:
            return <Person {...(props.person as PersonProps)} />;
    }
}

interface SelectPersonDrawerProps extends DrawerComponentProps {
    keyName?: string;
    people: ResidentProps[] | TeamMemberProps[] | PersonProps[];
    peopleType?: 'resident' | 'staff';
    subTitle?: string;
}

function SelectPersonDrawer(props: SelectPersonDrawerProps) {
    const {
        onNext,
        keyName = 'person',
        people,
        peopleType,
        subTitle,
        title = 'Select Person',
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const [selectedPersonId, setSelectedPersonId] = useState<string>();

    const handleSelectPerson = () => {
        trackEvent('Person Selected', { context: DrawerName, personType: keyName });

        onNext({ [keyName]: selectedPersonId });
    };

    return (
        <>
            <DrawerTitle title={title}>
                {subTitle && (
                    <Typography align="center" variant="body1">
                        {subTitle}
                    </Typography>
                )}
            </DrawerTitle>

            <DrawerBody>
                <RadioGroupOptions
                    radioGroupProps={{ 'aria-label': keyName }}
                    options={people?.map(
                        (person: ResidentProps | TeamMemberProps | PersonProps) => ({
                            value: person.id!,
                            label: <PersonLabel person={person} type={peopleType} />,
                        })
                    )}
                    onSelectOption={setSelectedPersonId}
                    selectedOption={selectedPersonId}
                />
            </DrawerBody>

            <DrawerFooter>
                <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleSelectPerson}
                    disabled={!selectedPersonId}
                >
                    Continue
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectPersonDrawer;
