import { createTheme } from '@mui/material/styles';

import sassVars from './sageTheme.module.scss';

const defaultMuiTheme = createTheme({});

const spacing = parseInt(sassVars.spacing.slice(0, -2), 10);

// This file supplies variables to Material UI and some areas of the app that
// still use JSS/Styled Components. The values are imported from sageTheme.module.scss.

// Theme is first defined using the base, then customizations added in order to use
// the augmentColor utility.
// See https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
let theme = createTheme({});

theme = createTheme({
    colorSchemes: {
        light: true,
        dark: false,
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { size: 'extraSmall' },
                    style: {
                        padding: `${spacing / 2}px ${spacing * 1.5}px`,
                        fontSize: '0.6875rem',
                        minWidth: spacing * 6,
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: spacing * 1.5,
                    fontWeight: 'bold',
                    '&.MuiButton-fullWidth .MuiButton-endIcon': {
                        position: 'absolute',
                        right: spacing * 2.5,
                    },
                    '&.MuiButton-fullWidth .MuiButton-startIcon': {
                        position: 'absolute',
                        left: spacing * 2.5,
                    },
                },
                sizeExtraSmall: {
                    padding: `${spacing / 2}px ${spacing * 1.5}px`,
                },
                sizeSmall: {
                    padding: `${spacing}px ${spacing * 2}px`,
                },
                sizeMedium: {
                    padding: `${spacing * 2}px`,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: sassVars['grey-200'],
                        color: sassVars['grey-900'],
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    paddingTop: spacing * 0.5,
                    paddingBottom: spacing * 0.5,
                    paddingLeft: spacing * 2.5,
                    paddingRight: spacing * 2.5,
                },
                filledInfo: {
                    backgroundColor: sassVars['grey-200'],
                    color: sassVars['grey-900'],
                },
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: spacing * 2,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                },
                sizeMedium: {
                    height: '1.5rem',
                },
                labelMedium: {
                    fontSize: '0.6875rem',
                    fontWeight: 'bold',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                },
                iconMedium: {
                    fontSize: '0.6875rem',
                    paddingLeft: '0.25rem',
                    paddingRight: '0.5rem',
                },
                sizeLarge: {
                    height: '1.75rem',
                },
                labelLarge: {
                    fontSize: '0.8125rem',
                    fontWeight: 'normal',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                },
                iconLarge: {
                    fontSize: '0.8125rem',
                    paddingLeft: '0.25rem',
                    paddingRight: '0.5rem',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: spacing * 3 + 2, // Allocate 1px for the border
                    width: spacing * 5 + 2, // Allocate 1px for the border
                    padding: 0,
                    margin: spacing,
                    overflow: 'visible',
                },
                thumb: {
                    width: spacing * 3,
                    height: spacing * 3,
                },
                track: {
                    borderRadius: spacing * 1.5 + 1, // Allocate 1px for the border
                    backgroundColor: sassVars['grey-300'],
                    opacity: 1,
                    transition: theme.transitions.create(['background-color', 'border']),
                },
                switchBase: {
                    padding: 1,
                    '&.Mui-checked': {
                        transform: `translateX(${spacing * 2}px)`,
                        color: sassVars.white,
                        '& + .MuiSwitch-track': {
                            backgroundColor: sassVars.primary,
                            opacity: 1,
                            border: 'none',
                        },
                    },
                    '& thumb': {
                        color: sassVars.primary,
                        border: `${spacing} solid white`,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontWeight: 'regular',
                    backgroundColor: sassVars['purple-100'],
                    color: sassVars['grey-900'],
                    '&.xsmall': { width: spacing * 3, height: spacing * 3, fontSize: '0.625rem' },
                    '&.small': { width: spacing * 4, height: spacing * 4, fontSize: '0.75rem' },
                    '&.medium': { width: spacing * 6, height: spacing * 6, fontSize: '1.25rem' },
                    '&.large': { width: spacing * 8, height: spacing * 8, fontSize: '1.5rem' },
                    '&.xlarge': { width: spacing * 10, height: spacing * 10, fontSize: '1.5rem' },
                },
                rounded: {
                    '&.xsmall': { borderRadius: spacing * 0.75 },
                    '&.small': { borderRadius: spacing * 0.75 },
                    '&.medium': { borderRadius: spacing * 1.5 },
                    '&.large': { borderRadius: spacing * 1.75 },
                    '&.xlarge': { borderRadius: spacing * 1.75 },
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                root: {
                    '&.xsmall': {
                        '.MuiAvatarGroup-avatar': {
                            width: spacing * 3,
                            height: spacing * 3,
                            fontSize: '0.625rem',
                            fontWeight: 600,
                        },
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    '.MuiBadge-badge': {
                        borderStyle: 'solid',
                        borderColor: sassVars['grey-100'],
                        borderWidth: 1,

                        '&.MuiBadge-dot': {
                            width: spacing * 1.5,
                            height: spacing * 1.5,
                        },
                    },

                    '&.medium': {
                        '.MuiBadge-badge': {
                            borderWidth: '2px',
                            borderRadius: '50%',
                            width: spacing * 2, // 16px
                            height: spacing * 2.5, // 20px

                            '&.MuiBadge-dot': {
                                width: spacing * 1.75, // 14px
                                height: spacing * 1.75, // 14px
                            },
                        },
                    },
                    '&.large': {
                        '.MuiBadge-badge': {
                            borderWidth: '2px',
                            borderRadius: '50%',
                            width: spacing * 3,
                            height: spacing * 3,

                            '&.MuiBadge-dot': {
                                width: spacing * 2,
                                height: spacing * 2,
                            },
                        },
                    },
                    '&.xlarge': {
                        '.MuiBadge-badge': {
                            borderWidth: '2px',
                            borderRadius: '50%',
                            width: spacing * 4,
                            height: spacing * 4,

                            '&.MuiBadge-dot': {
                                width: spacing * 2.25,
                                height: spacing * 2.25,
                            },
                        },
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                rounded: {
                    '&.xsmall': { borderRadius: spacing / 2 },
                    '&.small': { borderRadius: spacing * 0.75 },
                    '&.medium': { borderRadius: spacing * 1.5 },
                    '&.large': { borderRadius: spacing * 1.75 },
                    '&.xlarge': { borderRadius: spacing * 1.75 },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: spacing * 1.5,
                    boxShadow: sassVars.sageShadow,
                },
            },
        },
        MuiCheckbox: {
            variants: [
                {
                    props: { size: 'large' },
                    style: {
                        height: spacing * 3,
                        width: spacing * 3,
                    },
                },
            ],
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: spacing * 2.5,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    color: sassVars['grey-900'],
                    textAlign: 'center',
                    paddingTop: spacing * 2.5,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: 1.5,
                    textAlign: 'center',
                    paddingInline: spacing * 2.5,
                    paddingBlockEnd: spacing * 4,
                },
            },
        },
    },
    palette: {
        contrastThreshold: 4.5,
        primary: {
            main: sassVars.primary,
            light: sassVars.primaryLight,
            contrastText: sassVars.white,
        },
        primaryInverse: theme.palette.augmentColor({
            color: { main: sassVars.primaryLight, contrastText: sassVars.primary },
            name: 'primaryInverse',
        }),
        error: {
            main: sassVars.sageRed,
            light: sassVars['red-100'],
            contrastText: sassVars.white,
        },
        errorInverse: theme.palette.augmentColor({
            color: { main: sassVars['red-100'], contrastText: sassVars.sageRed },
            name: 'errorInverse',
        }),
        success: {
            main: sassVars.sageGreen,
            contrastText: sassVars.white,
        },
        warning: {
            main: sassVars.sageOrange,
            contrastText: sassVars.white,
        },
        info: {
            main: sassVars['purple-400'],
            contrastText: sassVars.white,
        },
        black: {
            main: sassVars.sageBlack,
            contrastText: sassVars.white,
        },
        white: {
            main: sassVars.sageWhite,
            contrastText: sassVars.black,
        },

        // Setting "grey" and "gray" since English is hard
        grey: {
            100: sassVars['grey-100'],
            200: sassVars['grey-200'],
            300: sassVars['grey-300'],
            400: undefined,
            500: sassVars['grey-500'],
            600: undefined,
            700: sassVars['grey-700'],
            800: undefined,
            900: sassVars['grey-900'],
            950: undefined,
        },
        gray: {
            100: sassVars['grey-100'],
            200: sassVars['grey-200'],
            300: sassVars['grey-300'],
            400: undefined,
            500: sassVars['grey-500'],
            600: undefined,
            700: sassVars['grey-700'],
            800: undefined,
            900: sassVars['grey-900'],
            950: undefined,
        },
        purple: {
            100: sassVars['purple-100'],
            200: undefined,
            300: undefined,
            400: sassVars['purple-400'],
            500: undefined,
            600: undefined,
            700: sassVars['purple-700'],
            800: undefined,
            900: sassVars['purple-900'],
            950: sassVars['purple-950'],
        },
        green: {
            100: sassVars['green-100'],
            200: undefined,
            300: undefined,
            400: undefined,
            500: sassVars['green-500'],
            600: undefined,
            700: sassVars['green-700'],
            800: undefined,
            900: sassVars['green-900'],
            950: undefined,
        },
        yellow: {
            100: sassVars['yellow-100'],
            200: undefined,
            300: undefined,
            400: sassVars['yellow-400'],
            500: sassVars['yellow-500'],
            600: undefined,
            700: sassVars['yellow-700'],
            800: undefined,
            900: sassVars['yellow-900'],
            950: undefined,
        },
        orange: {
            100: sassVars['orange-100'],
            200: undefined,
            300: undefined,
            400: undefined,
            500: sassVars['orange-500'],
            600: sassVars['orange-600'],
            700: sassVars['orange-700'],
            800: undefined,
            900: sassVars['orange-900'],
            950: undefined,
        },
        red: {
            100: sassVars['red-100'],
            200: undefined,
            300: undefined,
            400: undefined,
            500: sassVars['red-500'],
            600: undefined,
            700: sassVars['red-700'],
            800: undefined,
            900: sassVars['red-900'],
            950: undefined,
        },
    },
    typography: {
        fontFamily: sassVars.sageFont,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        lineHeight: 1.5,
        h1: {
            fontSize: '2rem',
            color: sassVars['grey-900'],
            lineHeight: 1.5,
        },
        h2: {
            fontSize: '1.5rem',
            color: sassVars['grey-900'],
            lineHeight: 1.5,
        },
        h3: {
            fontSize: '1.25rem',
            color: sassVars['grey-900'],
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '1rem',
            color: sassVars['grey-900'],
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '0.875rem',
            color: sassVars['grey-900'],
            lineHeight: 1.5,
        },
        body1: {
            fontSize: '0.875rem',
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.8125rem',
            lineHeight: 1.5,
        },
        subtitle1: {
            fontSize: '0.6875rem',
            lineHeight: 1.5,
        },
        // TODO: Delete once all captions have been turned into subtitle1
        caption: {
            fontSize: '0.6875rem',
            lineHeight: 1.5,
        },
        button: {
            textTransform: 'none',
        },
    },
    spacing,
    shadows: [
        'none',
        sassVars.sageShadow,
        sassVars.sageShadowLarge,
        ...defaultMuiTheme.shadows.slice(3),
    ],
});

export default theme;
