import { format } from 'date-fns';
import { useMemo } from 'react';

import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { OnNext } from '../drawers/DrawerManager';
import UnitCtaButtons from '../units/UnitCtaButtons';
import { CheckInReasons, CheckInStatuses } from 'src/constants/CheckIn';
import useCheckIns from 'src/hooks/useCheckIns';
import { Unit } from 'src/store/sageAdminApi';

interface UnitCheckInCardProps {
    unit: Unit;
    onNext?: OnNext;
}

function UnitCheckInCard(props: UnitCheckInCardProps) {
    const { unit, onNext } = props;

    const { palette } = useTheme();

    const {
        checkIns,
        isDuringDowntime,
        isLoading: isLoadingCheckIns,
    } = useCheckIns({ unitId: unit.id });

    const unitCheckIn = useMemo(
        () => checkIns?.find((checkIn) => checkIn.unit?.id === unit.id),
        [checkIns, unit.id]
    );

    const isCheckedIn = unitCheckIn?.status === CheckInStatuses.Complete;
    const cardTitle = useMemo(() => {
        if (isDuringDowntime) {
            return 'Daily check-in begins at 4:00 am';
        }

        if (!isCheckedIn) {
            return 'Unit not Checked-in';
        }

        switch (unitCheckIn?.checkInReason) {
            case CheckInReasons.ButtonPressed:
                return 'Resident pressed the button';
            case CheckInReasons.CalledResident:
                return 'Called resident';
            case CheckInReasons.SeenInPerson:
                return 'Seen in-person';
            case CheckInReasons.TeamMemberVerified:
                return 'Team member verified';
            case CheckInReasons.VerifiedByIntegration:
                return 'Verified by integration';
            default:
                return 'Unit Checked-in';
        }
    }, [isCheckedIn, isDuringDowntime, unitCheckIn?.checkInReason]);

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                borderRadius: 3,
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'gray.200',
            }}
        >
            <Stack
                sx={{
                    gap: 2,
                }}
            >
                <Stack
                    sx={{
                        gap: 0.5,
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {!isDuringDowntime && !isCheckedIn && (
                            <FontAwesomeIcon icon={faCalendarTimes} color={palette.error.main} />
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            {isLoadingCheckIns ? (
                                <Skeleton animation="wave" variant="rounded" width="120px" />
                            ) : (
                                cardTitle
                            )}
                        </Typography>
                    </Stack>
                    {isCheckedIn && unitCheckIn.checkInTimestamp && (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: 'gray.500',
                            }}
                        >
                            {format(
                                parseInt(unitCheckIn.checkInTimestamp),
                                "MMM d, yyyy 'at' h:mm a"
                            )}
                        </Typography>
                    )}
                </Stack>
                {isLoadingCheckIns ? (
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={56}
                        width="100%"
                        sx={{ borderRadius: 3 }}
                    />
                ) : (
                    <UnitCtaButtons
                        onNext={onNext}
                        unit={{ ...unit, checkIn: unitCheckIn }}
                        checkInButtonProps={{ color: 'primaryInverse' }}
                    />
                )}
            </Stack>
        </Box>
    );
}

export default UnitCheckInCard;
