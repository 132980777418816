function useEnvironment() {
    const env = import.meta.env.VITE_ENV;

    const isLocal = env === 'local';
    const isDev = env === 'dev';
    const isBouncer = env === 'bouncer';
    const isE2E = env === 'e2e';
    const isProd = env === 'production';

    return {
        isLocal,
        isBouncer,
        isDev,
        isE2E,
        isProd,
    };
}

export default useEnvironment;
