import { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import webAuth from './components/auth/AuthConfigManager';
import { DrawerContext } from './components/drawers/DrawerManager';
import SageLoader from './components/fragments/SageLoader';
import AuthTokenCheck from './components/fragments/authTokenCheck';
import { AUTH0_CALLBACK_PATH, LOGIN_SUCCESS_REDIRECT_PATH } from './constants/Auth0';
import LoginPage from './pages/LoginPage';
import { setBearerToken } from './store/authSlice';
import fetchWithRetry from './utils/fetchWithRetry';

import './assets/styles/containerStyles.scss';

const SageApp = lazy(() => fetchWithRetry(() => import('./SageApp'), 'SageApp'));

/**
 * This is the main entry point for the application. It handles both the initial and on-going authentication of users
 */
function App() {
    const { closeDrawer } = useContext(DrawerContext);
    const dispatch = useDispatch();
    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    const { isLoading: isLoadingAuth, isAuthenticated } = useAuth0();

    const [isProcessingLogin, setIsProcessingLogin] = useState<boolean | undefined>(undefined);
    const [hasCheckedClaims, setHasCheckedClaims] = useState(false);

    useEffect(() => {
        if (!isLoadingAuth && !isAuthenticated) {
            closeDrawer();
        }
    }, [closeDrawer, isAuthenticated, isLoadingAuth]);

    /**
     * This effect handles for the redirect back to this application after the user has authenticated
     * with Auth0, either via passwordless or ULP. In both cases, the user will be redirected to /auth_confirmed.
     *
     * - For passwordless, the URL hash contains user's auth details (token, idToken, expiresIn).
     *   We need to parse this hash ourselves.
     * - For ULP, Auth0 will redirect back with a "code" search param, which will be handled by the Auth0Provider.
     *
     * After the user is authenticated via one of the above methods, we can redirect the user to the user shift page.
     */
    useEffect(() => {
        if (pathname === AUTH0_CALLBACK_PATH) {
            setIsProcessingLogin(true);

            if (!isLoadingAuth && !!hash) {
                /**
                 * The `isAuthenticated` property defaults to `false`. The @auth0/auth0-react requires the specified redirect_uri to load completely
                 * before evaluating the user session and updating `isAuthenticated = true`, even if `isLoading === false`.
                 *
                 * See https://github.com/auth0/auth0-react/issues/82#issuecomment-711479502
                 */
                if (!isAuthenticated) {
                    webAuth.parseHash({ hash }, (parseError, authResult) => {
                        if (parseError) {
                            console.warn('Error parsing hash:', hash, parseError);
                        } else if (!authResult || !authResult.accessToken) {
                            console.warn('Unable to parse accessToken from authResult');
                        } else {
                            const { accessToken } = authResult;
                            dispatch(setBearerToken(`Bearer ${accessToken}`));
                            setIsProcessingLogin(false);
                            navigate(LOGIN_SUCCESS_REDIRECT_PATH, {
                                state: { showHeader: false },
                                replace: true,
                            });
                        }
                        setIsProcessingLogin(false);
                    });
                }
            } else if (
                !isLoadingAuth &&
                isAuthenticated &&
                /**
                 * Wait for auth0-react to parse and strip the code
                 * the useLocation search property seems to be stale here, so using the window.location.search instead
                 */
                !window.location.search.includes('code=')
            ) {
                setIsProcessingLogin(false);
                navigate(LOGIN_SUCCESS_REDIRECT_PATH, {
                    state: { showHeader: false },
                    replace: true,
                });
            }
        }
    }, [dispatch, hash, isAuthenticated, isLoadingAuth, navigate, pathname]);

    const isLoading = isLoadingAuth || isProcessingLogin || !hasCheckedClaims;

    return (
        <>
            {isLoading && <SageLoader message="Verifying your information" showReloadLink record />}
            {!isLoading && isAuthenticated && (
                <Suspense fallback={<SageLoader showReloadLink record />}>
                    <SageApp />
                </Suspense>
            )}
            {!isLoading && !isAuthenticated && (
                <Suspense fallback={<SageLoader showReloadLink record />}>
                    <Routes>
                        <Route path="/authenticate" element={<LoginPage />} />

                        <Route path="*" element={<Navigate to="/authenticate" replace />} />
                    </Routes>
                </Suspense>
            )}
            <AuthTokenCheck
                isProcessingLogin={isProcessingLogin}
                setHasCheckedClaims={setHasCheckedClaims}
            />
        </>
    );
}

export default App;
