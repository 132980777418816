export const Drawers = {
    AddTaskNote: 'add-task-note',
    CareConfirmation: 'care-confirmation',
    CompleteInsight: 'complete-insight',
    Confirmation: 'confirmation',
    DoorScheduleDrawer: 'door-schedule-drawer',
    ExploreInsights: 'explore-insights',
    PhoneSubscription: 'phone-subscription',
    ReceiveHighFive: 'receive-high-five',
    ResidentDetails: 'resident-details',
    ResidentPendants: 'resident-pendants',
    ResolveTask: 'resolve-task',
    SearchResident: 'search-resident',
    SelectCareZone: 'select-care-zone',
    SelectDevice: 'select-device',
    SelectEmergencyReason: 'select-emergency-reason',
    SelectEnterprise: 'select-enterprise',
    SelectMultiOutcomeResolution: 'select-multi-outcome-resolution',
    SelectOptions: 'select-options',
    SelectPerson: 'select-person',
    SelectResidentsWithButtons: 'select-residents-with-buttons',
    SelectResolutionType: 'select-resolution-type',
    SelectRoomCheckin: 'select-room-checkin',
    SelectDoor: 'select-door',
    SelectUnit: 'select-unit',
    SelectWard: 'select-ward',
    SelectWasFall: 'select-was-fall',
    SendHighFive: 'send-high-five',
    StaffMemberActionsDrawer: 'staff-member-actions-drawer',
    UnitCheckIn: 'unit-check-in',
    UnitDetail: 'unit-detail',
};

// SupportedDrawer type is generated from all the values in the Drawers object
export type SupportedDrawer = (typeof Drawers)[keyof typeof Drawers];
