export const PhoneSubscriptionStatuses = {
    Subscribed: 'SUBSCRIBED',
    Unsubscribed: 'UNSUBSCRIBED',
};

export const PhoneSmsNumberTypes = {
    Alerts: 'ALERTS',
    Tasks: 'TASKS',
};

export const PhoneSmsNumberTypesLabels = {
    [PhoneSmsNumberTypes.Alerts]: 'Alerts',
    [PhoneSmsNumberTypes.Tasks]: 'Tasks',
};

export type SupportedPhoneSmsNumberType =
    (typeof PhoneSmsNumberTypes)[keyof typeof PhoneSmsNumberTypes];
