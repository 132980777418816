import { fak } from '@awesome.me/kit-da59d5031a/icons';

import Groups from '../constants/Groups';

// Define a type that includes all possible values of Groups
type GroupName = (typeof Groups)[keyof typeof Groups];

export const getGroupIcon = (name: GroupName) => {
    switch (name) {
        case Groups.Activities:
            return fak.faPresentationGraph;
        case Groups.Management:
            return fak.faManagement;
        case Groups.CareStaff:
            return fak.faCarestaff;
        case Groups.Culinary:
            return fak.faCulinary;
        case Groups.Maintenance:
            return fak.faMaintenance;
        case Groups.NurseMedTech:
            return fak.faNurse;
        case Groups.Housekeeping:
            return fak.faHousekeeping;
        case Groups.Transportation:
            return fak.faTransport;
        case Groups.SageSupport:
            return fak.faSage;
        default:
            return fak.Carestaff;
    }
};

// Scroll to top of the page
export const ScrollToTop = () => {
    if (Math.round(window.scrollY) !== 0) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
};

// Scroll to bottom of the page
export const ScrollToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
    });
};

// Scroll to arbitrary value
export const ScrollTo = (value: number) => {
    if (Math.round(window.scrollY) !== value) {
        window.scrollTo({
            top: value,
            left: 0,
        });
    }
};

// When modals or drawers are open, we want to stop the background (body) from scrolling
export const PreventBodyScrolling = (scrollPosition: number) => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-body-scroll');
    if (scrollPosition) {
        body.style.top = `-${scrollPosition}px`;
    }
};

export const AllowBodyScrolling = () => {
    const body = document.getElementsByTagName('body')[0];
    const topValue = Number(body.style.top.replace('px', ''));
    let previousScroll = Math.round(topValue);
    previousScroll = previousScroll < 0 ? previousScroll * -1 : previousScroll;

    body.classList.remove('no-body-scroll');
    body.style.top = '';

    window.scrollTo({
        top: previousScroll,
    });
};
