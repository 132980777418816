import { useMemo } from 'react';

import { BadgeProps, Box, CircularProgress, CircularProgressProps, Skeleton } from '@mui/material';

import { RoutineCheckDurationsInMin } from 'constants/RoutineChecks';
import { PersonProps } from 'src/components/fragments/Person';
import Person from 'src/components/fragments/Person';
import { ImageSizes } from 'src/constants/ImageSizes';
import { Resident } from 'src/store/sageAdminApi';
import { getImageSize } from 'src/utils/iconImageUtils';

type ProgressValue =
    | number
    | {
          current: number;
          max: number;
          warningThreshold?: number;
          errorThreshold?: number;
      };

export interface ResidentAvatarProps extends PersonProps {
    resident: Resident;
    progress?: ProgressValue; // 0-100
    showProgress?: boolean;
}

const SizeToProgressDimensions = {
    xsmall: {
        thickness: 2,
        diameter: 28,
    },
    small: {
        thickness: 2,
        diameter: 38,
    },
    medium: {
        thickness: 2,
        diameter: 56,
    },
    large: {
        thickness: 2,
        diameter: 76,
    },
    xlarge: {
        thickness: 2,
        diameter: 96,
    },
};

function ResidentAvatar(props: ResidentAvatarProps) {
    const { progress = 0, showProgress = false, resident, ...personProps } = props;

    const imagePx = parseInt(
        getImageSize(personProps?.size || ImageSizes.Medium).replace('px', '')
    );

    const { thickness, diameter } =
        SizeToProgressDimensions[personProps?.size || ImageSizes.Medium];

    const {
        value,
        progressColor,
        badgeColor,
    }: {
        value: number;
        progressColor?: CircularProgressProps['color'];
        badgeColor?: BadgeProps['color'];
    } = useMemo(() => {
        // If progressValue is a number, use that as the progress value
        if (typeof progress === 'number') {
            return { value: progress, color: 'info' };
        } else if (typeof progress === 'object') {
            // If progressValue is an object, calculate the progress value
            const {
                current,
                max,
                warningThreshold = RoutineCheckDurationsInMin.WARNING_THRESHOLD,
                errorThreshold = 0,
            } = progress;

            const diff = max - current;
            const value = Math.min(Math.round((current / max) * 100), 100);

            if (diff <= errorThreshold) {
                return { value, progressColor: 'error', badgeColor: 'error' };
            } else if (diff <= warningThreshold) {
                return { value, progressColor: 'warning', badgeColor: 'warning' };
            } else {
                return { value, progressColor: 'info' };
            }
        } else {
            return { value: 0, progressColor: 'info' };
        }
    }, [progress]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: imagePx,
                height: imagePx,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress
                variant="determinate"
                value={100}
                size={diameter}
                thickness={thickness}
                sx={{
                    position: 'absolute',
                    visibility: showProgress ? 'visible' : 'hidden',
                    color: 'grey.200',
                }}
            />
            <CircularProgress
                variant="determinate"
                value={value}
                color={progressColor}
                size={diameter}
                thickness={thickness}
                sx={{ position: 'absolute', visibility: showProgress ? 'visible' : 'hidden' }}
            />
            {resident ? (
                <Person
                    {...resident}
                    {...personProps}
                    pinnedBadgeColor={showProgress ? badgeColor : undefined}
                />
            ) : (
                <Skeleton variant="circular" height={imagePx} width={imagePx} />
            )}
        </Box>
    );
}
export default ResidentAvatar;
