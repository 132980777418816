import { AnyAction, createSlice, Dispatch, ThunkAction, UnknownAction } from '@reduxjs/toolkit';

import { PreventBodyScrolling, AllowBodyScrolling } from '../utils/uiUtils';
import { RootState } from './index';

type GlobalUIState = {
    backButtonOverride: Record<string, string>;
    lastViewedRecognitionCreateTime: number;
    pageScroll: Record<string, number>;
    preventBodyScrolling: boolean;
    pinnedResidentIds: Record<string, boolean> | undefined;
};

export const initialState: GlobalUIState = {
    backButtonOverride: {},
    lastViewedRecognitionCreateTime: 0,
    pageScroll: {},
    preventBodyScrolling: false,
    pinnedResidentIds: undefined,
};

export const globalUISlice = createSlice({
    name: 'globalUI',
    initialState,
    reducers: {
        setPreventBodyScrolling: (state, { payload }) => {
            state.preventBodyScrolling = payload;
        },
        setPageScroll: (state, { payload }) => {
            const { page, scroll } = payload || {};
            if (page) {
                state.pageScroll[page] = scroll;
            }
        },
        setBackButtonOverride: (state, { payload }) => {
            const { page, override } = payload || {};
            if (page) {
                state.backButtonOverride[page] = override;
            }
        },
        setLastViewedRecognitionCreateTime: (state, { payload }) => {
            state.lastViewedRecognitionCreateTime = payload;
        },
        setPinnedResidentIds: (state, { payload }) => {
            state.pinnedResidentIds = payload;
        },
    },
    selectors: {
        selectBackButtonOverrideMap: (sliceState: GlobalUIState) => sliceState.backButtonOverride,
        selectIsPreventingBodyScrolling: (sliceState: GlobalUIState) =>
            sliceState.preventBodyScrolling,
        selectLastViewedRecognitionCreateTime: (sliceState: GlobalUIState) =>
            sliceState.lastViewedRecognitionCreateTime,
        selectPageScrollMap: (sliceState: GlobalUIState) => sliceState.pageScroll,
        selectPinnedResidentIds: (sliceState: GlobalUIState) => sliceState.pinnedResidentIds,
    },
});

// Action creators are generated for each case reducer function
export const {
    setBackButtonOverride,
    setLastViewedRecognitionCreateTime,
    setPageScroll,
    setPreventBodyScrolling,
    setPinnedResidentIds,
} = globalUISlice.actions;

export const {
    selectBackButtonOverrideMap,
    selectIsPreventingBodyScrolling,
    selectLastViewedRecognitionCreateTime,
    selectPageScrollMap,
    selectPinnedResidentIds,
} = globalUISlice.selectors;

// Thunks
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>;

export function ShouldPreventBodyScrolling(
    booleanValue: boolean,
    scrollPosition: number = 0
): AppThunk {
    return (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
        const stateBefore = getState();
        if (stateBefore.globalUI.preventBodyScrolling !== booleanValue) {
            if (booleanValue) {
                PreventBodyScrolling(scrollPosition);
            } else {
                AllowBodyScrolling();
            }
            dispatch(setPreventBodyScrolling(booleanValue));
        }
    };
}

export default globalUISlice.reducer;
