import { useContext } from 'react';
import React from 'react';

import { Typography, Button, Stack, AvatarGroup, Box } from '@mui/material';

import { AnalyticsContext, ObjectTypes } from '../../AnalyticsContext';
import { ImageSizes } from '../../constants/ImageSizes';
import Person from '../fragments/Person';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import HighFiveAdornment from 'src/assets/images/insights-icons/high-five-adornment.svg';
import HighFiveIcon from 'src/assets/images/insights-icons/high-five.png';
import { pluralize } from 'src/utils/intlUtils';

import './styles/HighFive.styles.scss';

interface ReceiveHighFiveDrawerProps extends DrawerComponentProps {
    unSeenRecognitions: Array<any>;
    recognitionSenders: Array<any>;
}

function ReceiveHighFiveDrawer(props: ReceiveHighFiveDrawerProps) {
    const { onClose, onNext, unSeenRecognitions = [], recognitionSenders = [] } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const recognitionsCount = unSeenRecognitions?.length;

    const handleGoBack = () => {
        trackEvent('Go Back Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Go Back',
            context: 'Receive High Five Drawer',
        });
        onClose();
    };

    return (
        <>
            <DrawerTitle sx={{ gap: 2, direction: 'row', justifyContent: 'space-between' }}>
                <Stack
                    sx={{
                        gap: 2,
                        alignItems: 'flex-start',
                    }}
                >
                    <AvatarGroup max={4}>
                        {recognitionSenders.length > 0 &&
                            recognitionSenders.map((sender) => (
                                <Person
                                    key={sender.id}
                                    id={sender.id}
                                    firstName={sender.firstName}
                                    lastName={sender.lastName}
                                    variant="circular"
                                    size={ImageSizes.Small}
                                />
                            ))}
                    </AvatarGroup>
                    <Stack
                        sx={{
                            gap: 1,
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Congratulations!
                        </Typography>
                        <Typography variant="body2">
                            You&apos;ve received{' '}
                            {pluralize(
                                recognitionsCount,
                                `a high five from a colleague.`,
                                `${recognitionsCount} high fives from colleagues.`,
                                false
                            )}
                        </Typography>
                    </Stack>
                </Stack>
                <Box className="high-five__container">
                    <img src={HighFiveAdornment} alt="" className="high-five__burst" />
                    <img src={HighFiveIcon} alt="" className="high-five-hand" />
                </Box>
            </DrawerTitle>
            <DrawerFooter sx={{ gap: 2.5 }}>
                <Button
                    className="ctaButton"
                    fullWidth
                    color="primary"
                    size="large"
                    variant="contained"
                    disableElevation
                    onClick={onNext}
                >
                    View Recognitions
                </Button>

                <Button
                    className="ctaButton"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={handleGoBack}
                    size="large"
                >
                    Later
                </Button>
            </DrawerFooter>
        </>
    );
}

export default ReceiveHighFiveDrawer;
