import { FunctionComponent, SVGProps } from 'react';

import sortBy from 'lodash/sortBy';
import BathingIcon from 'src/assets/images/alert-resolution-icons/adl/bathing.svg?react';
import DressingIcon from 'src/assets/images/alert-resolution-icons/adl/dressing.svg?react';
import EatingIcon from 'src/assets/images/alert-resolution-icons/adl/eating-feeding.svg?react';
import EscortIcon from 'src/assets/images/alert-resolution-icons/adl/escort.svg?react';
import GroomingIcon from 'src/assets/images/alert-resolution-icons/adl/grooming.svg?react';
import ToiletingIcon from 'src/assets/images/alert-resolution-icons/adl/toileting.svg?react';
import TransferIcon from 'src/assets/images/alert-resolution-icons/adl/transfer.svg?react';
import CleanedBathroomIcon from 'src/assets/images/alert-resolution-icons/cleaning/cleaned-bathroom.svg?react';
import EmptiedTrashIcon from 'src/assets/images/alert-resolution-icons/cleaning/emptied-trash.svg?react';
import LaundryIcon from 'src/assets/images/alert-resolution-icons/cleaning/laundry.svg?react';
import MadeBedIcon from 'src/assets/images/alert-resolution-icons/cleaning/made-bed.svg?react';
import FallIcon from 'src/assets/images/alert-resolution-icons/emergency/fall.svg?react';
import OtherEmergencyIcon from 'src/assets/images/alert-resolution-icons/emergency/other-emergency.svg?react';
import AccidentallyPressedIcon from 'src/assets/images/alert-resolution-icons/help-needed/accidentally-pressed.svg?react';
import NotFeelingWellIcon from 'src/assets/images/alert-resolution-icons/help-needed/not-feeling-well.svg?react';
import QuestionIcon from 'src/assets/images/alert-resolution-icons/help-needed/question.svg?react';
import OtherIcon from 'src/assets/images/alert-resolution-icons/other-icon.svg?react';
import MedtechIcon from 'src/assets/images/alert-resolution-icons/request/medtech.svg?react';
import OxygenIcon from 'src/assets/images/alert-resolution-icons/request/oxygen.svg?react';
import RepairIcon from 'src/assets/images/alert-resolution-icons/request/repair.svg?react';
import RoutineCheckIcon from 'src/assets/images/alert-resolution-icons/routine-check.svg?react';
import { CareProvided } from 'src/store/sageCareServiceApi';

export interface ResolutionTypeCategory {
    id: string;
    displayName: string;
    sortOrder: number;
    subTypes?: ResolutionType[];
}
export interface ResolutionType {
    icon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }> | string;
    id: CareProvided;
    displayName: string;
    sortOrder: number;
    category?: ResolutionTypeCategory;
}

export const ResolutionTypeCategories: Record<string, ResolutionTypeCategory> = {
    Emergency: {
        id: 'EMERGENCY',
        displayName: 'Emergency',
        sortOrder: 1,
    },
    ADLHelp: {
        id: 'NON_EMERGENCY_ADL_HELP',
        displayName: 'ADL Help',
        sortOrder: 2,
    },
    Request: {
        id: 'NON_EMERGENCY_REQUEST',
        displayName: 'Request',
        sortOrder: 3,
    },
    Cleaning: {
        id: 'NON_EMERGENCY_HOUSEKEEPING',
        displayName: 'Cleaning',
        sortOrder: 4,
    },
    Others: {
        id: 'NON_EMERGENCY_OTHER',
        displayName: 'Others',
        sortOrder: 5,
    },
};

export const ResolutionTypes: Record<string, ResolutionType> = {
    Fall: {
        icon: FallIcon,
        id: 'EMERGENCY_FALL',
        displayName: 'Fall',
        sortOrder: 1,
        category: ResolutionTypeCategories.Emergency,
    },
    OtherEmergency: {
        icon: OtherEmergencyIcon,
        id: 'EMERGENCY_OTHER',
        displayName: 'Other Emergency',
        sortOrder: 2,
        category: ResolutionTypeCategories.Emergency,
    },
    Toileting: {
        icon: ToiletingIcon,
        id: 'ADL_TOILETING',
        displayName: 'Toileting',
        sortOrder: 3,
        category: ResolutionTypeCategories.ADLHelp,
    },
    RoutineCheck: {
        icon: RoutineCheckIcon,
        id: 'ADL_ROUTINE_CHECK',
        displayName: 'Routine Check',
        sortOrder: 4,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Escort: {
        icon: EscortIcon,
        id: 'ADL_ESCORT',
        displayName: 'Escort',
        sortOrder: 5,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Dressing: {
        icon: DressingIcon,
        id: 'ADL_DRESSING',
        displayName: 'Dressing',
        sortOrder: 6,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Transfer: {
        icon: TransferIcon,
        id: 'ADL_TRANSFER',
        displayName: 'Transfer',
        sortOrder: 7,
        category: ResolutionTypeCategories.ADLHelp,
    },
    EatingFeeding: {
        icon: EatingIcon,
        id: 'ADL_EATING',
        displayName: 'Eating / Feeding',
        sortOrder: 8,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Bathing: {
        icon: BathingIcon,
        id: 'ADL_BATHING',
        displayName: 'Bathing',
        sortOrder: 9,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Grooming: {
        icon: GroomingIcon,
        id: 'ADL_GROOMING',
        displayName: 'Grooming',
        sortOrder: 10,
        category: ResolutionTypeCategories.ADLHelp,
    },
    Medtech: {
        icon: MedtechIcon,
        id: 'REQUEST_MEDTECH',
        displayName: 'Medtech',
        sortOrder: 11,
        category: ResolutionTypeCategories.Request,
    },
    Oxygen: {
        icon: OxygenIcon,
        id: 'REQUEST_OXYGEN',
        displayName: 'Oxygen',
        sortOrder: 12,
        category: ResolutionTypeCategories.Request,
    },
    Repair: {
        icon: RepairIcon,
        id: 'REQUEST_REPAIR',
        displayName: 'Repair',
        sortOrder: 13,
        category: ResolutionTypeCategories.Request,
    },
    OtherRequest: {
        icon: OtherIcon,
        id: 'REQUEST_OTHER',
        displayName: 'Other Request',
        sortOrder: 14,
        category: ResolutionTypeCategories.Request,
    },
    EmptiedTrash: {
        icon: EmptiedTrashIcon,
        id: 'HOUSEKEEPING_TRASH',
        displayName: 'Emptied Trash',
        sortOrder: 15,
        category: ResolutionTypeCategories.Cleaning,
    },
    MadeBed: {
        icon: MadeBedIcon,
        id: 'HOUSEKEEPING_BED',
        displayName: 'Made Bed',
        sortOrder: 16,
        category: ResolutionTypeCategories.Cleaning,
    },
    Laundry: {
        icon: LaundryIcon,
        id: 'HOUSEKEEPING_LAUNDRY',
        displayName: 'Laundry',
        sortOrder: 17,
        category: ResolutionTypeCategories.Cleaning,
    },
    CleanedBathroom: {
        icon: CleanedBathroomIcon,
        id: 'HOUSEKEEPING_BATHROOM',
        displayName: 'Cleaned Bathroom',
        sortOrder: 18,
        category: ResolutionTypeCategories.Cleaning,
    },
    OtherCleaning: {
        icon: OtherIcon,
        id: 'HOUSEKEEPING_OTHER',
        displayName: 'Other Cleaning',
        sortOrder: 19,
        category: ResolutionTypeCategories.Cleaning,
    },
    AccidentallyPressed: {
        icon: AccidentallyPressedIcon,
        id: 'NON_EMERGENCY_ACCIDENTAL_PRESS',
        displayName: 'Accidentally Pressed',
        sortOrder: 20,
        category: ResolutionTypeCategories.Others,
    },
    Question: {
        icon: QuestionIcon,
        id: 'NON_EMERGENCY_QUESTION',
        displayName: 'Question',
        sortOrder: 21,
        category: ResolutionTypeCategories.Others,
    },
    NotFeelingWell: {
        icon: NotFeelingWellIcon,
        id: 'NON_EMERGENCY_ILLNESS',
        displayName: 'Not Feeling Well',
        sortOrder: 22,
        category: ResolutionTypeCategories.Others,
    },
    FoundDevice: {
        id: 'NON_EMERGENCY_FOUND_DEVICE',
        displayName: 'Test Alert',
        sortOrder: 23,
    },
    // This is not explicitly shown to the user, but is used as the resolution type for "false alarm" fall alerts.
    NoCareGiven: {
        id: 'NON_EMERGENCY_NO_CARE_GIVEN',
        displayName: 'No Care Given',
        sortOrder: 24,
    },
};

export const ResolutionCategoriesSorted = (() => {
    const groupedByCategories = Object.values(ResolutionTypes).reduce(
        (categories, resolutionType) => {
            const category = resolutionType.category;
            if (category) {
                // Add category to map if it doesn't exist
                if (!categories[category.id]) {
                    // eslint-disable-next-line no-param-reassign
                    categories[category.id] = {
                        ...category,
                        subTypes: [],
                    };
                }
                categories[category.id].subTypes!.push(resolutionType);
            }
            return categories;
        },
        {} as Record<string, ResolutionTypeCategory>
    );

    // Return sorted array of categories with sorted subtypes
    return sortBy(
        Object.values(groupedByCategories).map((category) => ({
            ...category,
            subTypes: sortBy(category.subTypes, 'sortOrder'),
        })),
        'sortOrder'
    );
})();

export const IndependentLivingResolutions = [
    {
        ...ResolutionTypeCategories.Emergency,
        subTypes: [ResolutionTypes.Fall, ResolutionTypes.OtherEmergency],
    },
    {
        ...ResolutionTypeCategories.Others,
        subTypes: [
            ResolutionTypes.AccidentallyPressed,
            ResolutionTypes.NotFeelingWell,
            ResolutionTypes.OtherRequest,
        ],
    },
];

export const IdToResolutionTypeMap = new Map(
    Object.values(ResolutionTypes).map((type) => [type.id, type])
);

export const EmergencyResolutionDisplayValues = [
    ResolutionTypes.Fall.displayName,
    ResolutionTypes.OtherEmergency.displayName,
];
