import React from 'react';

import { datadogRum } from '@datadog/browser-rum';

export function getItemFromStorage(key: string, defaultValue: any): any {
    let value;

    // First, attempt to get from sessionStorage
    if (sessionStorage?.getItem) {
        const sessionStorageValue = sessionStorage.getItem(key);
        if (sessionStorageValue !== undefined) {
            value = sessionStorageValue;
        }
    }

    // If value is undefined, try to get from localStorage
    if (value === undefined && localStorage?.getItem) {
        const localStorageValue = localStorage.getItem(key);
        if (localStorageValue !== undefined) {
            value = localStorageValue;
        }
    }

    // If value is still undefined, return the default value
    if (value === undefined) {
        value = defaultValue;
    }

    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
}

export function setItemInStorage(key: string, value: any): void {
    const valueToStore = typeof value === 'string' ? value : JSON.stringify(value);

    try {
        // First, attempt to set in sessionStorage
        sessionStorage?.setItem(key, valueToStore);
    } catch (error) {
        try {
            // If failed to set in sessionStorage, set in localStorage
            localStorage?.setItem(key, valueToStore);
        } catch (error) {
            if (error instanceof DOMException) {
                if (
                    error.name === 'QuotaExceededError' ||
                    error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
                ) {
                    datadogRum.addError(error, { message: 'Storage quota exceeded' });
                } else {
                    datadogRum.addError(error, { message: 'Error setting value in storage' });
                }
            }

            // If failed to set in localStorage, throw the error
            throw error;
        }
    }
}

/**
 * This utility function is meant to be used with React.lazy() to retry
 * fetching a component by refreshing the page when it encounters an error.
 */
export function fetchWithRetry(
    componentToImport: () => Promise<{ default: React.ComponentType<any> }>,
    componentName: string | undefined
): Promise<{ default: React.ComponentType<any> }> {
    return new Promise((resolve, reject) => {
        // Check if window has been refreshed
        const storageKey = !!componentName
            ? `has-retried-refresh-${componentName}`
            : 'has-retried-refresh';

        const hasRefreshed = getItemFromStorage(storageKey, false);

        // Try to import the component
        componentToImport()
            .then((component) => {
                sessionStorage?.setItem(storageKey, 'false'); // Reset state on success
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // Set a time-based search param to force a refresh
                    const timeSearchParam = `?t=${Date.now()}`;
                    try {
                        setItemInStorage(storageKey, true); // Set state to true to prevent infinite loop

                        // Redirect to the same page with the time search param
                        return window.location.assign(`${window.location.href}${timeSearchParam}`);
                    } catch (error) {
                        // If unable to set state in storage, redirect to home page, which will force a refresh
                        return window.location.assign(`/?t=${Date.now()}`);
                    }
                }

                datadogRum.addError(error, {
                    message: 'Error fetching component after reloading page',
                });
                reject(error); // Default error behavior
            });
    });
}

export default fetchWithRetry;
