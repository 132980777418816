import { Box, Stack, Typography } from '@mui/material';

import Person, { PersonProps } from './Person';
import ContractStaffIndicator from 'src/assets/images/indicator-icons/contract-staff-indicator.svg?react';

export interface TeamMemberProps extends PersonProps {
    isAgencyStaff?: boolean;
    jobTitle?: string;
    children?: React.ReactNode;
}

function TeamMember(props: TeamMemberProps) {
    const { firstName, lastName, isAgencyStaff = false, jobTitle = '', children } = props;

    return (
        <Person {...props}>
            <Typography
                variant="body1"
                component="h4"
                sx={{
                    fontWeight: 'bold',
                    color: 'grey.900',
                }}
            >
                {firstName} {lastName}
            </Typography>
            {(isAgencyStaff || jobTitle) && (
                <Stack
                    direction="row"
                    sx={{
                        gap: 0.5,
                    }}
                >
                    {isAgencyStaff && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ContractStaffIndicator />
                        </Box>
                    )}
                    {jobTitle && (
                        <Typography
                            variant="subtitle1"
                            component="p"
                            sx={{
                                color: 'grey.900',
                            }}
                        >
                            {jobTitle}
                        </Typography>
                    )}
                </Stack>
            )}
            {children}
        </Person>
    );
}

export default TeamMember;
