export const TaskStatuses = {
    OpenUnclaimed: {
        id: 'OPEN_UNCLAIMED',
        displayName: 'Open - unclaimed',
    },
    OpenClaimed: {
        id: 'OPEN_CLAIMED',
        displayName: 'Open - claimed',
    },
    OpenConfirming: {
        id: 'OPEN_CONFIRMING',
        displayName: 'Open - confirming',
    },
    OpenConfirmed: {
        id: 'OPEN_CONFIRMED',
        displayName: 'Open - confirmed',
    },
    ClosedResolved: {
        id: 'CLOSED_RESOLVED',
        displayName: 'Closed - resolved',
    },
    ClosedCancelled: {
        id: 'CLOSED_CANCELED',
        displayName: 'Closed - canceled',
    },
    ClosedUnresolved: {
        id: 'CLOSED_UNRESOLVED',
        displayName: 'Closed - unresolved',
    },
};

export const OpenTaskStatuses = [
    TaskStatuses.OpenUnclaimed.id,
    TaskStatuses.OpenClaimed.id,
    TaskStatuses.OpenConfirming.id,
    TaskStatuses.OpenConfirmed.id,
];

export const ClosedTaskStatuses = [
    TaskStatuses.ClosedResolved.id,
    TaskStatuses.ClosedCancelled.id,
    TaskStatuses.ClosedUnresolved.id,
];
