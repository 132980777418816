import { Box, Stack, Typography } from '@mui/material';
import { BoxTypeMap } from '@mui/system';

import Person from '../fragments/Person';
import { ImageSizes } from 'src/constants/ImageSizes';
import { CompanyPhone, StaffMember } from 'src/store/sageAdminApi';
import { formatPhoneNumber } from 'src/utils/formUtils';

interface CompanyPhoneDetailsProps {
    containerProps?: BoxTypeMap['props'];
    companyPhone: CompanyPhone;
    staffMember?: StaffMember;
    displayNameColor?: string;
    hideAvatar?: boolean;
}

function CompanyPhoneDetails(props: CompanyPhoneDetailsProps) {
    const {
        containerProps,
        companyPhone,
        displayNameColor = 'primary.main',
        staffMember,
        hideAvatar = false,
    } = props;

    const companyPhoneNumber =
        (companyPhone?.phoneNumber && formatPhoneNumber(companyPhone?.phoneNumber)) || '';

    return (
        <Box {...containerProps}>
            <Typography
                variant="body1"
                sx={{
                    color: displayNameColor,
                    fontWeight: 'bold',
                }}
            >
                {companyPhone.displayName} {companyPhoneNumber && `(${companyPhoneNumber})`}
            </Typography>
            {staffMember && (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                    }}
                >
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{
                            color: 'grey.700',
                        }}
                    >
                        Currently used by{' '}
                        <b>
                            {staffMember.firstName}{' '}
                            {staffMember.lastName ? `${staffMember?.lastName[0]}.` : ''}
                        </b>
                    </Typography>
                    {!hideAvatar && (
                        <Person
                            id={staffMember.id}
                            firstName={staffMember.firstName}
                            lastName={staffMember.lastName}
                            size={ImageSizes.XSmall}
                        />
                    )}
                </Stack>
            )}
        </Box>
    );
}

export default CompanyPhoneDetails;
