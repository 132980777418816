import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, TextField } from '@mui/material';

import { sageBaseInsightsEngineApi } from '../../store/sageBaseInsightsEngineApi';
import { useExecuteActionMutation, Workflow } from '../../store/sageInsightsEngineApi';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import { ActionTypes } from 'src/constants/InsightTypes';
import { snackActions } from 'src/utils/snackBarTools';

import '../../assets/styles/buttonStyles.scss';

const trackingContext = 'Complete Insight Drawer';

interface CompleteInsightDrawerProps extends DrawerComponentProps {
    title: string;
    workflow: Workflow;
    value: string;
    actionType: string;
}

function CompleteInsightDrawer(props: CompleteInsightDrawerProps) {
    const { onPrevious, onNext, onClose, workflow, title = 'Add Notes', value, actionType } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const dispatch = useDispatch();

    const [executeAction, { isLoading: executingAction }] = useExecuteActionMutation();

    const shouldDisableActions = executingAction;

    const [notes, setNotes] = useState('');

    const handleGoBack = () => {
        trackEvent('Go Back Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Go Back',
            context: trackingContext,
        });
        onPrevious();
    };

    const handleSuccess = () => {
        snackActions.success(
            actionType === ActionTypes.Dismiss ? 'Feedback Sent' : 'Insight Completed'
        );
        onNext();
    };

    const completeInsight = async () => {
        try {
            await executeAction({
                workflowRid: workflow?.rid!,
                executeActionRequest: {
                    type: actionType,
                    details: value,
                    note: notes,
                },
            }).unwrap();

            handleSuccess();
        } catch (error: any) {
            datadogRum.addError(error, {
                message: 'Error completing insight',
                workflowRid: workflow?.rid,
                actionType: actionType,
            });

            const errorMessage =
                error.data?.code === 409
                    ? 'The insight has been closed.  No further action can be taken.'
                    : 'Error sending high five';

            snackActions.error(errorMessage);

            dispatch(
                sageBaseInsightsEngineApi.util.invalidateTags(['insights', 'workflows', 'actions'])
            );
            onClose();
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        trackEvent('Insight Submitted', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Submit',
            context: trackingContext,
            notesLength: notes?.length || 0,
            actionType,
            value,
        });
        completeInsight();
    };

    return (
        <>
            <DrawerTitle title={title} />

            <form onSubmit={handleSubmit}>
                <DrawerBody>
                    <FormControl fullWidth sx={{ pt: 1.5, mb: 2.5 }}>
                        <TextField
                            id="notes"
                            label="Enter notes here (optional)"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={notes}
                            onChange={({ target }) => setNotes(target.value)}
                        />
                    </FormControl>
                </DrawerBody>

                <DrawerFooter sx={{ gap: 2 }}>
                    <LoadingButton
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        loading={shouldDisableActions}
                        disabled={shouldDisableActions}
                        disableElevation
                    >
                        {!shouldDisableActions && 'Submit'}
                    </LoadingButton>

                    <Button
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={handleGoBack}
                        disabled={shouldDisableActions}
                    >
                        Go Back
                    </Button>
                </DrawerFooter>
            </form>
        </>
    );
}

export default CompleteInsightDrawer;
