export const DoorAlertTimes = {
    AlwaysAlert: 'AlwaysAlert',
    NeverAlert: 'NeverAlert',
    SevenPmToSevenAm: 'SevenPmToSevenAm',
    Custom: 'Custom',
};

export const DoorAlertTimeLabels = {
    [DoorAlertTimes.AlwaysAlert]: 'Always Alert',
    [DoorAlertTimes.NeverAlert]: 'Never Alert',
    [DoorAlertTimes.SevenPmToSevenAm]: '7 PM - 7 AM',
    [DoorAlertTimes.Custom]: 'Custom Time',
};
