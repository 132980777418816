import { useContext } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { CheckInReasons, CheckInStatuses } from '../../constants/CheckIn';
import StackedButtonOptions from '../fragments/StackedButtonOptions';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { Unit, useUpdateCheckInMutation } from 'src/store/sageAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

const DrawerName = 'Unit Check-In Drawer';

const options = [
    { label: 'Seen in-person', value: CheckInReasons.SeenInPerson },
    { label: 'Called resident', value: CheckInReasons.CalledResident },
    { label: 'Team member verified', value: CheckInReasons.TeamMemberVerified },
];

interface UnitCheckInDrawerProps extends DrawerComponentProps {
    unit: Unit & { checkIn: { id: string } };
    onNext: () => void;
}

export default function UnitCheckInDrawer(props: UnitCheckInDrawerProps) {
    const { onClose, onNext, unit } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const [updateCheckIn] = useUpdateCheckInMutation();

    const handleCheckIn = async (checkInReason: string) => {
        const buttonText =
            options
                .find(({ value }) => value === checkInReason)
                ?.label.toLocaleLowerCase()
                .replaceAll(' ', '-') || checkInReason;
        trackEvent('Check-in Reason Selected', { checkInReason, buttonText, context: DrawerName });

        try {
            await updateCheckIn({
                checkInId: unit?.checkIn?.id,
                updateCheckInRequest: {
                    status: CheckInStatuses.Complete,
                    checkInReason,
                },
            }).unwrap();

            snackActions.success(`Check-in updated for unit ${unit.displayName}`);
            onNext();
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Error updating check in',
                unitId: unit?.id,
                checkInReason,
            });
            snackActions.error('Error updating check in, please try again');
        }
    };

    return (
        <>
            <DrawerTitle title="Select option to check-in" />
            <DrawerFooter>
                <StackedButtonOptions
                    cancelAction={{ onSelect: onClose }}
                    onSelect={handleCheckIn}
                    options={options}
                />
            </DrawerFooter>
        </>
    );
}
