import { Button, Stack } from '@mui/material';

import '../../assets/styles/buttonStyles.scss';

interface StackedButtonOptionsProps {
    cancelAction?: {
        label?: string;
        onSelect: () => void;
    };
    onSelect: (selectedItem: any) => void;
    options?: {
        isPrimary?: boolean;
        isSelected?: boolean;
        label: string;
        value: any;
    }[];
}

function StackedButtonOptions(props: StackedButtonOptionsProps) {
    const {
        cancelAction = { label: 'Cancel', onSelect: () => {} },
        onSelect = () => {},
        options = [],
    } = props;

    return (
        <Stack
            sx={{
                gap: 2,
                width: '100%',
            }}
        >
            {options?.map((option) => (
                <Button
                    className={`ctaButton ${option.isSelected ? 'selected' : ''}`}
                    color="primary"
                    disableElevation
                    fullWidth
                    key={option.label}
                    onClick={() => onSelect(option.value)}
                    size="large"
                    variant={option.isPrimary ? 'contained' : 'outlined'}
                >
                    {option.label}
                </Button>
            ))}
            {cancelAction && (
                <Button
                    className="ctaButton"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={cancelAction.onSelect}
                    size="large"
                >
                    {cancelAction.label || 'Cancel'}
                </Button>
            )}
        </Stack>
    );
}

export default StackedButtonOptions;
