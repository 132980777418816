import { Box } from '@mui/system';

import atlantis from '../../assets/images/enterpriseLogos/atlantis.png';
import bridge from '../../assets/images/enterpriseLogos/bridge.svg';
import dominion from '../../assets/images/enterpriseLogos/dominion.png';
import frontier from '../../assets/images/enterpriseLogos/frontier.png';
import lenity from '../../assets/images/enterpriseLogos/lenity.png';
import sonida from '../../assets/images/enterpriseLogos/sonida.png';
import sageLogo from '../../assets/images/sage-logo-on-light.svg';
import Enterprises from 'src/constants/Enterprises';

import './styles/authFlow.scss';

const logoMap = {
    [Enterprises.Atlantis]: atlantis,
    [Enterprises.Bridge]: bridge,
    [Enterprises.Dominion]: dominion,
    [Enterprises.Frontier]: frontier,
    [Enterprises.Lenity]: lenity,
    [Enterprises.Sonida]: sonida,
};

const enterpriseNameRegex = /^(.*)\.hellosage\.com$/i;

export default function EnterpriseBranding() {
    const enterpriseName = window.location.hostname.match(enterpriseNameRegex)?.[1];

    const logoPath = logoMap[enterpriseName?.toLocaleLowerCase?.()] ?? sageLogo;

    return (
        <Box
            sx={{
                mb: 3,
            }}
        >
            <img src={logoPath} alt={enterpriseName} className="authCompanyBranding" />
        </Box>
    );
}
