import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsPreventingBodyScrolling, setPreventBodyScrolling } from 'src/store/globalUISlice';
import { AllowBodyScrolling, PreventBodyScrolling } from 'src/utils/uiUtils';

function useBlockPageScroll() {
    const dispatch = useDispatch();
    const isPreventingBodyScrolling = useSelector(selectIsPreventingBodyScrolling);

    const updatePageScrollBlock = useCallback(
        (shouldBlockScroll: boolean, scrollPosition: number | undefined) => {
            // If the desired state is different from the current state, then we need to update the state
            if (shouldBlockScroll !== isPreventingBodyScrolling) {
                if (shouldBlockScroll) {
                    PreventBodyScrolling(scrollPosition!);
                } else {
                    AllowBodyScrolling();
                }
                dispatch(setPreventBodyScrolling(shouldBlockScroll));
            }
        },
        [dispatch, isPreventingBodyScrolling]
    );

    return updatePageScrollBlock;
}

export default useBlockPageScroll;
