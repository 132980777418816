import React from 'react';

import '../../assets/styles/containerStyles.scss';
import './styles/preloadDisplayStyles.scss';

const PreloadDisplay = (props) => (
    <div className="pageContainer">
        <div className="PreloadDisplayCenterAll">{props.children}</div>
    </div>
);

export default PreloadDisplay;
