import { HourTo12 } from 'src/utils/timeUtils';

export const resolveDoorAlarmScheduleLabel = (doorAlarmSchedule) => {
    if (doorAlarmSchedule?.alwaysAlert) {
        return 'Always Alert';
    } else if (doorAlarmSchedule?.neverAlert) {
        return 'Never Alert';
    }
    return 'Scheduled Alerts';
};

const getMinutesInDayFromString = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
};

const getTimeStringFromMinutes = (minutes) => {
    const hoursString = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0');
    const minutesString = (minutes % 60).toString().padStart(2, '0');
    return `${hoursString}:${minutesString}`;
};

export const formatTimeForDisplay = (timeStr, includeAmPm = false) => {
    const [hour, minutes] = timeStr.split(':');
    const displayHour = HourTo12(hour);
    const amOrPm = hour < 12 ? 'AM' : 'PM';
    return `${displayHour}:${minutes}${includeAmPm ? ` ${amOrPm}` : ''}`;
};

export const inclusionTimeRangeForDisplay = (timeRange) => {
    const { startTime, endTime } = timeRange;
    const startMinutes = getMinutesInDayFromString(startTime);
    const endMinutes = getMinutesInDayFromString(endTime);
    const timeAdverbs = ['yesterday', 'today', 'tomorrow'];
    const formattedStartTime = formatTimeForDisplay(startTime, true);
    const formattedEndTime = formatTimeForDisplay(endTime, true);

    if (startMinutes > endMinutes) {
        return `${formattedStartTime} ${timeAdverbs[0]} - ${formattedEndTime} ${timeAdverbs[1]}, ${formattedStartTime} ${timeAdverbs[1]} - ${formattedEndTime} ${timeAdverbs[2]}`;
    }

    return `${formattedStartTime} ${timeAdverbs[1]} - ${formattedEndTime} ${timeAdverbs[1]}, ${formattedStartTime} ${timeAdverbs[2]} - ${formattedEndTime} ${timeAdverbs[2]}`;
};

const shiftMinutes = (minutesInDay, minutesToShift) => {
    if (minutesInDay === 1439 && minutesToShift >= 1) {
        // avoid returning 1440+ minutes
        return 0 + minutesToShift - 1;
    }
    if (minutesInDay === 0 && minutesToShift <= -1) {
        // avoid returning -1 minutes
        return 1439 + minutesToShift + 1;
    }

    return minutesInDay + minutesToShift;
};

const handleSingleTimeRange = (timeRange, returnInclusion = false) => {
    // For only one time range, we don't know if it is an inclusion range from the UI or an exclsuion range from the backend. We have to rely on the returnInclusion flag to to know what to return.

    const { startTime, endTime } = timeRange;

    const startTimeMinutes = getMinutesInDayFromString(startTime);
    const endTimeMinutes = getMinutesInDayFromString(endTime);

    if (startTimeMinutes > endTimeMinutes || returnInclusion) {
        // If the start time is greater than the end time, the input is coming from the UI because the backend does not support startTime > endTime.
        // If the returnSingleTimeRange flag is true, then we don't want to return two exclusion time ranges and the result is the same as if startTime > endTime.
        return [
            {
                startTime: getTimeStringFromMinutes(shiftMinutes(endTimeMinutes, 1)),
                endTime: getTimeStringFromMinutes(shiftMinutes(startTimeMinutes, -1)),
            },
        ];
    } else {
        // If the start time is less than the end time, then we can assume that the time range is contained within a single day and we need to calculate the inverse (exclusion) time range(s) bounded by 00:00 and 23:59

        if (startTimeMinutes === 0) {
            // If the start time is 00:00, then we need to return a single time range from the end time to 23:59
            return [
                {
                    startTime: getTimeStringFromMinutes(shiftMinutes(endTimeMinutes, 1)),
                    endTime: '23:59',
                },
            ];
        }

        if (endTimeMinutes === 1439) {
            // If the end time is 23:59, then we need to return a single time range from 00:00 to the start time
            return [
                {
                    startTime: '00:00',
                    endTime: getTimeStringFromMinutes(shiftMinutes(startTimeMinutes, -1)),
                },
            ];
        }

        // If the start time is not 00:00 and the end time is not 23:59, then we need to return two time ranges, one from 00:00 to the start time and one from the end time to 23:59

        return [
            {
                startTime: '00:00',
                endTime: getTimeStringFromMinutes(shiftMinutes(startTimeMinutes, -1)),
            },
            {
                startTime: getTimeStringFromMinutes(shiftMinutes(endTimeMinutes, 1)),
                endTime: '23:59',
            },
        ];
    }
};

export const invertTimeRange = (timeRangeArray, returnInclusion = false) => {
    // This function assumes a maximum of two items in the array that's passed in. Since the backend manages exclusions, more than two time ranges would not map to a valid inclusion range. This is because the UI (for now) displays the inclusion range as one startTime and one endTime.

    // The returnInclusion flag is used to tell the function to return a single inclusion time range in the case where one time range is passed in and we don't know whether it is an inclusion or exclusion.

    if (timeRangeArray?.length > 1) {
        // If there is more than one time range in the array, we can assume that they are exclusion ranges and not inclusion beause the UI outputs a singular startTime and endTime

        // Javascript should sort the array automatically, but just in case
        const sortedtimeRangeArray = [...timeRangeArray];
        sortedtimeRangeArray.sort(
            (a, b) =>
                getMinutesInDayFromString(a.startTime) - getMinutesInDayFromString(b.startTime)
        );

        const [timeRange1, timeRange2] = sortedtimeRangeArray;
        const timeRange1End = getMinutesInDayFromString(timeRange1.endTime);
        const timeRange2Start = getMinutesInDayFromString(timeRange2.startTime);
        const timeRange2End = getMinutesInDayFromString(timeRange2.endTime);

        // Check for overlap
        if (timeRange2Start <= timeRange1End) {
            // Combine the two intervals if they overlap
            const laterEnd =
                timeRange2End > timeRange1End ? timeRange2.endTime : timeRange1.endTime;

            const combinedInterval = { startTime: timeRange1.startTime, endTime: laterEnd };

            return handleSingleTimeRange(combinedInterval, true);
        } else {
            // No overlap
            return [
                {
                    startTime: getTimeStringFromMinutes(shiftMinutes(timeRange1End, 1)),
                    endTime: getTimeStringFromMinutes(shiftMinutes(timeRange2Start, -1)),
                },
            ];
        }
    } else {
        return handleSingleTimeRange(timeRangeArray[0], returnInclusion);
    }
};
