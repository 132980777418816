import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CareTypeLabels } from 'src/constants/CareTypes';

const PropSizeToElementSizeMap = {
    small: {
        icon: 'xs',
        text: 'subtitle1',
    },
    medium: {
        icon: 'sm',
        text: 'body2',
    },
    large: {
        icon: '1x',
        text: 'body1',
    },
};
const CareTypeLabel = (props) => {
    const { careType, labelsMap = CareTypeLabels, size = 'medium', dark = true } = props;
    const theme = useTheme();

    if (!careType) {
        return;
    }

    return (
        <Box
            component="span"
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FontAwesomeIcon
                icon={faBuilding}
                size={PropSizeToElementSizeMap[size].icon}
                color={dark ? theme.palette.black.main : theme.palette.grey[500]}
            />
            <Typography
                component="span"
                variant={PropSizeToElementSizeMap[size].text}
                sx={{
                    ml: 0.5,
                    color: dark ? 'grey.900' : 'grey.500',
                }}
            >
                {labelsMap[careType] || careType}
            </Typography>
        </Box>
    );
};

export default CareTypeLabel;
