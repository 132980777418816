import clsx from 'clsx';

import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Box, Checkbox, Typography, Skeleton, Stack } from '@mui/material';

import { pluralize } from 'src/utils/intlUtils';

import './styles/careZoneCheckbox.scss';

export default function CareZoneCheckbox(props) {
    const {
        careZone,
        isChecked = false,
        isDisabled = false,
        isLoadingMembersOnShift = false,
        membersOnShift = 0,
        onClick = () => {},
    } = props;

    const handleChange = () => {
        onClick(careZone);
    };

    return (
        <Button
            className={clsx('careZoneCheckboxContainerButton', isChecked && 'selected')}
            disabled={isDisabled}
            onClick={handleChange}
            data-dd-action-name={`${careZone?.name} checkbox`}
        >
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Checkbox
                    checked={isChecked}
                    disabled={isDisabled}
                    inputProps={{ 'aria-label': careZone?.name }}
                    className="careZoneCheckbox"
                />
                <Box>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            color: 'primary',
                            textTransform: 'capitalize',
                            mb: 0.5,
                        }}
                    >
                        {careZone?.name}
                    </Typography>

                    <Stack
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            gap: 1.5,
                            color: membersOnShift === 0 ? 'red.900' : 'grey.700',
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} />
                        <Typography variant="body1">
                            {isLoadingMembersOnShift && (
                                <Skeleton animation="wave" height={21} width={120} />
                            )}
                            {!isLoadingMembersOnShift &&
                                membersOnShift !== undefined &&
                                `${pluralize(membersOnShift, 'Member', 'Members')} on shift`}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Button>
    );
}
