import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SubscriptionOptions } from '@reduxjs/toolkit/query';

import {
    selectEnterpriseId,
    selectFacilityId,
    selectSageSupportGroupId,
    selectCareGroupId,
} from 'src/store/configSlice';
import {
    StaffMember,
    useGetGroupQuery,
    useGetStaffMembersQuery,
    useGetMembershipDetailsQuery,
} from 'src/store/sageAdminApi';

export interface ExtendedStaffMember extends StaffMember {
    isInCareStaffGroup?: boolean;
    isCareStaffGroupAdmin?: boolean;
    shouldReceiveAllCareMessages?: boolean;
}

function useFacilityStaff(excludeSageSupport = true, queryOptions: SubscriptionOptions = {}) {
    const enterpriseId = useSelector(selectEnterpriseId);
    const facilityId = useSelector(selectFacilityId);
    const sageSupportGroupId = useSelector(selectSageSupportGroupId);
    const careGroupId = useSelector(selectCareGroupId);

    const {
        pollingInterval = 0,
        refetchOnFocus = false,
        refetchOnReconnect = false,
    } = queryOptions;

    const {
        data: allStaffMembers,
        isLoading: isLoadingStaffMembers,
        isFetching: isFetchingStaffMembers,
        refetch: refetchStaffMembers,
    } = useGetStaffMembersQuery(
        { enterpriseId, facilityId },
        {
            pollingInterval,
            refetchOnFocus,
            refetchOnReconnect,
            skip: !enterpriseId || !facilityId,
        }
    );

    const {
        staffMembersSet,
        isLoading: isLoadingGroup,
        isFetching: isFetchingGroup,
        refetch: refetchGroup,
    } = useGetGroupQuery(
        { groupId: sageSupportGroupId! },
        {
            skip: !sageSupportGroupId || !excludeSageSupport,
            selectFromResult: (result) => ({
                ...result,
                staffMembersSet: new Set(result.data?.members?.map((member) => member.id) ?? []),
            }),
        }
    );

    const { careGroupMembersMap } = useGetMembershipDetailsQuery(
        { groupId: careGroupId! },
        {
            skip: !careGroupId,
            selectFromResult: (result) => ({
                ...result,
                careGroupMembersMap: new Map(
                    result.data?.map((member) => [member?.staffMember?.id, member])
                ),
            }),
        }
    );

    const careZoneOnShiftMap = useMemo(
        () =>
            allStaffMembers?.reduce((acc, staffMember) => {
                if (excludeSageSupport && staffMembersSet?.has(staffMember.id)) {
                    return acc;
                }

                const facilityOnCallState = staffMember?.onCallStates?.find(
                    (onCallState) => onCallState.facilityId === facilityId
                );

                const careZones =
                    staffMember.staffMemberFacilityConfigs?.find(
                        (config) => config.facilityId === facilityId
                    )?.careZones || [];

                /* eslint-disable no-param-reassign */
                for (const careZone of careZones) {
                    const careZoneId = careZone.id;
                    if (!careZoneId) {
                        continue;
                    }

                    if (acc[careZoneId] && facilityOnCallState?.isOnCall) {
                        acc[careZoneId].push(staffMember);
                    } else if (!acc[careZoneId]) {
                        acc[careZoneId] = facilityOnCallState?.isOnCall ? [staffMember] : [];
                    }
                }
                /* eslint-enable no-param-reassign */
                return acc;
            }, {} as { [careZoneId: string]: StaffMember[] }),
        [excludeSageSupport, facilityId, allStaffMembers, staffMembersSet]
    );

    const staffMembers: ExtendedStaffMember[] = useMemo(() => {
        const filteredStaffMembers =
            (excludeSageSupport
                ? allStaffMembers?.filter((staffMember) => !staffMembersSet?.has(staffMember.id))
                : allStaffMembers) || [];

        return filteredStaffMembers.map((staffMember) => {
            const shouldReceiveAllCareMessages =
                staffMember?.staffMemberFacilityConfigs?.find(
                    (config) => config.facilityId === facilityId
                )?.shouldReceiveAllCareMessages ?? false;
            const careGroupMembership = careGroupMembersMap?.get(staffMember.id);

            return {
                ...staffMember,
                shouldReceiveAllCareMessages,
                isInCareStaffGroup: !!careGroupMembership,
                isCareStaffGroupAdmin: !!careGroupMembership?.isAdmin,
            };
        });
    }, [excludeSageSupport, allStaffMembers, staffMembersSet, careGroupMembersMap, facilityId]);

    const staffMembersMap = useMemo(() => {
        if (!staffMembers?.length) {
            return new Map<string, ExtendedStaffMember>();
        }

        return new Map(staffMembers?.map((staffMember) => [staffMember.id!, staffMember]));
    }, [staffMembers]);

    const isLoading = isLoadingStaffMembers || isLoadingGroup;
    const isFetching = isFetchingStaffMembers || isFetchingGroup;

    return {
        staffMembers,
        isLoading,
        isFetching,
        refetchStaffMembers,
        refetchGroup,
        staffMembersMap,
        careZoneOnShiftMap,
        careGroupMembersMap,
    };
}

export default useFacilityStaff;
