import clsx from 'clsx';
import { ChangeEvent, useContext, useRef, useState } from 'react';

import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AnalyticsContext } from 'src/AnalyticsContext';

import './styles/SearchInput.styles.scss';

interface SearchInputProps {
    analyticsPrefix?: string;
    placeholder: string;
    searchValue: string;
    onSearchChange: (searchValue: string) => void;
}

function SearchInput(props: SearchInputProps) {
    const { analyticsPrefix, placeholder, searchValue, onSearchChange } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const [isFocused, setIsFocused] = useState(false);
    const { palette } = useTheme();

    const inputRef = useRef<HTMLInputElement>();

    const handleOnFocus = () => {
        trackEvent('Search Input Focused', { context: analyticsPrefix, label: placeholder });
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        trackEvent('Search Input Blurred', { context: analyticsPrefix, label: placeholder });
        setIsFocused(false);
    };

    const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
        onSearchChange(event.target.value);
    };

    const handleClearSearchInput = () => {
        onSearchChange('');

        trackEvent('Search Input Cleared', { context: analyticsPrefix, label: placeholder });

        // Maintain focus on the input field
        inputRef?.current?.focus?.();
    };

    const handleCancelClick = () => {
        onSearchChange('');
        trackEvent('Search Input Cancelled', { context: analyticsPrefix, label: placeholder });

        inputRef?.current?.blur?.();
    };

    return (
        <div className="search-input__container">
            <TextField
                aria-label={placeholder}
                placeholder={placeholder}
                value={searchValue}
                fullWidth
                onChange={handleSearchInput}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                variant="outlined"
                size="small"
                InputProps={{
                    className: 'search-input__input',
                    inputRef,
                    startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSearch} color={palette?.grey?.[900]} />
                        </InputAdornment>
                    ),
                    endAdornment: !!searchValue && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={handleClearSearchInput}
                                className="search-input__clear-icon"
                            >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                variant="text"
                onClick={handleCancelClick}
                className={clsx(
                    'search-input__cancel-button',
                    isFocused && 'search-input__cancel-button--visible'
                )}
            >
                Cancel
            </Button>
        </div>
    );
}

export default SearchInput;
