import { CareZone } from 'src/store/sageAdminApi';

interface CareZoneExclusionsType {
    [key: string]: string[];
}
export const DefaultCareZoneExclusions: CareZoneExclusionsType = {
    'assisted living': ['independent living'],
    'memory care': ['independent living'],
    'independent living': ['assisted living', 'memory care'],
};

export function getCareZoneGroups(
    careZones: CareZone[] = [],
    exclusions: CareZoneExclusionsType = DefaultCareZoneExclusions
) {
    return careZones.reduce(
        (acc, careZone: CareZone) => {
            const { groups, indexMap } = acc;
            const { id, name } = careZone;

            const exclusionsSet = new Set();
            const exclusionsArray = name ? exclusions[name.toLowerCase()] : null;
            exclusionsArray?.forEach((excludedName) => {
                const excludedId = careZones.find(
                    (careZone: CareZone) =>
                        careZone?.name?.toLowerCase() === excludedName.toLowerCase()
                )?.id;

                const excludedIdGroupIndex = indexMap.get(excludedId);
                if (excludedIdGroupIndex !== undefined) {
                    exclusionsSet.add(excludedIdGroupIndex);
                }
            });

            if (!indexMap.has(id)) {
                const groupIndex = groups.findIndex((_, index) => !exclusionsSet.has(index));

                if (groupIndex > -1) {
                    const groupToAddTo = groups[groupIndex];
                    (groupToAddTo as CareZone[]).push(careZone);
                    indexMap.set(id, groupIndex);
                } else {
                    indexMap.set(id, groups.length);
                    (groups as any[]).push([careZone]);
                }
            }

            return acc;
        },
        { groups: [], indexMap: new Map() }
    );
}

interface CareZoneGroupsByFacilityType {
    [key: string]: CareZone[];
}

export function getCareZonesByFacility(careZones: CareZone[]): CareZoneGroupsByFacilityType {
    return (
        careZones?.reduce((acc: CareZoneGroupsByFacilityType, careZone: CareZone) => {
            const { facilityId } = careZone;
            if (!facilityId) return acc;

            if (acc[facilityId]) {
                acc[facilityId].push(careZone);
                return acc;
            } else {
                return {
                    ...acc,
                    [facilityId]: [careZone],
                };
            }
        }, {}) || {}
    );
}

const CareZoneNameAbbreviations: { [key: string]: string } = {
    'assisted living': 'AL',
    'independent living': 'IL',
    'memory care': 'MC',
};
const formatter = new Intl.ListFormat('en', { style: 'short', type: 'conjunction' });

export function getCareZonesDisplayText(careZones: CareZone[] = []): string {
    if (!careZones?.length) {
        return '';
    }

    const careZoneDisplayNames =
        careZones.length > 1
            ? careZones.map((careZone) =>
                  careZone?.name
                      ? CareZoneNameAbbreviations[careZone!.name?.toLocaleLowerCase()]
                      : careZone?.name
              )
            : careZones.map((careZone) => careZone!.name);

    return formatter.format(careZoneDisplayNames.filter((name): name is string => !!name));
}
