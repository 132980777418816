import { useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, CircularProgress, Fade, Stack, Typography } from '@mui/material';

import SageLogo from '../../assets/images/sage-icon.svg';
import AppVersion from './AppVersion';

interface SageLoaderProps {
    message?: string;
    hideSageLogo?: boolean;
    logoSize?: number;
    record?: boolean;
    showReloadLink?: boolean;
    showReloadAfter?: number;
}

function SageLoader(props: SageLoaderProps) {
    const {
        message = 'Loading...',
        hideSageLogo = false,
        logoSize = 72,
        showReloadLink = false,
        showReloadAfter = 10,
    } = props;

    const [showReload, setShowReload] = useState<boolean>(false);

    useEffect(() => {
        if (!showReloadLink) return;

        // Record screen if showing reload link to debug possible "stuck" loading states
        datadogRum.startSessionReplayRecording();

        const timeout = setTimeout(() => {
            setShowReload(true);
        }, showReloadAfter * 1000);

        return () => {
            clearTimeout(timeout);

            datadogRum.stopSessionReplayRecording();
        };
    }, [showReloadAfter, showReloadLink]);

    return (
        <Stack
            sx={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                alignItems: 'center',
                justifyContent: 'center',
                p: 2.5,
                bgcolor: 'white.main',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    borderRadius: '50%',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px calc($spacing / 2)',
                }}
            >
                <CircularProgress color="primary" size={130} thickness={1.2} />
                {!hideSageLogo && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            src={SageLogo}
                            alt="sage logo"
                            sx={{
                                width: logoSize,
                                height: logoSize,
                            }}
                        />
                    </Box>
                )}
            </Box>
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'light',
                    color: 'grey.500',
                    mt: 5,
                }}
            >
                {message}
            </Typography>
            <Fade in={showReload}>
                <Stack
                    sx={{
                        gap: 1,
                        mt: 2,
                    }}
                >
                    <Box>
                        <Typography
                            component="span"
                            variant="body1"
                            sx={{
                                color: 'grey.500',
                            }}
                        >
                            Stuck Loading?{' '}
                        </Typography>
                        <Button
                            component="span"
                            color="primary"
                            disableElevation
                            disabled={!showReload}
                            onClick={() => window.location.replace('/')}
                            variant="text"
                            sx={{ p: 0 }}
                        >
                            Try refreshing the page
                        </Button>
                    </Box>
                    <AppVersion />
                </Stack>
            </Fade>
        </Stack>
    );
}

export default SageLoader;
