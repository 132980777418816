import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';

import RadioGroupOptions from '../fragments/RadioGroupOptions';
import LoadingState from '../fragments/loadingState';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import DrawerTitle from 'src/components/drawers/DrawerTitle';
import CompanyPhoneDetails from 'src/components/fragments/CompanyPhoneDetails';
import useCompanyPhones, { CompanyPhoneAllocation } from 'src/hooks/useCompanyPhones';
import useUserPermissions from 'src/hooks/useUserPermissions';
import { selectEnterpriseId, selectFacilityId } from 'src/store/configSlice';

const DrawerName = 'Select Device Drawer';

export const PersonalDeviceTypes = {
    ManagerPhone: 'Manager Phone',
    Computer: 'Computer',
};

export type DeviceSelection = {
    deviceAllocation?: CompanyPhoneAllocation;
    isTakeoverDevice?: boolean;
    isPersonalDevice?: boolean;
    personalDeviceType?: string;
};

export const PersonalDeviceOptions = [
    {
        label: (
            <Typography
                variant="body1"
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                }}
            >
                {PersonalDeviceTypes.ManagerPhone}
            </Typography>
        ),
        value: PersonalDeviceTypes.ManagerPhone,
    },
    {
        label: (
            <Typography
                variant="body1"
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                }}
            >
                {PersonalDeviceTypes.Computer}
            </Typography>
        ),
        value: PersonalDeviceTypes.Computer,
    },
];

function SelectDeviceDrawer(props: DrawerComponentProps) {
    const { onNext } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const enterpriseId = useSelector(selectEnterpriseId);
    const facilityId = useSelector(selectFacilityId);

    const { isInManagementGroup } = useUserPermissions();

    const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>();

    const {
        availableCompanyPhones,
        unavailableCompanyPhones,
        isFetching,
        errorFetchingCompanyPhones,
        errorFetchingOnCallStaffMembers,
        hasError,
        isLoading,
        refetchCompanyPhones,
        refetchStaffMembers,
    } = useCompanyPhones();

    const handleSelectDeviceOption = (deviceId: string) => {
        trackEvent('Device Option Selected', {
            objectType: ObjectTypes.Radio,
            context: DrawerName,
            deviceId,
        });
        setSelectedDeviceId(deviceId);
    };

    const handleSelectDevice = () => {
        trackEvent('Device Selected', {
            objectType: ObjectTypes.Button,
            context: DrawerName,
            deviceId: selectedDeviceId,
        });

        const companyPhone = availableCompanyPhones?.find(
            (phoneAllocation) => phoneAllocation.companyPhone.id === selectedDeviceId
        );
        const takeoverDevice = unavailableCompanyPhones?.find(
            (phoneAllocation) => phoneAllocation.companyPhone.id === selectedDeviceId
        );

        if (takeoverDevice) {
            onNext({ deviceAllocation: takeoverDevice, isTakeoverDevice: true });
        } else if (companyPhone) {
            onNext({ deviceAllocation: companyPhone });
        } else {
            onNext({
                isPersonalDevice: true,
                personalDeviceType: selectedDeviceId,
            });
        }
    };

    const handleRefetch = () => {
        if (errorFetchingCompanyPhones) {
            refetchCompanyPhones();
        }

        if (errorFetchingOnCallStaffMembers) {
            refetchStaffMembers();
        }
    };

    useEffect(() => {
        if (errorFetchingCompanyPhones) {
            datadogRum.addError(errorFetchingCompanyPhones, {
                message: 'Error fetching company phones',
                facilityId,
                enterpriseId,
            });
        }
    }, [enterpriseId, errorFetchingCompanyPhones, facilityId]);

    useEffect(() => {
        if (errorFetchingOnCallStaffMembers) {
            datadogRum.addError(errorFetchingOnCallStaffMembers, {
                message: 'Error fetching on-call staff members',
                facilityId,
                enterpriseId,
            });
        }
    }, [enterpriseId, errorFetchingOnCallStaffMembers, facilityId]);

    return (
        <>
            <DrawerTitle title="What device is this?" />
            <DrawerBody>
                {/* Loading State */}
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <LoadingState loadingText="Loading company phones" />
                    </Box>
                )}

                {/* Error State */}
                {!isLoading && hasError && (
                    <Stack
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h5">
                            There was an error loading company phones
                        </Typography>
                        <LoadingButton
                            className="ctaButton"
                            color="primary"
                            disableElevation
                            disabled={isFetching}
                            loading={isFetching}
                            fullWidth
                            onClick={handleRefetch}
                            size="large"
                            variant="outlined"
                        >
                            {isFetching ? '' : 'Retry'}
                        </LoadingButton>
                    </Stack>
                )}

                {/* Fulfilled State */}
                {!isLoading && !hasError && (
                    <Stack
                        sx={{
                            mx: -2,
                            px: 2,
                            gap: 4,
                            overflowY: 'auto',
                        }}
                    >
                        {availableCompanyPhones?.length > 0 && (
                            <Stack
                                sx={{
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h5">Available Community Phones</Typography>
                                <RadioGroupOptions
                                    radioGroupProps={{ 'aria-label': 'available community phones' }}
                                    variant="outlined"
                                    options={availableCompanyPhones?.map((phoneAllocation) => {
                                        const { companyPhone } = phoneAllocation;

                                        return {
                                            value: companyPhone.id!,
                                            label: () => (
                                                <CompanyPhoneDetails
                                                    companyPhone={companyPhone}
                                                    containerProps={{ py: 1 }}
                                                />
                                            ),
                                        };
                                    })}
                                    onSelectOption={handleSelectDeviceOption}
                                    selectedOption={selectedDeviceId}
                                />
                            </Stack>
                        )}

                        {(unavailableCompanyPhones?.length || 0) > 0 && (
                            <Stack
                                sx={{
                                    gap: 2,
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: 'grey.900',
                                    }}
                                >
                                    In-Use Community Phones
                                </Typography>

                                <RadioGroupOptions
                                    radioGroupProps={{ 'aria-label': 'in-use community phones' }}
                                    variant="outlined"
                                    options={unavailableCompanyPhones?.map((phoneAllocation) => {
                                        const { companyPhone, staffMember } = phoneAllocation;

                                        return {
                                            value: companyPhone.id!,
                                            label: (
                                                <CompanyPhoneDetails
                                                    companyPhone={companyPhone}
                                                    staffMember={staffMember}
                                                    containerProps={{ py: 1 }}
                                                />
                                            ),
                                        };
                                    })}
                                    onSelectOption={handleSelectDeviceOption}
                                    selectedOption={selectedDeviceId}
                                />
                            </Stack>
                        )}

                        <Box
                            sx={{
                                order: isInManagementGroup ? -1 : 1,
                            }}
                        >
                            {!isInManagementGroup && (
                                <Typography
                                    variant="h5"
                                    sx={{
                                        mb: 2,
                                    }}
                                >
                                    Other Devices
                                </Typography>
                            )}
                            <RadioGroupOptions
                                radioGroupProps={{ 'aria-label': 'other devices' }}
                                variant="outlined"
                                options={PersonalDeviceOptions}
                                onSelectOption={handleSelectDeviceOption}
                                selectedOption={selectedDeviceId}
                            />
                        </Box>
                    </Stack>
                )}
            </DrawerBody>
            <DrawerFooter sx={{ borderTop: 1, borderColor: 'grey.200' }}>
                <Button
                    fullWidth
                    disableElevation
                    color="primary"
                    size="large"
                    variant="contained"
                    disabled={!selectedDeviceId}
                    className="ctaButton"
                    onClick={handleSelectDevice}
                >
                    Select
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectDeviceDrawer;
