import { useContext, useMemo, useState } from 'react';

import { faSensor } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';

import RadioGroupOptions, { Variants } from '../fragments/RadioGroupOptions';
import SearchInput from '../fragments/SearchInput';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import { Door } from 'src/store/sageAdminApi';

import '../../assets/styles/buttonStyles.scss';

interface SelectDoorDrawerProps extends DrawerComponentProps {
    doors: Door[];
    preSelectedDoorId?: string;
}

function SelectDoorDrawer(props: SelectDoorDrawerProps) {
    const { doors, preSelectedDoorId, onNext, title = 'Select Door' } = props;
    const { trackEvent } = useContext(AnalyticsContext);
    const [selectedDoorId, setSelectedDoorId] = useState(preSelectedDoorId || '');
    const [searchTerm, setSearchTerm] = useState('');

    const filteredDoors = useMemo(() => {
        if (!!searchTerm) {
            return doors?.filter((door) =>
                door?.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return doors;
        }
    }, [doors, searchTerm]);

    const handleSelectDoor = (doorId: string) => {
        trackEvent('Select Door Clicked', { ObjectType: ObjectTypes.Button, door: doorId });
        setSelectedDoorId(doorId);
    };

    const handleContinue = () => {
        trackEvent('Continue Clicked', { ObjectType: ObjectTypes.Button, selectedDoorId });
        onNext({ value: selectedDoorId });
    };

    return (
        <>
            {/* Fixed Header */}
            <DrawerTitle title={title}>
                <SearchInput
                    searchValue={searchTerm}
                    onSearchChange={setSearchTerm}
                    placeholder="Search by door name"
                />
            </DrawerTitle>
            {/* Scrolling Area */}
            <DrawerBody>
                {filteredDoors?.length > 0 && (
                    <RadioGroupOptions
                        radioGroupProps={{ 'aria-label': 'unit' }}
                        variant={Variants.Outlined}
                        selectedOption={selectedDoorId}
                        onSelectOption={handleSelectDoor}
                        options={filteredDoors?.map((door) => ({
                            disabled: !!door.deviceAllocation,
                            value: door.rid,
                            label: (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: !!door.deviceAllocation
                                                ? 'grey.500'
                                                : 'grey.900',
                                        }}
                                    >
                                        {door.name}
                                    </Typography>

                                    {!!door.deviceAllocation && (
                                        <FontAwesomeIcon icon={faSensor} size="lg" />
                                    )}
                                </Box>
                            ),
                        }))}
                    />
                )}

                {filteredDoors?.length === 0 && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'grey.700',
                        }}
                    >
                        {!!searchTerm ? 'No doors found' : 'No doors'}
                    </Typography>
                )}
            </DrawerBody>
            {/* Bottom Sticky Buttons */}
            <DrawerFooter>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={!selectedDoorId}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectDoorDrawer;
