import { format, fromUnixTime } from 'date-fns';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CheckInStatuses, ValidCheckInStatus } from '../../constants/CheckIn';
import CareTypeLabel from '../fragments/CareTypeLabel';
import ResidentsInUnit from '../units/ResidentsInUnit';
import UnitCtaButtons from '../units/UnitCtaButtons';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps, DrawerContext } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { selectIsIndependentLiving } from 'src/store/configSlice';
import { Resident, Unit } from 'src/store/sageAdminApi';

const DrawerName = 'Unit Details Drawer';
interface UnitDetailDrawerProps extends DrawerComponentProps {
    unit: Unit & { checkIn: { checkInTimestamp: string; status: ValidCheckInStatus } };
}

export default function UnitDetailDrawer(props: UnitDetailDrawerProps) {
    const { onClose, onNext, unit } = props;

    const { palette } = useTheme();
    const navigate = useNavigate();
    const { closeDrawer } = useContext(DrawerContext);

    const isIndependentLiving = useSelector(selectIsIndependentLiving);

    const unitCheckedIn = isIndependentLiving && unit?.checkIn?.status === CheckInStatuses.Complete;

    const checkInTimestamp = useMemo(() => {
        if (unit.checkIn?.checkInTimestamp) {
            return format(fromUnixTime(parseInt(unit.checkIn.checkInTimestamp) / 1000), 'p');
        }
        return '';
    }, [unit]);

    return (
        <>
            <DrawerTitle>
                <Stack
                    sx={{
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {unitCheckedIn && (
                            <FontAwesomeIcon
                                icon={faCalendarCheck}
                                color={palette.success.main}
                                fontSize={20}
                                style={{ marginRight: 4 }}
                            />
                        )}
                        <Typography
                            align="center"
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            {unit.displayName}
                        </Typography>
                    </Box>
                    {unit.careType && <CareTypeLabel careType={unit.careType} dark={false} />}
                </Stack>
                {unitCheckedIn && (
                    <Box
                        sx={{
                            pt: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Unit Checked-in at {checkInTimestamp}
                        </Typography>
                    </Box>
                )}
            </DrawerTitle>
            {/* Scrolling Area */}
            <DrawerBody>
                <ResidentsInUnit
                    unit={unit}
                    analyticsPrefix={DrawerName}
                    showEmptyMessage={false}
                    onResidentClick={(resident: Resident) => {
                        navigate(`/residents/resident/${resident?.id}`);
                        closeDrawer();
                    }}
                />
            </DrawerBody>
            {/* Bottom Sticky Buttons */}
            <DrawerFooter>
                <UnitCtaButtons
                    unit={unit}
                    analyticsPrefix={DrawerName}
                    showEmptyMessage
                    onClose={onClose}
                    onNext={onNext}
                />
            </DrawerFooter>
        </>
    );
}
