import { Box, CircularProgress, Typography } from '@mui/material';

import PreloadDisplay from './preloadDisplay';

const LoadingState = (props) => {
    const { loadingText = 'Loading...' } = props;

    return (
        <PreloadDisplay>
            <CircularProgress color="primary" />
            <Box
                sx={{
                    mt: 2.5,
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: 'grey.900',
                    }}
                >
                    {loadingText}
                </Typography>
            </Box>
        </PreloadDisplay>
    );
};

export default LoadingState;
