import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';

import RoutineCheckChip from '../fragments/RoutineCheckChip';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import ResidentAvatar from 'src/component-library/ResidentAvatar';
import HospiceChip from 'src/components/fragments/HospiceChip';
import ResuscitationChip from 'src/components/fragments/ResuscitationChip';
import UnitCheckInCard from 'src/components/fragments/UnitCheckInCard';
import { CareTypeLabelsAbbreviated, CareTypes } from 'src/constants/CareTypes';
import { CheckInStatuses } from 'src/constants/CheckIn';
import { Drawers } from 'src/constants/Drawers';
import useCheckIns from 'src/hooks/useCheckIns';
import usePinnedResidentIds from 'src/hooks/usePinnedResidentIds';
import useUserPermissions from 'src/hooks/useUserPermissions';
import { selectRoutineCheckInterval } from 'src/store/configSlice';
import {
    CheckIn,
    Resident,
    useGetLatestCareActivityQuery,
    useGetResidentQuery,
} from 'src/store/sageAdminApi';
import { validateHospiceStatus } from 'src/utils/HospiceUtils';
import { snackActions } from 'src/utils/snackBarTools';

interface ResidentDetailsDrawerProps extends DrawerComponentProps {
    resident: Resident;
}

const DrawerName = 'Resident Details Drawer';

function ResidentDetailsDrawer(props: ResidentDetailsDrawerProps) {
    const { hasNextDrawer, onClose, onNext, resident } = props;

    const routineCheckInterval = useSelector(selectRoutineCheckInterval);

    const { id: residentId, firstName, lastName, age, unit } = resident;
    const residentName = `${firstName} ${lastName}`;
    const unitIsIL = unit?.careType === CareTypes.IndependentLiving;

    const { trackEvent } = useContext(AnalyticsContext);

    const { canSeePHI, isLoading: isLoadingPermissions } = useUserPermissions();
    const { addToPinned, removeFromPinned, pinnedResidentIds } = usePinnedResidentIds();
    const isPinned = pinnedResidentIds?.has(residentId!);

    const { data: residentData, isLoading: isLoadingResident } = useGetResidentQuery(
        { residentId: residentId! },
        { skip: !residentId }
    );

    const isOnHold = !!resident?.isOnHold;
    const isArchived = !!resident?.isArchived;
    const isActive = !isOnHold && !isArchived;

    const residentUnitId = residentData?.unit?.id;

    const showActivityProgress =
        resident?.isRoutineCheckEnabled && routineCheckInterval > 0 && isActive && !unitIsIL;

    const { minSinceLastActivity, isLoading: isLoadingCareActivity } =
        useGetLatestCareActivityQuery(
            { getLatestCareActivityRequest: { residentRids: [residentId!] } },
            {
                skip: !residentId,
                pollingInterval: showActivityProgress ? 60_000 : 0,
                selectFromResult: (results) => ({
                    ...results,
                    minSinceLastActivity:
                        results.data?.[residentId!]?.timeSinceLastCareActivityInMin,
                }),
            }
        );

    const { checkIns } = useCheckIns({ unitId: residentUnitId });

    const unitIsCheckedIn =
        checkIns?.some(
            (checkIn: CheckIn) =>
                checkIn?.unit?.id === residentUnitId && checkIn?.status === CheckInStatuses.Complete
        ) || false;

    const { hospiceHealthInfo, resuscitationStrategy } = residentData?.healthInfo || {};

    const handlePinResident = () => {
        trackEvent('Add To Care Assignment Clicked', {
            objectType: ObjectTypes.Button,
            residentId,
            context: DrawerName,
        });

        addToPinned(residentId!);
        snackActions.success('Resident Added', { autoHideDuration: 2_000 });
    };

    const handleUnpinResident = () => {
        trackEvent('Remove From Care Assignment Clicked', {
            objectType: ObjectTypes.Button,
            residentId,
            context: DrawerName,
        });

        removeFromPinned(residentId!);
        snackActions.success('Resident Removed', { autoHideDuration: 2_000 });
    };

    const handleClickViewProfile = () => {
        trackEvent('View Profile Button Clicked', {
            residentId,
            context: DrawerName,
        });
        onClose();
    };

    const handleClickLogCare = () => {
        trackEvent('Log Care Button Clicked', {
            objectType: ObjectTypes.Button,
            residentId,
            context: DrawerName,
        });

        onNext(
            {
                hideAccidentallyPressed: true,
                hideFoundDevice: true,
                hideRoutineCheck: false,
                redirectOnSuccess: false,
                resident: residentData,
                residentId,
                successMessage: `Care logged for ${residentName}`,
            },
            hasNextDrawer
                ? undefined
                : { type: Drawers.SelectMultiOutcomeResolution, showCloseIcon: true }
        );
    };

    return (
        <>
            <DrawerTitle>
                {showActivityProgress && (
                    <Box>
                        <RoutineCheckChip
                            minSinceLastActivity={minSinceLastActivity}
                            isLoading={isLoadingCareActivity}
                            showPrefix
                        />
                    </Box>
                )}
                <Stack
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        gap: 1.5,
                    }}
                >
                    <ResidentAvatar
                        resident={resident}
                        badgePosition={{ vertical: 'top', horizontal: 'right' }}
                        isCheckedIn={unitIsCheckedIn}
                        isPinned={isActive && isPinned}
                        showProgress={showActivityProgress}
                        progress={{
                            current: minSinceLastActivity || 0,
                            max: routineCheckInterval,
                        }}
                        size="large"
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            {residentName}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'grey.900',
                            }}
                        >
                            {`Age: `}
                            <b>{age}</b>
                            {' • '}
                            {unit ? (
                                <>
                                    Unit:{' '}
                                    <b>
                                        {unit.displayName}
                                        {' | '}
                                        {unit?.careType && CareTypeLabelsAbbreviated[unit.careType]}
                                    </b>
                                </>
                            ) : (
                                'No Unit'
                            )}
                        </Typography>
                    </Box>
                </Stack>
            </DrawerTitle>
            {!isLoadingResident && !isLoadingPermissions && canSeePHI && (
                <DrawerBody>
                    <Stack
                        direction="row"
                        sx={{
                            gap: 2.5,
                        }}
                    >
                        {/* DNR Display */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                flexBasis: '50%',
                            }}
                        >
                            <Typography
                                component="div"
                                variant="body2"
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Code Status:
                            </Typography>
                            <ResuscitationChip resuscitationStrategy={resuscitationStrategy} />
                        </Box>

                        {/* Hospice Display */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                flexBasis: '50%',
                            }}
                        >
                            <Typography
                                component="div"
                                variant="body2"
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Hospice:
                            </Typography>
                            <HospiceChip
                                hospiceStatus={validateHospiceStatus(
                                    hospiceHealthInfo?.hospiceStatus
                                )}
                            />
                        </Box>
                    </Stack>
                </DrawerBody>
            )}
            <DrawerFooter sx={{ gap: 2.5, justifyContent: 'center', alignItems: 'center' }}>
                {unitIsIL ? (
                    <UnitCheckInCard
                        unit={{ ...unit, currentOccupants: [resident] }}
                        onNext={onNext}
                    />
                ) : isPinned && isActive ? (
                    <Button
                        variant="outlined"
                        color="error"
                        fullWidth
                        disableElevation
                        onClick={handleUnpinResident}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Remove from Care Assignment
                        </Typography>
                    </Button>
                ) : (
                    isActive && (
                        <Button
                            variant="contained"
                            color="primaryInverse"
                            fullWidth
                            disableElevation
                            onClick={handlePinResident}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Add to Care Assignment
                            </Typography>
                        </Button>
                    )
                )}

                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        gap: 2.5,
                    }}
                >
                    <Button
                        component={Link}
                        to={`/residents/resident/${residentId}`}
                        variant="outlined"
                        disableElevation
                        onClick={handleClickViewProfile}
                        sx={{ flexBasis: '50%', flexGrow: 1 }}
                    >
                        View Profile
                    </Button>

                    {!unitIsIL && isActive && (
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={handleClickLogCare}
                            sx={{ flexBasis: '50%', flexGrow: 1 }}
                        >
                            Log Care
                        </Button>
                    )}
                </Stack>
            </DrawerFooter>
        </>
    );
}

export default ResidentDetailsDrawer;
