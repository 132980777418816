import { ProviderContext, OptionsObject, SnackbarMessage, useSnackbar } from 'notistack';

/**
 * We store the `useSnackbar` instance in a variable as a singleton so
 * that we can call the snackbar methods from anywhere in the app without
 * needing to use the `useSnackbar()` hook directly in every component.
 */

let useSnackbarRef: ProviderContext;

const InnerSnackbarUtilsConfigurator = (props: {
    setUseSnackbarRef: (useSnackbarRef: ProviderContext) => void;
}) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

const setUseSnackbarRef = (useSnackbarRefProp: ProviderContext) => {
    useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
);

export const snackActions = {
    success(msg: SnackbarMessage, options?: OptionsObject) {
        this.toast(msg, { ...options, variant: 'success' });
    },
    warning(msg: SnackbarMessage, options?: OptionsObject) {
        this.toast(msg, { ...options, variant: 'warning' });
    },
    info(msg: SnackbarMessage, options?: OptionsObject) {
        this.toast(msg, { ...options, variant: 'info' });
    },
    error(msg: SnackbarMessage, options?: OptionsObject) {
        this.toast(msg, { ...options, variant: 'error', preventDuplicate: true });
    },
    toast(msg: SnackbarMessage, options?: OptionsObject) {
        useSnackbarRef.enqueueSnackbar(msg, options);
    },
};
