import { sageBaseInsightsEngineApi as api } from './sageBaseInsightsEngineApi';

export const addTagTypes = ['insights', 'workflows', 'actions', 'rules'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getInsight: build.query<GetInsightApiResponse, GetInsightApiArg>({
                query: (queryArg) => ({
                    url: `/insights/${queryArg.insightRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['insights'],
            }),
            resetInsight: build.mutation<ResetInsightApiResponse, ResetInsightApiArg>({
                query: (queryArg) => ({
                    url: `/insights/${queryArg.insightRid}/reset`,
                    method: 'POST',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['insights', 'workflows', 'actions'],
            }),
            searchInsights: build.query<SearchInsightsApiResponse, SearchInsightsApiArg>({
                query: (queryArg) => ({
                    url: `/insights/search`,
                    method: 'POST',
                    body: queryArg.insightSearchRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['insights'],
            }),
            createRule: build.mutation<CreateRuleApiResponse, CreateRuleApiArg>({
                query: (queryArg) => ({
                    url: `/rules`,
                    method: 'POST',
                    body: queryArg.createOrUpdateRuleRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['rules'],
            }),
            getRule: build.query<GetRuleApiResponse, GetRuleApiArg>({
                query: (queryArg) => ({
                    url: `/rules/${queryArg.ruleRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['rules'],
            }),
            updateRule: build.mutation<UpdateRuleApiResponse, UpdateRuleApiArg>({
                query: (queryArg) => ({
                    url: `/rules/${queryArg.ruleRid}`,
                    method: 'PUT',
                    body: queryArg.createOrUpdateRuleRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['rules'],
            }),
            deleteRule: build.mutation<DeleteRuleApiResponse, DeleteRuleApiArg>({
                query: (queryArg) => ({
                    url: `/rules/${queryArg.ruleRid}`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['rules'],
            }),
            searchRules: build.mutation<SearchRulesApiResponse, SearchRulesApiArg>({
                query: (queryArg) => ({
                    url: `/rules/search`,
                    method: 'POST',
                    body: queryArg.ruleSearchRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['rules'],
            }),
            getWorkflows: build.query<GetWorkflowsApiResponse, GetWorkflowsApiArg>({
                query: (queryArg) => ({
                    url: `/workflows`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        insightRid: queryArg.insightRid,
                        type: queryArg['type'],
                        status: queryArg.status,
                    },
                }),
                providesTags: ['workflows'],
            }),
            getWorkflow: build.query<GetWorkflowApiResponse, GetWorkflowApiArg>({
                query: (queryArg) => ({
                    url: `/workflows/${queryArg.workflowRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['workflows'],
            }),
            getAvailableActionTypes: build.query<
                GetAvailableActionTypesApiResponse,
                GetAvailableActionTypesApiArg
            >({
                query: (queryArg) => ({
                    url: `/workflows/${queryArg.workflowRid}/actions`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['workflows', 'actions'],
            }),
            executeAction: build.mutation<ExecuteActionApiResponse, ExecuteActionApiArg>({
                query: (queryArg) => ({
                    url: `/workflows/${queryArg.workflowRid}/actions`,
                    method: 'POST',
                    body: queryArg.executeActionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['insights', 'workflows', 'actions'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageInsightsEngineApi };
export type GetInsightApiResponse = /** status 200 OK */ Insight;
export type GetInsightApiArg = {
    authorization?: string;
    insightRid: string;
};
export type ResetInsightApiResponse = /** status 200 OK */ RidResponse;
export type ResetInsightApiArg = {
    authorization?: string;
    insightRid: string;
};
export type SearchInsightsApiResponse = /** status 200 OK */ InsightSearchResult;
export type SearchInsightsApiArg = {
    authorization?: string;
    insightSearchRequest: InsightSearchRequest;
};
export type CreateRuleApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type CreateRuleApiArg = {
    authorization?: string;
    createOrUpdateRuleRequest: CreateOrUpdateRuleRequest;
};
export type GetRuleApiResponse = /** status 200 OK */ Rule;
export type GetRuleApiArg = {
    authorization?: string;
    ruleRid: string;
};
export type UpdateRuleApiResponse = /** status 200 OK */ RidResponse;
export type UpdateRuleApiArg = {
    authorization?: string;
    ruleRid: string;
    createOrUpdateRuleRequest: CreateOrUpdateRuleRequest;
};
export type DeleteRuleApiResponse = /** status 200 OK */ Rule;
export type DeleteRuleApiArg = {
    authorization?: string;
    ruleRid: string;
};
export type SearchRulesApiResponse = /** status 200 OK */ RuleSearchResult;
export type SearchRulesApiArg = {
    authorization?: string;
    ruleSearchRequest: RuleSearchRequest;
};
export type GetWorkflowsApiResponse = /** status 200 OK */ Workflow[];
export type GetWorkflowsApiArg = {
    authorization?: string;
    insightRid?: string;
    type?: string;
    status?: string;
};
export type GetWorkflowApiResponse = /** status 200 OK */ Workflow;
export type GetWorkflowApiArg = {
    authorization?: string;
    workflowRid: string;
};
export type GetAvailableActionTypesApiResponse = /** status 200 OK */ string[];
export type GetAvailableActionTypesApiArg = {
    authorization?: string;
    workflowRid: string;
};
export type ExecuteActionApiResponse = unknown;
export type ExecuteActionApiArg = {
    authorization?: string;
    workflowRid: string;
    executeActionRequest: ExecuteActionRequest;
};
export type UserWorkflowStatus = {
    rid?: string;
    workflowRid?: string;
    actorRid?: string;
    status?: string;
};
export type Action = {
    rid?: string;
    workflowRid?: string;
    type?: string;
    status?: string;
    statusDetail?: string;
    note?: string;
    lastUpdateTimestamp?: string;
    actorRid?: string;
};
export type Workflow = {
    rid?: string;
    insightRid?: string;
    type?: string;
    status?: string;
    /** Indicates whether the Workflow is global or user-scoped. */
    workflowScope?: string;
    /** User workflow statuses - only applicable for user-scoped workflows. */
    userWorkflowStatuses?: UserWorkflowStatus[];
    actionHistory?: Action[];
};
export type Insight = {
    rid?: string;
    type?: string;
    scopeResourceType?: string;
    scopeResourceRid?: string;
    /** This field is only applicable when the scopeResourceType is STAFF. It's needed to distinguish between insights across multiple facilities because an individual's staff rid is the same across all facilities in an enterprise. */
    staffFacilityRid?: string;
    status?: string;
    value?: string;
    intervalType?: string;
    intervalStartDate?: string;
    intervalEndDate?: string;
    creationTimestamp?: string;
    supplementaryData?: {
        [key: string]: object;
    };
    workflows?: Workflow[];
};
export type RidResponse = {
    rid?: string;
};
export type InsightSearchResult = {
    insights?: Insight[];
    pageSize?: number;
    numPages?: number;
    numResults?: number;
};
export type InsightSearchRequest = {
    types?: string[];
    scopeResourceTypes?: string[];
    scopeResourceRids?: string[];
    staffFacilityRids?: string[];
    statuses?: string[];
    values?: string[];
    intervalTypes?: string[];
    /** The Insight has a creationTimestamp greater than or equal to this timestamp (epoch milliseconds) */
    creationFromTimestamp?: string;
    /** The Insight has a creationTimestamp less than or equal to this timestamp (epoch milliseconds) */
    creationToTimestamp?: string;
    /** The Insight has an intervalStartDate greater than or equal to this date (YYYY-MM-DD) */
    searchStartFromDate?: string;
    /** The Insight has an intervalStartDate less than or equal to this date (YYYY-MM-DD) */
    searchStartToDate?: string;
    /** The Insight has an intervalEndDate greater than or equal to this date (YYYY-MM-DD) */
    searchEndFromDate?: string;
    /** The Insight has an intervalEndDate less than or equal to this date (YYYY-MM-DD) */
    searchEndToDate?: string;
    pageSize?: number;
    pageNumber?: number;
};
export type CreateOrUpdateRuleRequest = {
    insightType?: string;
    evaluationPeriodInMs?: number;
    insightIntervalType?: string;
    trsQueryDefinitionName?: string;
    trsQueryParameterValues?: object;
};
export type Rule = {
    rid?: string;
    insightType?: string;
    evaluationPeriodInMs?: number;
    insightIntervalType?: string;
    trsQueryDefinitionName?: string;
    trsQueryParameterValues?: object;
};
export type RuleSearchResult = {
    rules?: Rule[];
    pageSize?: number;
    numPages?: number;
    numResults?: number;
};
export type RuleSearchRequest = {
    insightTypes?: string[];
    trsQueryDefinitionNames?: string[];
    pageSize?: number;
    pageNumber?: number;
};
export type ExecuteActionRequest = {
    type?: string;
    details?: string;
    note?: string;
};
export const {
    useGetInsightQuery,
    useLazyGetInsightQuery,
    useResetInsightMutation,
    useSearchInsightsQuery,
    useLazySearchInsightsQuery,
    useCreateRuleMutation,
    useGetRuleQuery,
    useLazyGetRuleQuery,
    useUpdateRuleMutation,
    useDeleteRuleMutation,
    useSearchRulesMutation,
    useGetWorkflowsQuery,
    useLazyGetWorkflowsQuery,
    useGetWorkflowQuery,
    useLazyGetWorkflowQuery,
    useGetAvailableActionTypesQuery,
    useLazyGetAvailableActionTypesQuery,
    useExecuteActionMutation,
} = injectedRtkApi;
