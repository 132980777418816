import { sageBaseGamificationApi as api } from './sageBaseGamificationApi';

export const addTagTypes = ['recognitions'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getRecognitions: build.query<GetRecognitionsApiResponse, GetRecognitionsApiArg>({
                query: (queryArg) => ({
                    url: `/recognitions`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        recipientRid: queryArg.recipientRid,
                        facilityRid: queryArg.facilityRid,
                        seenByRecipient: queryArg.seenByRecipient,
                    },
                }),
                providesTags: ['recognitions'],
            }),
            sendRecognition: build.mutation<SendRecognitionApiResponse, SendRecognitionApiArg>({
                query: (queryArg) => ({
                    url: `/recognitions`,
                    method: 'POST',
                    body: queryArg.sendRecognitionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['recognitions'],
            }),
            searchRecognitions: build.mutation<
                SearchRecognitionsApiResponse,
                SearchRecognitionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/recognitions/search`,
                    method: 'POST',
                    body: queryArg.recognitionSearchRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['recognitions'],
            }),
            getRecognition: build.query<GetRecognitionApiResponse, GetRecognitionApiArg>({
                query: (queryArg) => ({
                    url: `/recognitions/${queryArg.recognitionRid}`,
                    headers: { Authorization: queryArg?.authorization },
                }),
                providesTags: ['recognitions'],
            }),
            updateRecognition: build.mutation<
                UpdateRecognitionApiResponse,
                UpdateRecognitionApiArg
            >({
                query: (queryArg) => ({
                    url: `/recognitions/${queryArg.recognitionRid}`,
                    method: 'PUT',
                    body: queryArg.updateRecognitionRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
                invalidatesTags: ['recognitions'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageGamificationApi };
export type GetRecognitionsApiResponse = /** status 200 OK */ Recognition[];
export type GetRecognitionsApiArg = {
    authorization?: string;
    recipientRid: string;
    facilityRid: string;
    seenByRecipient?: boolean;
};
export type SendRecognitionApiResponse = /** status 200 OK */ {
    rid?: string;
};
export type SendRecognitionApiArg = {
    authorization?: string;
    sendRecognitionRequest: SendRecognitionRequest;
};
export type SearchRecognitionsApiResponse = /** status 200 OK */ SearchRecognitionsResult;
export type SearchRecognitionsApiArg = {
    authorization?: string;
    recognitionSearchRequest: SearchRecognitionsRequest;
};
export type GetRecognitionApiResponse = /** status 200 OK */ Recognition;
export type GetRecognitionApiArg = {
    authorization?: string;
    recognitionRid: string;
};
export type UpdateRecognitionApiResponse = /** status 200 OK */ RidResponse;
export type UpdateRecognitionApiArg = {
    authorization?: string;
    recognitionRid: string;
    updateRecognitionRequest: UpdateRecognitionRequest;
};
export type Recognition = {
    rid?: string;
    type?: string;
    senderRid?: string;
    recipientRid?: string;
    facilityRid?: string;
    seenByRecipient?: boolean;
    reason?: string;
    /** Optional message from the sender to the recipient */
    message?: string;
    additionalProperties?: {
        [key: string]: object;
    };
    creationTimestamp?: string;
    seenTimestamp?: string;
};
export type SendRecognitionRequest = {
    type: string;
    senderRid?: string;
    recipientRid: string;
    facilityRid: string;
    reason?: string;
    /** Optional message from the sender to the recipient */
    message?: string;
    additionalProperties?: {
        [key: string]: object;
    };
};
export type SearchRecognitionsResult = {
    recognitions?: Recognition[];
    pageSize?: number;
    numPages?: number;
    numResults?: number;
};
export type SearchRecognitionsRequest = {
    recipientRids?: string[];
    facilityRids?: string[];
    /** Both seen and unseen are returned if this is not specified */
    seenByRecipient?: boolean;
    pageSize?: number;
    pageNumber?: number;
};
export type RidResponse = {
    rid?: string;
};
export type UpdateRecognitionRequest = {
    seenByRecipient?: boolean;
};
export const {
    useGetRecognitionsQuery,
    useLazyGetRecognitionsQuery,
    useSendRecognitionMutation,
    useSearchRecognitionsMutation,
    useGetRecognitionQuery,
    useLazyGetRecognitionQuery,
    useUpdateRecognitionMutation,
} = injectedRtkApi;
