import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Icon, Stack, Typography } from '@mui/material';

import RadioGroupOptions from '../fragments/RadioGroupOptions';
import Resident from '../fragments/Resident';
import SearchInput from '../fragments/SearchInput';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import sortBy from 'lodash/sortBy';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { selectEnterpriseId, selectFacilityId, selectUserCareZoneIds } from 'src/store/configSlice';
import { useUnitsSearchQuery, Unit, Resident as ResidentType } from 'src/store/sageAdminApi';

interface SearchResidentDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
    onNext: (props?: {
        analyticsPrefix?: string;
        residentId?: string;
        resident?: ResidentType;
    }) => void;
    sortCriteria?: string[];
}

function SearchResidentDrawer(props: SearchResidentDrawerProps) {
    const {
        analyticsPrefix,
        onNext,
        title = 'Search for Resident',
        sortCriteria = ['firstName', 'lastName'],
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const careZoneIds = useSelector(selectUserCareZoneIds);
    const enterpriseId = useSelector(selectEnterpriseId);
    const facilityId = useSelector(selectFacilityId);

    const [selectedResidentId, setSelectedResidentId] = useState<string | undefined>(undefined);

    const { data: unitSearchResults, isLoading } = useUnitsSearchQuery(
        {
            unitSearchRequest: {
                careZoneIds,
                facilityIds: [facilityId],
                enterpriseIds: [enterpriseId],
                pageSize: 1_000,
            },
        },
        { skip: !facilityId || !enterpriseId }
    );

    const residentsInCareZone = useMemo(() => {
        const residentsWithUnit =
            unitSearchResults?.units
                ?.reduce?.((acc, unit) => {
                    if (unit.currentOccupants) {
                        const occupantsWithUnit = unit.currentOccupants.map((occupant) => ({
                            ...occupant,
                            unit,
                        }));
                        return [...acc, ...occupantsWithUnit];
                    } else {
                        return acc;
                    }
                }, [] as (Unit & ResidentType)[])
                .filter((resident) => !resident.isOnHold && !resident.isArchived) || [];

        return sortBy(residentsWithUnit, sortCriteria);
    }, [sortCriteria, unitSearchResults?.units]);

    const [searchTerm, setSearchTerm] = useState('');

    const filteredResidents = useMemo(() => {
        if (!!searchTerm) {
            return residentsInCareZone?.filter((resident) =>
                `${resident.firstName} ${resident.middleName} ${resident.lastName}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
        } else {
            return residentsInCareZone;
        }
    }, [residentsInCareZone, searchTerm]);

    const handleSelectResident = useCallback(() => {
        if (!selectedResidentId) {
            return;
        }

        trackEvent('Resident Selected', {
            context: analyticsPrefix,
            residentId: selectedResidentId,
        });

        onNext({
            analyticsPrefix,
            residentId: selectedResidentId,
            resident: filteredResidents?.find((r) => r.id === selectedResidentId),
        });
    }, [analyticsPrefix, filteredResidents, onNext, selectedResidentId, trackEvent]);

    return (
        <>
            <DrawerTitle title={title}>
                <SearchInput
                    searchValue={searchTerm}
                    onSearchChange={setSearchTerm}
                    placeholder="Search for a resident"
                />
            </DrawerTitle>
            <DrawerBody>
                {!isLoading && !(filteredResidents?.length > 0) && (
                    <Stack
                        sx={{
                            gap: 3,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Icon color="info" component="div">
                            <FontAwesomeIcon icon={faSearch} />
                        </Icon>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                width: 200,
                            }}
                        >
                            No residents found
                        </Typography>
                    </Stack>
                )}

                <RadioGroupOptions
                    radioGroupProps={{ 'aria-label': 'resident' }}
                    options={filteredResidents?.map((resident) => ({
                        value: resident.id!,
                        label: <Resident resident={resident} showCareType />,
                    }))}
                    onSelectOption={setSelectedResidentId}
                    selectedOption={selectedResidentId}
                />
            </DrawerBody>
            <DrawerFooter>
                <LoadingButton
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleSelectResident}
                    disabled={!selectedResidentId || isLoading}
                    loading={isLoading}
                    loadingIndicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
                >
                    Continue
                </LoadingButton>
            </DrawerFooter>
        </>
    );
}

export default SearchResidentDrawer;
