import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { datadogLogs } from '@datadog/browser-logs';

import { selectFacilityId, selectStaffMemberId } from 'src/store/configSlice';
import { selectPinnedResidentIds, setPinnedResidentIds } from 'src/store/globalUISlice';
import { useUpdateStaffFacilityPreferencesMutation } from 'src/store/sageAdminApi';

export const DebouncePeriodMs = 10_000;

function usePinnedResidentIds() {
    const dispatch = useDispatch();
    const facilityId = useSelector(selectFacilityId);
    const staffMemberId = useSelector(selectStaffMemberId);
    const pinnedResidentIdsObject = useSelector(selectPinnedResidentIds);

    const [updatePreferences, { isLoading: isUpdatingPreferences }] =
        useUpdateStaffFacilityPreferencesMutation();

    const pinnedResidentIds = useMemo(() => {
        if (!pinnedResidentIdsObject) {
            return new Set<string>();
        }

        // Convert the object to a set of resident ids
        return Object.keys(pinnedResidentIdsObject).reduce((residentIdsSet, residentId) => {
            if (pinnedResidentIdsObject[residentId]) {
                residentIdsSet.add(residentId);
            }
            return residentIdsSet;
        }, new Set<string>());
    }, [pinnedResidentIdsObject]);

    const updateServerState = async (residentIdsArray: string[]) => {
        try {
            await updatePreferences({
                staffMemberId,
                facilityId,
                updateStaffFacilityPreferencesRequest: {
                    pinnedResidentIds: residentIdsArray,
                },
            }).unwrap();
        } catch (error: any) {
            datadogLogs.logger.error('Error updating pinned resident ids', error);
        }
    };

    const addToPinned = (residentId: string) => {
        if (residentId && !pinnedResidentIds?.has(residentId)) {
            dispatch(setPinnedResidentIds({ ...pinnedResidentIdsObject, [residentId]: true }));
        }
    };

    const addAllToPinned = (residentIds: Set<string>) => {
        if (residentIds && residentIds.size > 0) {
            const newPinnedResidentIdsSet = new Set<string>(pinnedResidentIds);
            residentIds.forEach((residentId) => {
                newPinnedResidentIdsSet.add(residentId);
            });

            // Convert the set back to an object
            const pinnedResidentIdsObject = Array.from(newPinnedResidentIdsSet).reduce(
                (residentIdsObject, residentId) => ({ ...residentIdsObject, [residentId]: true }),
                {} as Record<string, boolean>
            );

            dispatch(setPinnedResidentIds(pinnedResidentIdsObject));
        }
    };

    const replaceAllPinned = (residentIds: Set<string>) => {
        if (residentIds) {
            const newPinnedResidentIds = {} as Record<string, boolean>;
            residentIds.forEach((residentId) => {
                newPinnedResidentIds[residentId] = true;
            });
            dispatch(setPinnedResidentIds(newPinnedResidentIds));
            updateServerState(Array.from(residentIds));
        }
    };

    const removeFromPinned = (residentId: string) => {
        if (residentId && pinnedResidentIds?.has(residentId)) {
            dispatch(
                setPinnedResidentIds({
                    ...pinnedResidentIdsObject,
                    [residentId]: false,
                })
            );
        }
    };

    const togglePinnedResidentId = (residentId: string) => {
        if (pinnedResidentIds?.has(residentId)) {
            removeFromPinned(residentId);
        } else {
            addToPinned(residentId);
        }
    };

    return {
        pinnedResidentIds,
        numberOfPinnedResidents: pinnedResidentIds.size,
        addToPinned,
        addAllToPinned,
        replaceAllPinned,
        removeFromPinned,
        togglePinnedResidentId,
        isUpdatingPreferences,
        updateServerState,
    };
}

export default usePinnedResidentIds;
