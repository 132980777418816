import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useMemo } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useSelector } from 'react-redux';

import sortBy from 'lodash/sortBy';
import { CheckInStatuses } from 'src/constants/CheckIn';
import { selectEnterpriseId, selectFacility } from 'src/store/configSlice';
import { GetCheckInsApiArg, useGetCheckInsQuery } from 'src/store/sageAdminApi';

export const DateFormat = 'yyyy-MM-dd';

interface UseCheckInsProps {
    date?: string;
    isOnHold?: boolean;
    pollingInterval?: number;
    status?: string;
    unitId?: string;
}

function useCheckIns(props: UseCheckInsProps = {}) {
    const {
        date = format(new Date(), DateFormat),
        isOnHold = false,
        pollingInterval = 0,
        status,
        unitId,
    } = props;

    const browserTabIsVisible = usePageVisibility();

    const enterpriseId = useSelector(selectEnterpriseId);
    const facility = useSelector(selectFacility);

    const isDuringDowntime = useMemo(() => {
        // If a timezone is configured on the facility, check to see if the facility
        // time is between 12am and 4am. Otherwise, check the local time
        let currentHour;
        if (facility?.timezone) {
            currentHour = formatInTimeZone(new Date(), facility.timezone, 'H');
        } else {
            currentHour = format(new Date(), 'H');
        }

        // Currently, this is localized even though we already generate check-ins at 4 am ET
        return parseInt(currentHour) < 4;
    }, [facility]);

    const getCheckInsRequest = useMemo(() => {
        const baseRequestBody: GetCheckInsApiArg = {
            date,
            enterpriseId,
            facilityId: facility.id,
            ...(unitId && { unitId }),
        };

        if (isOnHold) {
            return {
                ...baseRequestBody,
                isOnHold: true,
                status: CheckInStatuses.SkippedOnHold,
            };
        } else {
            return {
                ...baseRequestBody,
                isOnHold: false,
                status,
            };
        }
    }, [date, enterpriseId, facility.id, isOnHold, status, unitId]);

    const { checkIns, isFetching, isLoading, refetch } = useGetCheckInsQuery(getCheckInsRequest, {
        refetchOnFocus: true,
        pollingInterval: browserTabIsVisible ? pollingInterval : 0,
        skip: isDuringDowntime || !enterpriseId || !facility,
        selectFromResult: (results) => ({
            ...results,
            checkIns: sortBy(results?.data || [], ['unit.displayName']),
        }),
    });

    return {
        checkIns,
        isFetching,
        isLoading,
        isDuringDowntime,
        numResults: checkIns?.length,
        refetch,
    };
}

export default useCheckIns;
