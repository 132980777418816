export enum ResuscitationStrategies {
    UNKNOWN = 'UNKNOWN',
    CPR = 'CPR',
    DNR = 'DNR',
}

export const ResuscitationStrategySelectorLabels = {
    [ResuscitationStrategies.UNKNOWN]: 'Not Set',
    [ResuscitationStrategies.CPR]: 'CPR Required',
    [ResuscitationStrategies.DNR]: 'DNR Order',
};

export const ResuscitationStrategyDisplayLabels = {
    [ResuscitationStrategies.UNKNOWN]: 'Not Set',
    [ResuscitationStrategies.CPR]: 'CPR',
    [ResuscitationStrategies.DNR]: 'DNR',
};

export const ResuscitationStrategiesOptions = [
    ResuscitationStrategies.CPR,
    ResuscitationStrategies.DNR,
    ResuscitationStrategies.UNKNOWN,
];
export type ResuscitationStrategyType = keyof typeof ResuscitationStrategies;
