import { useContext, useState } from 'react';

import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import RadioGroupOptions, { Variants } from '../fragments/RadioGroupOptions';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';

import '../../assets/styles/buttonStyles.scss';

const DrawerName = 'Select Room Check-in Drawer';

export const CheckInOption = { id: 'CHECKIN', displayName: 'Check-In button' };

interface SelectRoomCheckinDrawerProps extends DrawerComponentProps {
    options: { id: string; displayName: string }[];
    preSelectedOption?: string;
}

function SelectRoomCheckinDrawer(props: SelectRoomCheckinDrawerProps) {
    const { options, preSelectedOption, onNext, title = 'Select Room' } = props;
    const { palette } = useTheme();
    const { trackEvent } = useContext(AnalyticsContext);
    const [selectedOption, setSelectedOption] = useState(preSelectedOption || '');

    const handleSelectRoom = (selectedRoomId: string) => {
        trackEvent('Room Selected', { room: selectedRoomId, context: DrawerName });
        setSelectedOption(selectedRoomId);
    };

    const handleContinue = () => {
        trackEvent('Continue Button Clicked', { context: DrawerName, room: selectedOption });
        onNext({ room: selectedOption });
    };

    return (
        <>
            <DrawerTitle title={title} />
            <DrawerBody>
                <RadioGroupOptions
                    radioGroupProps={{ 'aria-label': 'room' }}
                    variant={Variants.Outlined}
                    selectedOption={selectedOption}
                    onSelectOption={handleSelectRoom}
                    options={options.map((option) => ({
                        value: option.id,
                        label: (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'grey.900',
                                    }}
                                >
                                    {option.displayName}
                                </Typography>
                                {option.id === CheckInOption.id && (
                                    <FontAwesomeIcon
                                        color={palette.success.main}
                                        icon={faCalendarCheck}
                                        size="lg"
                                    />
                                )}
                            </Box>
                        ),
                    }))}
                />
            </DrawerBody>
            <DrawerFooter>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={!selectedOption}
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectRoomCheckinDrawer;
