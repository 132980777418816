import { fromUnixTime, isPast } from 'date-fns';
import { useEffect, useRef, useCallback } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import { AUTH0_CALLBACK_PATH, authorizationParams } from 'src/constants/Auth0';
import { LogoutDialogTypes } from 'src/pages/LoginPage';
import { setBearerToken } from 'src/store/authSlice';

import '../../assets/styles/containerStyles.scss';

const REFRESH_INTERVAL = 1000 * 60; // 1 minute

const AuthTokenCheck = (props) => {
    const { isProcessingLogin, setHasCheckedClaims } = props;

    const { isLoading, getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const browserTabIsVisible = usePageVisibility();

    const refreshTimer = useRef();
    const lastCheckedTimestamp = useRef();

    const handleLogout = useCallback(
        (logoutDialogType) => {
            logout({
                clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
                logoutParams: {
                    returnTo: `${window.location.origin}/authenticate?logoutReason=${logoutDialogType}`,
                },
            });
        },
        [logout]
    );

    const checkTokenClaims = useCallback(
        async (retriesRemaining = 2) => {
            try {
                const token = await getAccessTokenSilently({ authorizationParams });
                const claims = await getIdTokenClaims();
                lastCheckedTimestamp.current = Date.now();

                if (claims?.exp) {
                    const isExpired = isPast(fromUnixTime(claims.exp));

                    if (isExpired) {
                        handleLogout(LogoutDialogTypes.SessionExpired);
                        dispatch(setBearerToken(null));
                        refreshTimer?.current && clearTimeout(refreshTimer.current);
                    } else {
                        dispatch(setBearerToken(`Bearer ${token}`));

                        refreshTimer?.current && clearTimeout(refreshTimer.current);
                        refreshTimer.current = setTimeout(() => {
                            checkTokenClaims();
                        }, REFRESH_INTERVAL);
                    }
                }
            } catch (error) {
                if (/login required/i.test(error.message)) {
                    if (location.pathname !== '/authenticate') {
                        lastCheckedTimestamp.current = null;
                        navigate(`/authenticate?logoutReason=${LogoutDialogTypes.LoginRequired}`);
                    }
                } else if (retriesRemaining > 0) {
                    /**
                     * Re-check token claims once because of the "Error: Invalid State" that happens
                     * when the two Auth0 libraries are used together
                     * See: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
                     */
                    checkTokenClaims(retriesRemaining - 1);
                } else {
                    datadogRum.addError(error, {
                        message: 'Error getting token or checking token claims on retry',
                        location,
                    });
                    handleLogout(LogoutDialogTypes.TokenError);
                    dispatch(setBearerToken(null));
                    refreshTimer?.current && clearTimeout(refreshTimer.current);
                    lastCheckedTimestamp.current = null;
                }
            } finally {
                setHasCheckedClaims(true);
            }
        },
        [
            dispatch,
            getAccessTokenSilently,
            getIdTokenClaims,
            handleLogout,
            location,
            navigate,
            setHasCheckedClaims,
        ]
    );

    useEffect(() => {
        if (
            browserTabIsVisible &&
            !isLoading &&
            isProcessingLogin !== true &&
            location?.pathname !== AUTH0_CALLBACK_PATH &&
            (!lastCheckedTimestamp.current ||
                lastCheckedTimestamp.current < Date.now() - REFRESH_INTERVAL)
        ) {
            checkTokenClaims();
        }

        return () => {
            refreshTimer?.current && clearTimeout(refreshTimer.current);
        };
    }, [browserTabIsVisible, checkTokenClaims, isLoading, isProcessingLogin, location?.pathname]);

    return <div />;
};

export default AuthTokenCheck;
