export enum HospiceStatus {
    HOSPICE_CARE = 'HOSPICE_CARE',
    STANDARD_CARE = 'STANDARD_CARE',
    UNKNOWN = 'UNKNOWN',
}
export const HospiceStatusLabels: Record<HospiceStatus, string> = {
    [HospiceStatus.UNKNOWN]: 'Not Set',
    [HospiceStatus.HOSPICE_CARE]: 'Yes',
    [HospiceStatus.STANDARD_CARE]: 'No',
};

export const HospiceStatusOptions = [
    HospiceStatus.HOSPICE_CARE,
    HospiceStatus.STANDARD_CARE,
    HospiceStatus.UNKNOWN,
];
