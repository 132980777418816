const pluralRules = new Intl.PluralRules('en-US');

// From https://2ality.com/2019/12/intl-pluralrules.html#a-simple-tool-function-for-pluralization
export function pluralize(count, singular, plural, includeCount = true) {
    const grammaticalNumber = pluralRules.select(count);
    switch (grammaticalNumber) {
        case 'one':
            return includeCount ? count + ' ' + singular : singular;
        case 'zero':
        case 'other':
            return includeCount ? count + ' ' + plural : plural;
        default:
            throw new Error('Unknown: ' + grammaticalNumber);
    }
}
