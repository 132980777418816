import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps } from '@mui/material/';
import { useTheme } from '@mui/material/styles';

import Resident, { ResidentProps } from '../fragments/Resident';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import useCareZones from 'src/hooks/useCareZones';
import { ResidentWithLatestActivity } from 'src/hooks/useOverdueRoutineChecks';
import usePinnedResidentIds from 'src/hooks/usePinnedResidentIds';
import { validateHospiceStatus } from 'src/utils/HospiceUtils';

interface ResidentCardProps extends ResidentProps {
    containerProps?: BoxProps;
    index?: number;
    hideArrow?: boolean;
    hideBadge?: boolean;
    onResidentClick?: () => void;
    preventDefault?: boolean;
    resident: ResidentWithLatestActivity;
    unitIsCheckedIn?: boolean;
}

export default function ResidentCard(props: ResidentCardProps) {
    const {
        containerProps = {},
        hideArrow = false,
        hideBadge = false,
        index,
        onResidentClick,
        preventDefault = false,
        progress,
        resident,
        showProgress = false,
        unitIsCheckedIn,
    } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const navigate = useNavigate();
    const { palette } = useTheme();

    const { careZones } = useCareZones();
    const { pinnedResidentIds } = usePinnedResidentIds();

    const defaultHandleOnClick = () => {
        navigate(`/residents/resident/${resident?.id}`);
    };

    const handleOnClick = () => {
        trackEvent('Resident Card Clicked', { objectType: ObjectTypes.Card, cardIndex: index });
        if (typeof onResidentClick === 'function') {
            onResidentClick();
        } else if (!preventDefault) {
            defaultHandleOnClick();
        }
    };

    const isPinned = pinnedResidentIds.has(resident.id!);

    return (
        <Box
            {...containerProps}
            onClick={handleOnClick}
            data-dd-action-name="Resident Card"
            data-testid="resident-card"
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
                cursor: 'pointer',
            }}
        >
            <Resident
                resident={resident}
                unit={resident.unit}
                showCareType={(careZones?.length || 0) > 0}
                hospiceStatus={validateHospiceStatus(
                    resident.healthInfo?.hospiceHealthInfo?.hospiceStatus
                )}
                showProgress={showProgress}
                progress={progress}
                // Badge props
                isArchived={hideBadge ? false : resident.isArchived}
                isCheckedIn={hideBadge ? false : unitIsCheckedIn}
                isOnHold={hideBadge ? false : resident.isOnHold}
                isPinned={hideBadge ? false : isPinned}
                badgeSize={isPinned ? 'xs' : undefined}
            />
            {!hideArrow && <FontAwesomeIcon color={palette.primary.main} icon={faArrowRight} />}
        </Box>
    );
}
