import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Alert, Card, Collapse, Stack, TextField, Typography } from '@mui/material';

import RadioGroupOptions from '../fragments/RadioGroupOptions';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import { DoorAlertTimes, DoorAlertTimeLabels } from 'src/constants/DoorAlertTimes';
import { selectFacilityId } from 'src/store/configSlice';
import {
    Door,
    DoorAlarmSchedule,
    useCreateOrUpdateDoorAlarmScheduleMutation,
    useUpdateDoorMutation,
} from 'src/store/sageAdminApi';
import { invertTimeRange, formatTimeForDisplay } from 'src/utils/doorAlarmScheduleUtils';
import { snackActions } from 'src/utils/snackBarTools';

const SevenAm = '07:00';
const SevenPm = '19:00';

interface DoorScheduleDrawerProps extends DrawerComponentProps {
    door: Door;
    doorAlarmSchedule: DoorAlarmSchedule;
}

function DoorScheduleDrawer(props: DoorScheduleDrawerProps) {
    const { door, doorAlarmSchedule, onNext } = props;
    const facilityRid = useSelector(selectFacilityId);
    const { trackEvent } = useContext(AnalyticsContext);

    const [selectedOption, setSelectedOption] = useState(DoorAlertTimes.AlwaysAlert);
    const [startAlertTime, setStartAlertTime] = useState(SevenPm);
    const [endAlertTime, setEndAlertTime] = useState(SevenAm);
    const [showOvernightAlert, setShowOvernightAlert] = useState<boolean>(false);

    const [updateSchedule, { isLoading: isUpdatingSchedule }] =
        useCreateOrUpdateDoorAlarmScheduleMutation();
    const [updateDoor, { isLoading: isUpdatingDoor }] = useUpdateDoorMutation();

    const startAndEndTimesTheSame = startAlertTime === endAlertTime;
    const wholeDayAlertTime = startAlertTime === '00:00' && endAlertTime === '23:59';
    const isLoading = isUpdatingSchedule || isUpdatingDoor;
    const isDisabled = startAndEndTimesTheSame || wholeDayAlertTime || isLoading;

    const hasSchedule = !!doorAlarmSchedule;

    const showCustomFields = selectedOption === DoorAlertTimes.Custom;

    const exclusionTimes = useMemo(() => {
        return invertTimeRange([{ startTime: startAlertTime, endTime: endAlertTime }]);
    }, [endAlertTime, startAlertTime]);

    const handleSelectOption = (value: string) => {
        setSelectedOption(value);

        if (value === DoorAlertTimes.SevenPmToSevenAm) {
            setStartAlertTime(SevenPm);
            setEndAlertTime(SevenAm);
        }
    };

    const handleContinue = async () => {
        trackEvent('Door Schedule Updated', { objectType: ObjectTypes.Button, selectedOption });

        let payload: {
            alwaysAlert: boolean;
            neverAlert: boolean;
            dayOfWeekTimeRangeMap?: Record<string, { startTime: string; endTime: string }[]>;
        } = { alwaysAlert: false, neverAlert: false };
        switch (selectedOption) {
            case DoorAlertTimes.AlwaysAlert:
                payload = { alwaysAlert: true, neverAlert: false };
                break;
            case DoorAlertTimes.NeverAlert:
                payload = { alwaysAlert: false, neverAlert: true };
                break;
            default:
                payload = {
                    alwaysAlert: false,
                    neverAlert: false,
                    dayOfWeekTimeRangeMap: {
                        All: exclusionTimes,
                    },
                };
                break;
        }

        try {
            const updatedScheduleId = await updateSchedule({
                createOrUpdateDoorAlarmScheduleRequest: {
                    ...payload,
                    doorRid: door.rid!,
                },
            }).unwrap();

            // If the door doesn't have a schedule, we need to update it with the
            // new schedule id created above
            if (!hasSchedule && updatedScheduleId?.rid) {
                await updateDoor({
                    doorRid: door.rid,
                    items: {
                        facilityRid: facilityRid,
                        doorAlarmScheduleRid: updatedScheduleId?.rid,
                        // Door name is required in this call
                        doorName: door.name,
                    },
                }).unwrap();
            }
            onNext();
        } catch (error) {
            datadogRum.addError(error, { message: 'Error updating door schedule', door, payload });
            snackActions.error('Error updating door schedule');
        }
    };

    useEffect(() => {
        if (hasSchedule) {
            const { alwaysAlert, neverAlert, dailySchedules } = doorAlarmSchedule;
            const { dayOfWeekTimeRangeMap } = dailySchedules || {};
            if (alwaysAlert) {
                setSelectedOption(DoorAlertTimes.AlwaysAlert);
            } else if (neverAlert) {
                setSelectedOption(DoorAlertTimes.NeverAlert);
            } else if (dayOfWeekTimeRangeMap?.All) {
                const [inclusionRange] = invertTimeRange(dayOfWeekTimeRangeMap.All, true);

                if (inclusionRange.startTime === SevenPm && inclusionRange.endTime === SevenAm) {
                    setSelectedOption(DoorAlertTimes.SevenPmToSevenAm);
                } else {
                    setSelectedOption(DoorAlertTimes.Custom);
                    setStartAlertTime(inclusionRange.startTime);
                    setEndAlertTime(inclusionRange.endTime);
                }
            }
        }
    }, [doorAlarmSchedule, hasSchedule]);

    useEffect(() => {
        if (startAlertTime && endAlertTime) {
            setShowOvernightAlert(startAlertTime > endAlertTime);
        }
    }, [startAlertTime, endAlertTime]);

    return (
        <>
            <DrawerTitle title="Select Alert Hours" />
            <DrawerBody sx={{ gap: 3 }}>
                <RadioGroupOptions
                    radioGroupProps={{ 'aria-label': 'alert hours' }}
                    variant="outlined"
                    selectedOption={selectedOption}
                    onSelectOption={handleSelectOption}
                    options={Object.entries(DoorAlertTimes).map(([key, value]) => ({
                        value: key,
                        label: DoorAlertTimeLabels[value],
                    }))}
                />
                <Collapse in={showCustomFields}>
                    <Stack
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                gap: 2,
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="startTime"
                                label="Start Time"
                                type="time"
                                value={startAlertTime}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setStartAlertTime(value === '' ? '00:00' : value);
                                }}
                            />

                            <TextField
                                fullWidth
                                variant="outlined"
                                id="endTime"
                                label="End Time"
                                type="time"
                                value={endAlertTime}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setEndAlertTime(value === '' ? '00:00' : value);
                                }}
                            />
                        </Stack>

                        {startAndEndTimesTheSame && (
                            <Alert severity="error">Start and end times must be different</Alert>
                        )}

                        {wholeDayAlertTime && (
                            <Alert severity="error">
                                This alert period spans the whole day. Please select Always Alert
                                instead.
                            </Alert>
                        )}

                        <Collapse in={showOvernightAlert}>
                            <Card
                                elevation={0}
                                sx={{
                                    backgroundColor: 'primary.light',
                                    p: 2,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        gap: 2,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    <Stack
                                        sx={{
                                            gap: 0.25,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Overnight Alert
                                        </Typography>
                                        <Typography variant="body2">
                                            Alert hours are from{' '}
                                            {formatTimeForDisplay(startAlertTime, true)} each day to{' '}
                                            {formatTimeForDisplay(endAlertTime, true)} the next day
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Collapse>
                    </Stack>
                </Collapse>
            </DrawerBody>
            <DrawerFooter>
                <LoadingButton
                    color="primary"
                    disableElevation
                    loading={isLoading}
                    disabled={isDisabled}
                    fullWidth
                    onClick={handleContinue}
                    variant="contained"
                >
                    Continue
                </LoadingButton>
            </DrawerFooter>
        </>
    );
}

export default DoorScheduleDrawer;
