import { useRef } from 'react';

import { Box, Skeleton } from '@mui/material';

import SageLogo from 'src/assets/images/sage-icon.svg';
import useImageCache from 'src/hooks/useImageCache';

interface EnterpriseLogoProps {
    enterpriseId: string;
    enterpriseName?: string;
    height?: number;
    width?: number;
}

function EnterpriseLogo(props: EnterpriseLogoProps) {
    const { enterpriseId, enterpriseName, height = 48, width = 48 } = props;

    const imageContainerRef = useRef(null);
    const [image, { isLoading }] = useImageCache(enterpriseId, undefined, {
        imageContainerRef,
        useLocalStorage: true,
    });

    return isLoading ? (
        <Skeleton variant="rounded" animation="wave" width={width} height={height} />
    ) : (
        <Box
            ref={imageContainerRef}
            component="img"
            src={image || SageLogo}
            alt={`${enterpriseName} logo`}
            sx={{
                height: height,
                width: width,
                border: 1,
                borderColor: 'grey.200',
                borderRadius: 2,
                p: 0.5,
                objectFit: 'contain',
            }}
        />
    );
}

export default EnterpriseLogo;
