import { useContext, useEffect } from 'react';
import uaParser from 'ua-parser-js';

import { Box, Card, Link, Stack, Typography } from '@mui/material';

import { AnalyticsContext } from 'src/AnalyticsContext';
import ChromeLogo from 'src/assets/images/browser-icons/chrome-logo.svg?react';
import MicrosoftEdgeLogo from 'src/assets/images/browser-icons/edge-logo.svg?react';
import FirefoxLogo from 'src/assets/images/browser-icons/firefox-logo.svg?react';
import SafariLogo from 'src/assets/images/browser-icons/safari-logo.svg?react';
import SageLogo from 'src/assets/images/sage-logo-on-light.svg?react';

const { browser } = uaParser(navigator.userAgent);

const browserLinks = [
    { name: 'Chrome', logo: ChromeLogo, href: 'https://support.google.com/accounts/answer/61416' },
    {
        name: 'Safari',
        logo: SafariLogo,
        href: 'https://support.apple.com/guide/safari/enable-cookies-ibrw850f6c51/mac',
    },
    {
        name: 'Firefox',
        logo: FirefoxLogo,
        href: 'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them',
    },
    {
        name: 'Microsoft Edge',
        logo: MicrosoftEdgeLogo,
        href: 'https://support.microsoft.com/en-us/microsoft-edge/temporarily-allow-cookies-and-site-data-in-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2',
    },
];

function EnableCookiesPage() {
    const { trackEvent } = useContext(AnalyticsContext);

    useEffect(() => {
        trackEvent('enable-cookies-page.page.view');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                py: 5,
                px: 2.5,
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    mb: 4,
                }}
            >
                <SageLogo height={32} width={120} />
            </Box>
            <Stack
                sx={{
                    width: '100%',
                    gap: 2.5,
                }}
            >
                <Typography variant="h2">Enable Cookies to Use Sage</Typography>
                <Typography variant="h4">
                    Your cookies are blocked or not supported by your browser. You must enable
                    cookies to log into Sage.
                    <br />
                    <br />
                    Tap the corresponding browser below for instructions on how to enable cookies.
                </Typography>
                <Typography>
                    <b>Your Browser</b>: {browser.name} ({browser.version})
                </Typography>
                <Box
                    sx={{
                        mx: 'auto',
                    }}
                >
                    <Stack
                        sx={{
                            gap: 2,
                            mx: 'auto',
                        }}
                    >
                        {browserLinks.map(({ name, logo: BrowserLogo, href }) => (
                            <Link
                                key={name}
                                href={href}
                                underline="hover"
                                sx={{
                                    color: 'inherit',
                                }}
                            >
                                <Card sx={{ borderRadius: 2 }}>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            gap: 1.5,
                                            alignItems: 'center',
                                            py: 2.5,
                                            px: 3,
                                        }}
                                    >
                                        <BrowserLogo height={32} width={32} />
                                        <Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Link>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
}

export default EnableCookiesPage;
