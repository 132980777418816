import { matchIsValidTel } from 'mui-tel-input';

import { SupportedCountryCodes } from 'src/constants/SupportedCountryCodes';

const environment = import.meta.env.VITE_ENV;

interface ValidationRuleOptions {
    isRequired?: boolean;
    requiredErrorMessage?: string;
    invalidErrorMessage?: string;
}

const validateIsTenDigitPhoneNumber = (value: string, countryCode = '+1') => {
    // Strip out country code
    const noCountryCode = value.replace(countryCode, '');
    const onlyDigits = noCountryCode.replace(/\D/g, '');

    return onlyDigits.length === 10;
};

export const getPhoneNumberValidationRules = (options?: ValidationRuleOptions) => {
    const {
        isRequired = false,
        requiredErrorMessage = 'Phone number is required',
        invalidErrorMessage = 'Phone number is invalid',
    } = options || {};
    if (isRequired) {
        return {
            validate: (value: string) => {
                if (!value || value === '+1') {
                    return requiredErrorMessage;
                } else if (environment !== 'production') {
                    return validateIsTenDigitPhoneNumber(value) ? true : invalidErrorMessage;
                } else {
                    return matchIsValidTel(value, { onlyCountries: SupportedCountryCodes })
                        ? true
                        : invalidErrorMessage;
                }
            },
        };
    } else {
        if (environment !== 'production') {
            return {
                validate: (value: string) =>
                    !value || // empty string is allowed
                    value === '+1' || // MuiTelInput default value
                    validateIsTenDigitPhoneNumber(value)
                        ? true
                        : invalidErrorMessage,
            };
        } else {
            return {
                validate: (value: string) =>
                    !value || // empty string is allowed
                    value === '+1' || // MuiTelInput default value
                    matchIsValidTel(value, { onlyCountries: SupportedCountryCodes })
                        ? true
                        : invalidErrorMessage,
            };
        }
    }
};

// Limit the length of the phone number to 11 digits (10 digits + 1 country code)
export const limitPhoneNumberLength = (value: string, maxLength = 11) => {
    const onlyDigits = value.replace(/\D/g, '');

    if (onlyDigits.length > maxLength) {
        return value.slice(0, value.length - (onlyDigits.length - maxLength));
    }
    return value;
};
