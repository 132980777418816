import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { ImageSizes } from '../constants/ImageSizes';

export const getImageSize = (variant = ImageSizes.Medium) => {
    switch (variant) {
        case ImageSizes.XSmall:
            return '24px';
        case ImageSizes.Small:
            return '32px';
        case ImageSizes.Large:
            return '64px';
        case ImageSizes.XLarge:
            return '80px';
        case ImageSizes.Medium:
        default:
            return '48px';
    }
};

export const getIconSize = (variant = ImageSizes.Medium): SizeProp | undefined => {
    switch (variant) {
        case ImageSizes.XSmall:
        case ImageSizes.Small:
            return 'sm';
        case ImageSizes.XLarge:
            return 'xl';
        case ImageSizes.Medium:
        case ImageSizes.Large:
        default:
            return undefined;
    }
};
