export const NoneOption = 'NONE';

export const CareTypes = {
    AssistedLiving: 'ASSISTED_LIVING',
    IndependentLiving: 'INDEPENDENT_LIVING',
    MemoryCare: 'MEMORY_CARE',
};

export const CareTypeLabels = {
    [CareTypes.AssistedLiving]: 'Assisted Living',
    [CareTypes.IndependentLiving]: 'Independent Living',
    [CareTypes.MemoryCare]: 'Memory Care',
    [NoneOption]: 'None',
};

export const CareTypeLabelsAbbreviated = {
    [CareTypes.AssistedLiving]: 'AL',
    [CareTypes.IndependentLiving]: 'IL',
    [CareTypes.MemoryCare]: 'MC',
    [NoneOption]: 'None',
};

/**
 * (2022-12-07) Note: currently, wards use the same types as CareTypes,
 * but this will change in the future once we allow for facilities
 * to customize their own care types.
 */
export const WardLabels = CareTypeLabels;
