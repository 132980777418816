import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';

import LastPressTimeDisplay from '../fragments/LastPressTimeDisplay';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import PendantButtonIcon from 'src/assets/images/device-icons/pendant-button-icon.svg?react';
import useUserPermissions from 'src/hooks/useUserPermissions';

import '../../assets/styles/buttonStyles.scss';

const DrawerName = 'Resident Pendants Drawer';

interface ResidentPendantsDrawerProps extends DrawerComponentProps {
    pendants: Array<{ id: string; device: { lastPressTimestamp: number } }>;
}

function ResidentPendantsDrawer(props: ResidentPendantsDrawerProps) {
    const { onClose, title = '', pendants } = props;
    const { isInManagementGroup } = useUserPermissions();
    const { trackEvent } = useContext(AnalyticsContext);
    const navigate = useNavigate();

    const handleCancel = () => {
        trackEvent('Cancel Button Clicked', { context: DrawerName });
        onClose();
    };

    const handlePendantClick = (id: string) => {
        trackEvent('Resident Pendant Clicked', { context: DrawerName, allocationId: id });
        if (isInManagementGroup) {
            onClose();
            navigate(`/devices/allocation/${id}`);
        }
    };

    return (
        <>
            <DrawerTitle title={title} />
            <DrawerBody sx={{ gap: 2, pb: 2 }}>
                {pendants.map((pendant, index) => (
                    <Box
                        key={index}
                        onClick={() => handlePendantClick(pendant.id)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: 2,
                            py: 1,
                            boxShadow: 1,
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <PendantButtonIcon width="2rem" />
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'grey.900',
                                }}
                            >
                                Resident Pendant
                            </Typography>
                        </Stack>
                        <LastPressTimeDisplay
                            lastPressTimestamp={pendant.device?.lastPressTimestamp}
                        />
                    </Box>
                ))}
            </DrawerBody>
            <DrawerFooter>
                <Button
                    size="large"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </DrawerFooter>
        </>
    );
}

export default ResidentPendantsDrawer;
