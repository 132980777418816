import { useMemo } from 'react';

import Chip from '@mui/material/Chip';

import {
    HospiceStatusHospiceCareChip,
    HospiceStatusStandardCareChip,
    HospiceStatusUnknownChip,
} from 'src/components/residents/styles/ResidentChipParameters';
import { HospiceStatus, HospiceStatusLabels } from 'src/constants/HospiceStatus';

export interface HospiceChipProps {
    hospiceStatus: HospiceStatus;
    hideLabel?: boolean;
}

const HospiceChip = (props: HospiceChipProps) => {
    const { hospiceStatus, hideLabel = false } = props;

    const hospiceChipProps = useMemo(() => {
        const label = !hideLabel ? HospiceStatusLabels[hospiceStatus] : undefined;
        const sxProp = hideLabel ? { '.MuiChip-iconMedium': { marginRight: '-1rem' } } : undefined;
        switch (hospiceStatus) {
            case HospiceStatus.HOSPICE_CARE:
                return {
                    ...HospiceStatusHospiceCareChip,
                    label,
                    sx: sxProp,
                };
            case HospiceStatus.STANDARD_CARE:
                return {
                    ...HospiceStatusStandardCareChip,
                    label,
                    sx: sxProp,
                };
            default:
                return {
                    ...HospiceStatusUnknownChip,
                    label,
                    sx: sxProp,
                };
        }
    }, [hospiceStatus, hideLabel]);

    return <Chip {...hospiceChipProps} />;
};

export default HospiceChip;
