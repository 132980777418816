/**
 * Intentially left empty. Endpoints will be injected later as needed.
 * See https://redux-toolkit.js.org/rtk-query/usage/code-generation#usage
 */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from './index';

/**
 * Configures the admin api to be used for generating endpoints.
 */
export const sageBaseAdminApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_SAGE_ADMIN_BASE_URL,
        timeout: 30_000,

        // See https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
        prepareHeaders: (headers, { getState, endpoint }) => {
            const rootState: RootState = getState() as RootState;

            const bearerToken = rootState.authConfig?.bearerToken;
            if (bearerToken) {
                const authHeader = (bearerToken as String).toLowerCase().startsWith('bearer')
                    ? bearerToken
                    : `Bearer ${bearerToken}`;

                headers.set('Authorization', authHeader);
            }

            const profileRid = rootState.appConfig?.staffMemberId;
            if (profileRid) {
                headers.set('x-sage-profile-rid', profileRid);
            }

            if (endpoint === 'uploadImageForResource') {
                headers.set('Content-Type', 'application/octet-stream');
            }

            return headers;
        },
    }),
    endpoints: () => ({}),
    tagTypes: [
        'careZones',
        'checkIns',
        'companyPhones',
        'devices',
        'doors',
        'doorAlarms',
        'enterprises',
        'facilities',
        'features',
        'groups',
        'media',
        'residents',
        'schedules',
        'staff',
        'tasks',
        'units',
    ],
    reducerPath: 'sageAdminApi',
});
