import { useContext, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    faCircleCheck,
    faCircleXmark,
    faCircleQuestion,
    faSync,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { faMessageExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    Divider,
    Stack,
    Typography,
    useTheme,
    Link as ExternalLink,
    Alert,
} from '@mui/material';

import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import { PhoneSubscriptionStatuses } from 'src/constants/PhoneSubscriptionStatuses';
import { selectFacility } from 'src/store/configSlice';
import { useGetPhoneNumberSubscriptionsQuery } from 'src/store/sageMessageOrchestrationApi';
import { formatPhoneNumber } from 'src/utils/formUtils';

import '../../assets/styles/buttonStyles.scss';

const trackingContext = 'Phone Subscription Drawer';

interface PhoneSubscriptionDrawerProps extends DrawerComponentProps {
    title: string;
    phoneNumber: string;
    displayName: string;
}

function PhoneSubscriptionDrawer(props: PhoneSubscriptionDrawerProps) {
    const { onNext, title = 'Notifications', phoneNumber, displayName } = props;

    const facility = useSelector(selectFacility);
    const { id, emergencyNotificationNumber, taskNotificationNumber } = facility;
    const { palette } = useTheme();

    const { trackEvent } = useContext(AnalyticsContext);

    const {
        data: phoneNumberSubscriptions,
        isLoading: isLoadingSubscriptions,
        isError,
    } = useGetPhoneNumberSubscriptionsQuery(
        {
            facilityRid: id,
            getPhoneNumberSubscriptionsRequest: {
                recipientPhoneNumbers: [phoneNumber],
                senderPhoneNumbers: [emergencyNotificationNumber!, taskNotificationNumber!],
            },
        },
        {
            skip: !id || !phoneNumber || !emergencyNotificationNumber || !taskNotificationNumber,
            refetchOnFocus: true,
        }
    );

    const { alertSubscriptionStatus, taskSubscriptionStatus } = useMemo(() => {
        if (!phoneNumberSubscriptions) {
            return {};
        }

        const alertSubscription = phoneNumberSubscriptions.find(
            (subscription) => subscription.senderPhoneNumber === emergencyNotificationNumber
        );

        const taskSubscription = phoneNumberSubscriptions.find(
            (subscription) => subscription.senderPhoneNumber === taskNotificationNumber
        );

        return {
            alertSubscriptionStatus: alertSubscription?.status,
            taskSubscriptionStatus: taskSubscription?.status,
        };
    }, [emergencyNotificationNumber, phoneNumberSubscriptions, taskNotificationNumber]);

    const isSubscribedToAlerts = alertSubscriptionStatus === PhoneSubscriptionStatuses.Subscribed;
    const isSubscribedToTasks = taskSubscriptionStatus === PhoneSubscriptionStatuses.Subscribed;

    const subscriptionIcon = useCallback(
        (isLoading: boolean, error: boolean, status: string) => {
            if (isLoading) {
                return { icon: faSync, color: palette.grey[700] };
            } else if (status === PhoneSubscriptionStatuses.Subscribed) {
                return { icon: faCircleCheck, color: palette.success.main };
            } else if (status === PhoneSubscriptionStatuses.Unsubscribed) {
                return { icon: faCircleXmark, color: palette.error.main };
            } else if (error) {
                return { icon: faTriangleExclamation, color: palette.grey[700] };
            }
            return { icon: faCircleQuestion, color: palette.grey[300] };
        },
        [palette]
    );

    const alertIconAndColor = subscriptionIcon(
        isLoadingSubscriptions,
        isError,
        alertSubscriptionStatus!
    );

    const taskIconAndColor = subscriptionIcon(
        isLoadingSubscriptions,
        isError,
        taskSubscriptionStatus!
    );

    const handleContinue = () => {
        const buttonName = isSubscribedToAlerts && isSubscribedToTasks ? 'Continue' : 'Skip';
        trackEvent(`${buttonName} Button Clicked`, {
            objectType: ObjectTypes.Button,
            context: trackingContext,
            phoneNumber,
        });

        onNext();
    };

    return (
        <>
            <DrawerTitle title={title} />
            <DrawerBody sx={{ gap: 2 }}>
                <Stack
                    sx={{
                        gap: 0.5,
                    }}
                >
                    <Typography variant="body2">
                        {isSubscribedToAlerts && isSubscribedToTasks
                            ? 'All notifications enabled on:'
                            : "It looks like some notifications aren't enabled on:"}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >{`${displayName} (${formatPhoneNumber(phoneNumber)})`}</Typography>
                </Stack>

                {(!isSubscribedToAlerts || !isSubscribedToTasks) && (
                    <Alert
                        severity="error"
                        sx={{ borderRadius: 2, fontWeight: 'bold' }}
                        icon={false}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Tap &apos;Subscribe&apos; below to start receiving messages.
                        </Typography>
                    </Alert>
                )}

                <Card sx={{ boxShadow: 0, border: '1px solid', borderColor: 'purple.100' }}>
                    <Stack divider={<Divider sx={{ bgColor: 'purple.100' }} />}>
                        {/* Alerts */}
                        <Stack
                            direction="row"
                            sx={{
                                gap: 1,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: 1.5,
                                py: 2.5,
                            }}
                        >
                            <Stack
                                sx={{
                                    gap: 1,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'center',
                                        gap: 0.5,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMessageExclamation} size="sm" />
                                    <Typography variant="body2">Alerts</Typography>
                                </Stack>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {formatPhoneNumber(emergencyNotificationNumber)}
                                </Typography>
                                <Stack
                                    direction="row"
                                    sx={{
                                        gap: 0.5,
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={alertIconAndColor.icon}
                                        color={alertIconAndColor?.color}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: alertIconAndColor.color,
                                        }}
                                    >
                                        {isSubscribedToAlerts
                                            ? 'Receiving messages'
                                            : 'Not receiving messages'}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Button
                                component={ExternalLink}
                                href={`sms:${emergencyNotificationNumber}?body=START`}
                                variant="contained"
                                size="extraSmall"
                                disableElevation
                                disabled={isSubscribedToAlerts}
                            >
                                Subscribe
                            </Button>
                        </Stack>

                        {/* Tasks */}
                        <Stack
                            direction="row"
                            sx={{
                                gap: 1,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: 1.5,
                                py: 2.5,
                            }}
                        >
                            <Stack
                                sx={{
                                    gap: 1,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'center',
                                        gap: 0.5,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMessageExclamation} size="sm" />
                                    <Typography variant="body2">Tasks</Typography>
                                </Stack>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {formatPhoneNumber(emergencyNotificationNumber)}
                                </Typography>
                                <Stack
                                    direction="row"
                                    sx={{
                                        gap: 0.5,
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={taskIconAndColor.icon}
                                        color={taskIconAndColor?.color}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: taskIconAndColor.color,
                                        }}
                                    >
                                        {isSubscribedToTasks
                                            ? 'Receiving messages'
                                            : 'Not receiving messages'}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Button
                                component={ExternalLink}
                                href={`sms:${taskNotificationNumber}?body=START`}
                                variant="contained"
                                size="extraSmall"
                                disableElevation
                                disabled={isSubscribedToTasks}
                            >
                                Subscribe
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
            </DrawerBody>
            <DrawerFooter>
                <Button variant="outlined" onClick={handleContinue}>
                    {isSubscribedToAlerts && isSubscribedToTasks ? 'Continue' : 'Skip'}
                </Button>
            </DrawerFooter>
        </>
    );
}

export default PhoneSubscriptionDrawer;
