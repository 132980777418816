import { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

import { Box, Stack, SxProps, Theme } from '@mui/material';

import './styles/Drawer.styles.scss';

interface DrawerBodyProps {
    allowShrink?: boolean;
    sx?: SxProps<Theme>;
    children?: React.ReactNode;
}

function DrawerBody(props: DrawerBodyProps) {
    const { allowShrink = false, children, sx = {} } = props;
    const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();

    const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (allowShrink) {
            setMaxHeight(undefined);
            return;
        }

        if (!maxHeight || containerHeight > maxHeight) {
            setMaxHeight(containerHeight);
        }
    }, [maxHeight, containerHeight, allowShrink]);

    return (
        <Box
            ref={containerRef}
            sx={{
                gridArea: 'body',
                overflowY: 'auto',
                minHeight: maxHeight,
            }}
        >
            <Stack
                sx={{
                    // Default props
                    pt: 2.5,
                    px: 2.5,
                    // Custom props
                    ...sx,
                }}
            >
                {children}
            </Stack>
        </Box>
    );
}

export default DrawerBody;
