const Groups = {
    Activities: 'Activities',
    CareStaff: 'Care staff',
    Culinary: 'Culinary',
    Housekeeping: 'Housekeeping',
    Maintenance: 'Maintenance',
    Management: 'Management',
    NurseMedTech: 'Nurse/MedTech',
    SageSupport: 'Sage Support',
    Transportation: 'Transportation',
};

export default Groups;
