import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useRef } from 'react';

import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import {
    faHand,
    faBoxArchive,
    faHeart,
    faXmark,
    faExclamationTriangle,
    faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Avatar, Badge, BadgeOrigin, BadgeProps, Box, BoxProps, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ImageSizes } from 'src/constants/ImageSizes';
import useImageCache from 'src/hooks/useImageCache';
import { getImageSize, getIconSize } from 'src/utils/iconImageUtils';

export interface PersonIndicators {
    isAlert?: boolean;
    isArchived?: boolean;
    isCheckedIn?: boolean;
    isOnCall?: boolean;
    isOnHold?: boolean;
    isPinned?: boolean;
    isRemovable?: boolean;
    isResolved?: boolean;
}

export interface PersonProps extends PersonIndicators {
    id?: string;
    firstName?: string;
    lastName?: string;
    size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
    pinnedBadgeColor?: BadgeProps['color'];
    badgePosition?: BadgeOrigin;
    badgeSize?: FontAwesomeIconProps['size'];
    children?: React.ReactNode;
    image?: string;
    useUpdatedImage?: boolean;
    containerProps?: BoxProps;
    variant?: 'circular' | 'rounded';
}

export default function Person(props: PersonProps) {
    const {
        id,
        firstName,
        lastName,
        size = ImageSizes.Medium,
        pinnedBadgeColor,
        badgePosition = {},
        badgeSize,
        isAlert = false,
        isArchived = false,
        isCheckedIn = false,
        isOnCall = false,
        isOnHold = false,
        isPinned = false,
        isRemovable = false,
        isResolved = false,
        children,
        image,
        useUpdatedImage = false,
        containerProps,
        variant = 'circular',
    } = props;

    const { spacing } = useTheme();

    // Set default container props
    const {
        display = 'flex',
        alignItems = 'center',
        flexWrap = 'nowrap',
        ...restContainerProps
    } = containerProps || {};

    const imageContainerRef = useRef(null);
    const [imageData, { isLoading, refetch: refetchImage }] = useImageCache(id, image, {
        imageContainerRef,
    });

    const initials = useMemo(
        () => `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toLocaleUpperCase(),
        [firstName, lastName]
    );

    const {
        badgeAnchorPosition,
        badgeColor,
        badgeContent,
    }: {
        badgeAnchorPosition: BadgeOrigin;
        badgeColor: BadgeProps['color'];
        badgeContent?: ReactNode;
    } = useMemo(() => {
        const iconSize = badgeSize || getIconSize(size);

        if (isAlert) {
            return {
                badgeColor: 'error',
                badgeContent: (
                    <Box sx={{ transform: 'scale(0.75)' }}>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="white"
                            size={iconSize}
                            fixedWidth
                        />
                    </Box>
                ),
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isRemovable) {
            return {
                badgeColor: 'black',
                badgeContent: (
                    <FontAwesomeIcon icon={faXmark} color="white" size={iconSize} fixedWidth />
                ),
                badgeAnchorPosition: {
                    vertical: 'top',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isResolved) {
            return {
                badgeColor: 'success',
                badgeContent: (
                    <FontAwesomeIcon icon={faCheck} color="white" size={iconSize} fixedWidth />
                ),
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isOnHold) {
            return {
                badgeColor: 'warning',
                badgeContent: (
                    <FontAwesomeIcon icon={faHand} color="white" size={iconSize} fixedWidth />
                ),
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isArchived) {
            return {
                badgeColor: 'black',
                badgeContent: (
                    <FontAwesomeIcon icon={faBoxArchive} color="white" size={iconSize} fixedWidth />
                ),
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isCheckedIn) {
            return {
                badgeColor: 'success',
                badgeContent: (
                    <FontAwesomeIcon
                        icon={faCalendarCheck}
                        color="white"
                        size={iconSize}
                        fixedWidth
                    />
                ),
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isPinned) {
            return {
                badgeColor: pinnedBadgeColor || 'primary',
                badgeContent: (
                    <FontAwesomeIcon icon={faHeart} color="white" size={iconSize} fixedWidth />
                ),
                badgeAnchorPosition: {
                    vertical: 'top',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        if (isOnCall) {
            return {
                badgeColor: 'success',
                badgeAnchorPosition: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    ...badgePosition,
                },
            };
        }

        return {
            badgeColor: 'default',
            badgeContent: null,
            badgeAnchorPosition: {
                vertical: 'bottom',
                horizontal: 'right',
                ...badgePosition,
            },
        };
    }, [
        badgePosition,
        badgeSize,
        isAlert,
        isArchived,
        isCheckedIn,
        isOnCall,
        isOnHold,
        isPinned,
        isRemovable,
        isResolved,
        pinnedBadgeColor,
        size,
    ]);

    const { flexDirection = 'row', gap } = useMemo(() => {
        switch (size) {
            case ImageSizes.XSmall:
            case ImageSizes.Small:
                return {
                    flexDirection: 'row',
                    gap: spacing(0.5),
                };
            case ImageSizes.Large:
                return {
                    flexDirection: 'column',
                    gap: spacing(0.5),
                };
            case ImageSizes.XLarge:
                return {
                    flexDirection: 'column',
                    gap: spacing(2.5),
                };
            case ImageSizes.Medium:
            default:
                return {
                    flexDirection: 'row',
                    gap: spacing(1.5),
                };
        }
    }, [size, spacing]);

    useEffect(() => {
        if (useUpdatedImage) {
            refetchImage();
        }
    }, [refetchImage, useUpdatedImage]);

    return (
        <Box
            {...restContainerProps}
            className={clsx('person-container', size)}
            sx={{
                display: display,
                alignItems: alignItems,
                flexWrap: flexWrap,
                flexDirection: flexDirection,
                gap: gap,
                ...(restContainerProps?.sx || {}),
            }}
        >
            <Box
                ref={imageContainerRef}
                data-dd-privacy="mask"
                sx={{
                    position: 'relative',
                    lineHeight: 0,
                }}
            >
                {!imageData && isLoading ? (
                    <Skeleton
                        animation="wave"
                        variant={variant}
                        width={getImageSize(size)}
                        height={getImageSize(size)}
                        className={size}
                    />
                ) : (
                    <Badge
                        badgeContent={badgeContent}
                        color={badgeColor}
                        anchorOrigin={badgeAnchorPosition}
                        overlap={variant === 'circular' ? 'circular' : 'rectangular'}
                        variant={isOnCall ? 'dot' : 'standard'}
                        invisible={size === ImageSizes.XSmall || size === ImageSizes.Small}
                        className={size}
                    >
                        <Avatar
                            variant={variant}
                            alt={`${firstName} ${lastName}`}
                            src={imageData}
                            className={size}
                            sx={{ fontWeight: 'regular' }}
                            data-testid="person-avatar"
                        >
                            {initials}
                        </Avatar>
                    </Badge>
                )}
            </Box>
            {children && (
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
}
