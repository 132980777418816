import { HospiceStatus } from 'src/constants/HospiceStatus';

export function validateHospiceStatus(value?: string): HospiceStatus {
    switch (value) {
        case 'HOSPICE_CARE':
        case 'STANDARD_CARE':
        case 'UNKNOWN':
            return value as HospiceStatus;
        default:
            return HospiceStatus.UNKNOWN;
    }
}

export function isHospice(hospiceStatus?: HospiceStatus): boolean {
    return validateHospiceStatus(hospiceStatus) === HospiceStatus.HOSPICE_CARE;
}
