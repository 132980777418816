import { useContext } from 'react';

import { Button, Typography, Box } from '@mui/material';

import { AnalyticsContext, ObjectTypes } from '../../AnalyticsContext';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import LightbulbIcon from 'src/assets/images/insights-icons/lightbulb-icon.svg';
import NewChip from 'src/assets/images/insights-icons/new-chip.svg';

import './styles/ExploreInsights.styles.scss';

function ExploreInsightsDrawer(props: DrawerComponentProps) {
    const { onClose, onNext } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const handleGoBack = () => {
        trackEvent('Later Button Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Later',
            context: 'Explore Insights Drawer',
        });
        onClose();
    };

    return (
        <>
            <DrawerTitle sx={{ pt: 4 }}>
                <Box className="lightbulb__container">
                    <img src={LightbulbIcon} alt="" className="lightbulb__icon" />
                    <img src={NewChip} alt="" className="lightbulb__newchip" />
                </Box>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    Explore New Insights
                </Typography>
            </DrawerTitle>
            <DrawerBody sx={{ pt: 1 }}>
                <Typography variant="body2">
                    Sage surfaces key insights to identify opportunities at your community. Review
                    the newest data and consider actions to take.
                </Typography>
            </DrawerBody>
            <DrawerFooter sx={{ gap: 2.5 }}>
                <Button
                    className="ctaButton"
                    fullWidth
                    color="primary"
                    size="large"
                    variant="contained"
                    disableElevation
                    onClick={onNext}
                >
                    View Insights
                </Button>

                <Button
                    className="ctaButton"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={handleGoBack}
                    size="large"
                >
                    Later
                </Button>
            </DrawerFooter>
        </>
    );
}

export default ExploreInsightsDrawer;
