import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { faPhoneFlip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CheckInStatuses } from '../../constants/CheckIn';
import ResidentCard from '../residents/ResidentCard';
import { AnalyticsContext } from 'src/AnalyticsContext';
import { selectIsIndependentLiving } from 'src/store/configSlice';
import { Formats, formatPhoneNumber } from 'src/utils/formUtils';

import '../../assets/styles/buttonStyles.scss';

const ResidentsInUnit = (props) => {
    const { analyticsPrefix, onResidentClick, showEmptyMessage = false, unit } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const isIndependentLiving = useSelector(selectIsIndependentLiving);
    const unitCheckedIn = isIndependentLiving && unit?.checkIn?.status === CheckInStatuses.Complete;

    const trackCallButtonClick = (buttonName, additionalProperties = {}) => {
        trackEvent(`${analyticsPrefix}.${buttonName}.button.tap`, additionalProperties);
    };

    return (
        <>
            {unit?.currentOccupants?.length > 0 && (
                <>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            mb: 1,
                        }}
                    >
                        Residents in unit
                    </Typography>

                    <Stack
                        sx={{
                            gap: 2,
                        }}
                    >
                        {unit.currentOccupants?.map?.((occupant, index) => (
                            <ResidentCard
                                analyticsPrefix={analyticsPrefix}
                                index={index}
                                key={occupant.id}
                                unitIsCheckedIn={unitCheckedIn}
                                resident={occupant}
                                onResidentClick={
                                    onResidentClick ? () => onResidentClick(occupant) : null
                                }
                            />
                        ))}
                    </Stack>

                    <Stack
                        sx={{
                            gap: 2,
                            mt: 2.5,
                        }}
                    >
                        {unit?.currentOccupants?.map?.(
                            (occupant, index) =>
                                occupant?.personalPhone && (
                                    <Button
                                        key={occupant.id}
                                        fullWidth
                                        disableElevation
                                        variant="outlined"
                                        endIcon={<FontAwesomeIcon icon={faPhoneFlip} />}
                                        href={`tel:${occupant.personalPhone}`}
                                        className="ctaButton iconRight"
                                        onClick={() =>
                                            trackCallButtonClick('resident-phone-number', {
                                                listIndex: index,
                                            })
                                        }
                                    >
                                        <Stack
                                            direction="row"
                                            sx={{
                                                gap: 0.5,
                                            }}
                                        >
                                            <span>Call {occupant.firstName}</span>
                                            <b>
                                                {formatPhoneNumber(
                                                    occupant.personalPhone,
                                                    Formats.National
                                                )}
                                            </b>
                                        </Stack>
                                    </Button>
                                )
                        )}

                        {unit.phoneNumber && (
                            <Button
                                fullWidth
                                disableElevation
                                variant="outlined"
                                endIcon={<FontAwesomeIcon icon={faPhoneFlip} />}
                                href={`tel:${unit.phoneNumber}`}
                                className="ctaButton iconRight"
                                onClick={() => trackCallButtonClick('unit-phone-number')}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        gap: 0.5,
                                    }}
                                >
                                    <span>Call Unit</span>
                                    <b>{formatPhoneNumber(unit.phoneNumber, Formats.National)}</b>
                                </Stack>
                            </Button>
                        )}
                    </Stack>
                </>
            )}
            {!unit?.currentOccupants?.length > 0 && showEmptyMessage && (
                <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{
                        mb: 1.5,
                        color: 'grey.700',
                    }}
                >
                    No residents in unit
                </Typography>
            )}
        </>
    );
};

export default ResidentsInUnit;
