const Enterprises = {
    Atlantis: 'atlantis',
    Bridge: 'bridge',
    Dominion: 'dominion',
    Frontier: 'frontier',
    Lenity: 'lenity',
    Sonida: 'sonida',
};

export default Enterprises;
