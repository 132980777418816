import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
    CareZone,
    Facility,
    Group,
    ServiceConfiguration,
    StaffMember,
    StaffMemberFacilityMetadata,
} from './sageAdminApi';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { CareTypes } from 'src/constants/CareTypes';
import Groups from 'src/constants/Groups';

export interface AppConfigState {
    allGroups: Group[];
    config: ServiceConfiguration;
    currentUser: StaffMember;
    enterpriseId: string;
    facility: Facility;
    facilityCareZones: CareZone[];
    facilityId: string;
    facilityMetadata: StaffMemberFacilityMetadata;
    staffMemberId: string;
}

export const initialState: AppConfigState = {
    allGroups: [],
    config: {},
    currentUser: {},
    enterpriseId: '',
    facility: {
        facilityConfig: {
            isAlertLocationEnabled: false,
            routineCheckIntervalInMin: 0, // 0 == disabled / off
        },
    },
    facilityCareZones: [],
    facilityId: '',
    facilityMetadata: {},
    staffMemberId: '',
};

export const configSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        resetAppConfig: (state) => {
            // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
            state = initialState;
        },
        setAllGroups: (state, { payload }) => {
            state.allGroups = sortBy(payload, ['name']);
        },
        setConfig: (state, { payload }) => {
            state.config = payload;
        },
        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload;
        },
        setEnterpriseId: (state, { payload }) => {
            state.enterpriseId = payload;
        },
        setFacility: (state, { payload }) => {
            state.facility = payload;
        },
        setFacilityCareZones: (state, { payload }) => {
            state.facilityCareZones = payload;
        },
        setFacilityId: (state, { payload }) => {
            state.facilityId = payload;
        },
        setFacilityMetadata: (state, { payload }) => {
            state.facilityMetadata = payload;
        },
        setStaffMemberId: (state, { payload }) => {
            state.staffMemberId = payload;
        },
    },
    selectors: {
        selectAllGroups: (sliceState: AppConfigState) => sliceState.allGroups,
        selectAppConfig: (sliceState: AppConfigState) => sliceState,
        selectConfig: (sliceState: AppConfigState) => sliceState.config,
        selectCurrentUser: (sliceState: AppConfigState) => sliceState.currentUser,
        selectEnterpriseId: (sliceState: AppConfigState) => sliceState.enterpriseId,
        selectFacility: (sliceState: AppConfigState) => sliceState.facility,
        selectFacilityId: (sliceState: AppConfigState) => sliceState.facilityId,
        selectFacilityCareZones: (sliceState: AppConfigState) => sliceState.facilityCareZones,
        selectIsAlertLocationEnabled: (sliceState: AppConfigState) =>
            sliceState.facility?.facilityConfig?.isAlertLocationEnabled || false,
        selectRoutineCheckInterval: (sliceState: AppConfigState) =>
            sliceState.facility?.facilityConfig?.routineCheckIntervalInMin || 0,
        selectFacilityMetadata: (sliceState: AppConfigState) => sliceState.facilityMetadata,
        selectStaffMemberId: (sliceState: AppConfigState) => sliceState.staffMemberId,
        selectIsConfigReady: (sliceState: AppConfigState) => {
            const {
                allGroups,
                config,
                currentUser,
                enterpriseId,
                facility,
                facilityId,
                facilityMetadata,
                staffMemberId,
            } = sliceState;

            return !(
                isEmpty(allGroups) ||
                isEmpty(config) ||
                isEmpty(currentUser) ||
                isEmpty(enterpriseId) ||
                isEmpty(facility) ||
                isEmpty(facilityId) ||
                isEmpty(facilityMetadata) ||
                isEmpty(staffMemberId)
            );
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    resetAppConfig,
    setAllGroups,
    setConfig,
    setCurrentUser,
    setEnterpriseId,
    setFacility,
    setFacilityId,
    setFacilityCareZones,
    setFacilityMetadata,
    setStaffMemberId,
} = configSlice.actions;

export const {
    selectAllGroups,
    selectAppConfig,
    selectConfig,
    selectCurrentUser,
    selectEnterpriseId,
    selectFacility,
    selectFacilityId,
    selectFacilityCareZones,
    selectFacilityMetadata,
    selectIsAlertLocationEnabled,
    selectIsConfigReady,
    selectRoutineCheckInterval,
    selectStaffMemberId,
} = configSlice.selectors;

export const selectFacilityOnCallState = createSelector(
    selectFacilityId,
    selectCurrentUser,
    (facilityId, currentUser) =>
        currentUser?.onCallStates?.find((onCallState) => onCallState.facilityId === facilityId)
);

export const selectIsOnCall = createSelector(
    selectFacilityOnCallState,
    (facilityOnCallState) => !!facilityOnCallState?.isOnCall
);

export const selectStaffMemberFacilityConfigs = createSelector(
    selectFacilityId,
    selectCurrentUser,
    (facilityId, staffMemberProfile) =>
        staffMemberProfile?.staffMemberFacilityConfigs?.find(
            (config) => config.facilityId === facilityId
        )
);

export const selectIsIndependentLiving = createSelector(
    selectStaffMemberFacilityConfigs,
    (configs) =>
        !!configs?.careZones?.some(
            (careZone) =>
                careZone.name?.toLowerCase() === 'independent living' ||
                careZone.unitTags?.careTypes?.includes(CareTypes.IndependentLiving)
        )
);

export const selectUserCareZoneIds = createSelector(
    selectFacilityCareZones,
    selectStaffMemberFacilityConfigs,
    (facilityCareZones, staffMemberFacilityConfig) => {
        if (
            !facilityCareZones ||
            !staffMemberFacilityConfig ||
            !staffMemberFacilityConfig.careZones
        ) {
            return [];
        }

        const facilityCareZoneIds = new Set(facilityCareZones.map((careZone) => careZone.id!));

        return staffMemberFacilityConfig.careZones
            .map((careZone) => careZone.id!)
            .filter((id): id is string => facilityCareZoneIds.has(id));
    }
);

export const selectUserGroupIds = createSelector(selectFacilityMetadata, (facilityMetadata) =>
    facilityMetadata?.groups?.map((group) => group.groupId)
);

export const selectCareGroupId = createSelector(
    selectAllGroups,
    (groups) => groups?.find((group) => group.name === Groups.CareStaff)?.id
);

export const selectSageSupportGroupId = createSelector(
    selectAllGroups,
    (groups) => groups?.find((group) => group.name === Groups.SageSupport)?.id
);

export const selectIsUserInSageSupportGroup = createSelector(
    selectUserGroupIds,
    selectSageSupportGroupId,
    (userGroupIds, sageSupportGroupId) =>
        sageSupportGroupId && userGroupIds?.includes(sageSupportGroupId)
);

export default configSlice.reducer;
