import { Box, Typography } from '@mui/material';

function AppVersion() {
    return (
        <Box
            sx={{
                width: '100%',
                textAlign: 'center',
                py: 2.5,
            }}
        >
            <Typography
                variant="subtitle1"
                align="center"
                sx={{
                    color: 'gray.500',
                }}
            >
                App Version {import.meta.env.VITE_VERSION}
            </Typography>
        </Box>
    );
}

export default AppVersion;
