import mixpanel from 'mixpanel-browser';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { init, identify } from '@chamaeleonidae/chmln';
import { datadogRum } from '@datadog/browser-rum';

import useEnvironment from './hooks/useEnvironment';
import {
    selectCurrentUser,
    selectIsOnCall,
    selectStaffMemberId,
    selectFacilityMetadata,
    selectFacility,
    selectStaffMemberFacilityConfigs,
} from './store/configSlice';
import { CareZone, StaffMemberGroupMembership } from './store/sageAdminApi';
import useCareZones from 'src/hooks/useCareZones';

declare global {
    interface Window {
        enableLogEvents?: () => void;
        disableLogEvents?: () => void;
        mixpanel?: any;
        chmln?: any;
    }
}

export type EventBody = {
    [key: string]: any;
};

export type TrackEventOptions = {
    sendToDataDog?: boolean;
    sendToMixpanel?: boolean;
};

export const ObjectTypes = {
    Backdrop: 'Backdrop',
    Banner: 'Banner',
    Button: 'Button',
    Card: 'Card',
    Checkbox: 'Checkbox',
    Dialog: 'Dialog',
    Drawer: 'Drawer',
    Form: 'Form',
    Icon: 'Icon',
    Keypress: 'Keypress',
    Link: 'Link',
    Notification: 'Notification',
    Input: 'Input',
    Page: 'Page',
    Radio: 'Radio',
    Select: 'Select',
};

export const AnalyticsContext = createContext({
    baseEventBody: {} as EventBody,
    getPrefix: (_existingContext: string | undefined, _newContext: string | undefined) => {},
    trackEvent: (
        _eventName: string,
        _eventBody: EventBody = {},
        _options: TrackEventOptions = {}
    ) => {},
    trackPageView: (_pageName: string) => {},
});

const IS_DISABLED = !import.meta.env.PROD || !import.meta.env.VITE_MIXPANEL_TOKEN;

interface AnalyticsProviderProps {
    children: React.ReactNode;
}

function AnalyticsProvider(props: AnalyticsProviderProps) {
    const { user: auth0User } = useAuth0();
    const { pathname } = useLocation();
    const isOnCall = useSelector(selectIsOnCall);
    const facility = useSelector(selectFacility);
    const currentUserProfile = useSelector(selectCurrentUser);
    const staffMemberProfileId = useSelector(selectStaffMemberId);
    const facilityMetadata = useSelector(selectFacilityMetadata);
    const staffMemberFacilityConfigs = useSelector(selectStaffMemberFacilityConfigs);
    const { isProd, isDev, isBouncer } = useEnvironment();
    const { userCareZones } = useCareZones();

    const [shouldLogEvents, setShouldLogEvents] = useState(false);

    useEffect(() => {
        mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);
        // Make mixpanel available on window for Chameleon integration
        window.mixpanel = mixpanel;

        if (IS_DISABLED) {
            mixpanel.disable();
        }

        if (window) {
            window.enableLogEvents = () => {
                console.info('Event logging enabled');
                setShouldLogEvents(true);
            };
            window.disableLogEvents = () => {
                console.info('Event logging disabled');
                setShouldLogEvents(false);
            };
        }

        return () => {
            if (window) {
                window.enableLogEvents = undefined;
                window.disableLogEvents = undefined;
            }
        };
    }, []);

    useEffect(() => {
        if (isProd || isDev || isBouncer) {
            /**
             * Initialize Chameleon
             *
             * Note: the custom data attribute "data-chameleon-id" is attached to
             * any element that is expected to be queried by a tour in Chameleon, usually as
             * a trigger criteria for displaying the tour to the user.
             */
            init(import.meta.env.VITE_CHAMELEON_ID, {
                fastUrl: import.meta.env.VITE_CHAMELEON_URL,
            });
        }
    }, [isBouncer, isDev, isProd]);

    useEffect(() => {
        if (auth0User && currentUserProfile) {
            try {
                datadogRum?.setUser({
                    id: auth0User?.sub,
                    name: `${auth0User?.given_name ?? ''} ${auth0User?.family_name ?? ''}`,
                    email: currentUserProfile?.email,
                    enterpriseId: facility?.enterprise?.id,
                    enterpriseName: facility?.enterprise?.name,
                    facilityId: facility?.id,
                    facilityName: facility?.name,
                    groups: facilityMetadata?.groups?.map(
                        ({ groupName }: StaffMemberGroupMembership) => groupName
                    ),
                });
            } catch (e) {
                console.error('Unable to attach user to datadog:', e);
            }
        }
    }, [auth0User, currentUserProfile, facility, facilityMetadata]);

    const baseEventBody = useMemo(
        () => ({
            pathname,
            appVersion: import.meta.env.VITE_VERSION,
            enterpriseId: facility?.enterprise?.id,
            enterpriseName: facility?.enterprise?.name,
            facilityId: facility?.id,
            facilityName: facility?.name,
            facilityStatus: facility?.status,
            groups: facilityMetadata?.groups?.map(
                ({ groupName }: StaffMemberGroupMembership) => groupName
            ),
            profileId: staffMemberProfileId,
            userId: staffMemberProfileId,
            isOnCall,
        }),
        [facility, facilityMetadata, isOnCall, pathname, staffMemberProfileId]
    );

    useEffect(() => {
        // Identify user in Chameleon
        if (
            (isProd || isDev || isBouncer) &&
            staffMemberFacilityConfigs &&
            auth0User?.sub &&
            facilityMetadata
        ) {
            try {
                identify(auth0User?.sub, {
                    ...baseEventBody,
                    isAgencyStaff: staffMemberFacilityConfigs?.isAgencyStaff,
                    isCareAdmin: staffMemberFacilityConfigs?.isCareAdmin,
                    jobTitle: staffMemberFacilityConfigs?.jobTitle,
                    userCareZones: userCareZones?.map(({ name }: CareZone) => name),
                });
            } catch (error) {
                datadogRum.addError(error, { message: 'Error identifying user in Chameleon' });
            }
        }
    }, [
        auth0User,
        baseEventBody,
        facilityMetadata,
        isBouncer,
        isDev,
        isProd,
        staffMemberFacilityConfigs,
        userCareZones,
    ]);

    // Track user actions in Datadog
    // See https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm#instrument-your-code
    const trackDatadogAction = (actionName: string, payload: EventBody) => {
        try {
            datadogRum?.addAction(actionName, payload);
        } catch (error) {
            console.error('Error tracking datadog action:', error);
        }
    };

    const trackMixpanelEvent = (eventName: string, payload: EventBody) => {
        try {
            mixpanel?.track?.(eventName, payload);
        } catch (error) {
            console.error('Error tracking mixpanel event:', error);
        }
    };

    const trackEvent = useCallback(
        (eventName: string, eventBody: EventBody = {}, options: TrackEventOptions = {}) => {
            const fullEventBody = { ...baseEventBody, ...eventBody };

            const { sendToMixpanel = true, sendToDataDog = true } = options;

            if (shouldLogEvents) {
                console.info('\nEvent:', eventName);
                console.table(fullEventBody);
            }

            if (IS_DISABLED) {
                return;
            }

            if (!eventName) {
                console.warn('No event name provided');
            } else {
                sendToDataDog && trackDatadogAction(eventName, eventBody);
                sendToMixpanel && trackMixpanelEvent(eventName, fullEventBody);
            }
        },
        [baseEventBody, shouldLogEvents]
    );

    const trackPageView = useCallback(
        (pageName: string) => {
            trackEvent('Page Viewed', { pageName, objectType: ObjectTypes.Page });
        },
        [trackEvent]
    );

    const getPrefix = useCallback(
        (existingContext?: string, newContext?: string) =>
            !!existingContext ? `${existingContext}.${newContext}` : newContext,
        []
    );

    const contextValues = useMemo(
        () => ({ baseEventBody, getPrefix, trackEvent, trackPageView }),
        [baseEventBody, getPrefix, trackEvent, trackPageView]
    );

    return (
        <AnalyticsContext.Provider value={contextValues}>
            {props.children}

            {/* Hidden placeholder divs used for Chameleon */}
            <div data-chameleon-id="care-confirmation-enabled" style={{ visibility: 'hidden' }} />
        </AnalyticsContext.Provider>
    );
}

export default AnalyticsProvider;
