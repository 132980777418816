export const CheckInReasons = {
    ButtonPressed: 'BUTTON_PRESSED',
    CalledResident: 'CALLED_RESIDENT',
    SeenInPerson: 'SEEN_IN_PERSON',
    TeamMemberVerified: 'TEAM_MEMBER_VERIFIED',
    VerifiedByIntegration: 'VERIFIED_BY_INTEGRATION',
};

export const CheckInStatuses = {
    Unknown: 'UNKNOWN',
    Pending: 'PENDING',
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    SkippedOnHold: 'SKIPPED_ON_HOLD',
};

export const CheckInStatusLabels = {
    [CheckInStatuses.Unknown]: 'Unknown',
    [CheckInStatuses.Pending]: 'Not Checked-In',
    [CheckInStatuses.Complete]: 'Checked-In',
    [CheckInStatuses.Expired]: 'Expired',
    [CheckInStatuses.SkippedOnHold]: 'On Hold',
};

export type ValidCheckInStatus = (typeof CheckInStatuses)[keyof typeof CheckInStatuses];
