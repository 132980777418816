import { sageBaseMessageOrchestrationApi as api } from './sageBaseMessageOrchestrationApi';

export const addTagTypes = [] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            createOrUpdatePhoneNumberSubscription: build.mutation<
                CreateOrUpdatePhoneNumberSubscriptionApiResponse,
                CreateOrUpdatePhoneNumberSubscriptionApiArg
            >({
                query: (queryArg) => ({
                    url: `/phoneNumberSubscriptions`,
                    method: 'POST',
                    body: queryArg.createOrUpdatePhoneNumberSubscriptionRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityRid: queryArg.facilityRid },
                }),
            }),
            getPhoneNumberSubscription: build.query<
                GetPhoneNumberSubscriptionApiResponse,
                GetPhoneNumberSubscriptionApiArg
            >({
                query: (queryArg) => ({
                    url: `/phoneNumberSubscriptions`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: {
                        facilityRid: queryArg.facilityRid,
                        recipientPhoneNumber: queryArg.recipientPhoneNumber,
                        senderPhoneNumber: queryArg.senderPhoneNumber,
                    },
                }),
            }),
            getPhoneNumberSubscriptions: build.query<
                GetPhoneNumberSubscriptionsApiResponse,
                GetPhoneNumberSubscriptionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/phoneNumberSubscriptions/bulk`,
                    method: 'POST',
                    body: queryArg.getPhoneNumberSubscriptionsRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { facilityRid: queryArg.facilityRid },
                }),
            }),
            findWebPushNotificationSubscriptions: build.query<
                FindWebPushNotificationSubscriptionsApiResponse,
                FindWebPushNotificationSubscriptionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/recipients/webPushNotifications`,
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        facilityRid: queryArg.facilityRid,
                        enterpriseRid: queryArg.enterpriseRid,
                        recipientIds: queryArg.recipientIds,
                        endpoints: queryArg.endpoints,
                        webPushSubscriptionRids: queryArg.webPushSubscriptionRids,
                    },
                }),
            }),
            createOrUpdateWebPushNotificationSubscription: build.mutation<
                CreateOrUpdateWebPushNotificationSubscriptionApiResponse,
                CreateOrUpdateWebPushNotificationSubscriptionApiArg
            >({
                query: (queryArg) => ({
                    url: `/recipients/webPushNotifications`,
                    method: 'PUT',
                    body: queryArg.webPushNotificationSubscription,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'User-Agent': queryArg['User-Agent'],
                    },
                    params: {
                        facilityRid: queryArg.facilityRid,
                        enterpriseRid: queryArg.enterpriseRid,
                    },
                }),
            }),
            deleteWebPushNotificationSubscriptions: build.mutation<
                DeleteWebPushNotificationSubscriptionsApiResponse,
                DeleteWebPushNotificationSubscriptionsApiArg
            >({
                query: (queryArg) => ({
                    url: `/recipients/webPushNotifications`,
                    method: 'DELETE',
                    headers: { Authorization: queryArg?.authorization },
                    params: {
                        facilityRid: queryArg.facilityRid,
                        enterpriseRid: queryArg.enterpriseRid,
                        recipientIds: queryArg.recipientIds,
                        endpoints: queryArg.endpoints,
                        webPushSubscriptionRids: queryArg.webPushSubscriptionRids,
                    },
                }),
            }),
            sendMessage: build.mutation<SendMessageApiResponse, SendMessageApiArg>({
                query: (queryArg) => ({
                    url: `/messages`,
                    method: 'POST',
                    body: queryArg.sendMessageRequest,
                    headers: { Authorization: queryArg?.authorization },
                }),
            }),
            sendMessageToRecipientIds: build.mutation<
                SendMessageToRecipientIdsApiResponse,
                SendMessageToRecipientIdsApiArg
            >({
                query: (queryArg) => ({
                    url: `/messages/sendToRecipientIds`,
                    method: 'POST',
                    body: queryArg.sendMessageToRecipientIdsRequest,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                }),
            }),
            updateMessageSent: build.mutation<
                UpdateMessageSentApiResponse,
                UpdateMessageSentApiArg
            >({
                query: (queryArg) => ({
                    url: `/messages/${queryArg.messageRid}/messagesSent`,
                    method: 'PUT',
                    body: queryArg.body,
                    params: { messageSentRid: queryArg.messageSentRid },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageMessageOrchestrationApi };
export type CreateOrUpdatePhoneNumberSubscriptionApiResponse = unknown;
export type CreateOrUpdatePhoneNumberSubscriptionApiArg = {
    authorization?: string;
    facilityRid?: string;
    'x-sage-profile-rid'?: string;
    createOrUpdatePhoneNumberSubscriptionRequest: CreateOrUpdatePhoneNumberSubscriptionRequest;
};
export type GetPhoneNumberSubscriptionApiResponse = /** status 200 OK */ PhoneNumberSubscription;
export type GetPhoneNumberSubscriptionApiArg = {
    authorization?: string;
    facilityRid?: string;
    'x-sage-profile-rid'?: string;
    /** Recipient phone number */
    recipientPhoneNumber: string;
    /** Sender phone number */
    senderPhoneNumber: string;
};
export type GetPhoneNumberSubscriptionsApiResponse = /** status 200 OK */ PhoneNumberSubscription[];
export type GetPhoneNumberSubscriptionsApiArg = {
    authorization?: string;
    facilityRid?: string;
    'x-sage-profile-rid'?: string;
    getPhoneNumberSubscriptionsRequest: GetPhoneNumberSubscriptionsRequest;
};
export type FindWebPushNotificationSubscriptionsApiResponse =
    /** status 200 OK */ FindWebPushNotificationSubscriptionsResponse;
export type FindWebPushNotificationSubscriptionsApiArg = {
    authorization?: string;
    facilityRid?: string;
    enterpriseRid?: string;
    /** List of recipientIds */
    recipientIds?: string[];
    /** List of endpoints */
    endpoints?: string[];
    webPushSubscriptionRids?: string[];
};
export type CreateOrUpdateWebPushNotificationSubscriptionApiResponse =
    /** status 200 OK */ RidResponse;
export type CreateOrUpdateWebPushNotificationSubscriptionApiArg = {
    authorization?: string;
    facilityRid?: string;
    enterpriseRid?: string;
    'User-Agent'?: string;
    webPushNotificationSubscription: WebPushNotificationSubscription;
};
export type DeleteWebPushNotificationSubscriptionsApiResponse = /** status 200 OK */ RidArray;
export type DeleteWebPushNotificationSubscriptionsApiArg = {
    authorization?: string;
    facilityRid?: string;
    enterpriseRid?: string;
    /** recipient identifier */
    recipientIds?: string[];
    /** subscription endpoints */
    endpoints?: string[];
    webPushSubscriptionRids?: string[];
};
export type SendMessageApiResponse = /** status 200 OK */ SendMessageResponse;
export type SendMessageApiArg = {
    authorization?: string;
    sendMessageRequest: SendMessageRequest;
};
export type SendMessageToRecipientIdsApiResponse =
    /** status 200 OK */ SendMessageToRecipientIdsResponse;
export type SendMessageToRecipientIdsApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    sendMessageToRecipientIdsRequest: SendMessageToRecipientIdsRequest;
};
export type UpdateMessageSentApiResponse = /** status 200 OK */ RidResponse;
export type UpdateMessageSentApiArg = {
    /** RID of corresponding message */
    messageRid: string;
    /** RID of corresponding messageSent */
    messageSentRid: string;
    body: {
        status?: string;
        statusTimestamp?: string;
    };
};
export type CreateOrUpdatePhoneNumberSubscriptionRequest = {
    recipientPhoneNumber: string;
    senderPhoneNumber: string;
    status: string;
};
export type PhoneNumberSubscription = {
    recipientPhoneNumber: string;
    senderPhoneNumber: string;
    status: string;
};
export type GetPhoneNumberSubscriptionsRequest = {
    recipientPhoneNumbers: string[];
    senderPhoneNumbers: string[];
};
export type WebPushNotificationSubscription = {
    recipientId: string;
    keys?: {
        /** The public key used for encryption. */
        p256dh?: string;
        /** The authentication secret used for encryption. */
        auth?: string;
    };
    /** The URL identifying the push endpoint. */
    endpoint: string;
    expirationTime?: string;
    rid?: string;
};
export type FindWebPushNotificationSubscriptionsResponse = {
    subscriptions?: WebPushNotificationSubscription[];
};
export type RidResponse = {
    rid?: string;
};
export type RidArray = string[];
export type Recipient = {
    phoneNumber?: string;
    recipientId?: string;
    subscriptions?: WebPushNotificationSubscription[];
};
export type SendMessageResponse = {
    successfullySent?: {
        recipient?: Recipient;
        /** the method by which the message was successfully delivered */
        deliveryMethod?: string;
    }[];
    failedToSend?: Recipient[];
};
export type MessageSenderOptions = {
    /** Required if delivery method includes TEXT */
    applicationId?: string;
    /** Required if delivery method includes TEXT */
    phoneNumber?: string;
};
export type SendMessageRequest = {
    message?: string;
    messageData?: {
        [key: string]: object;
    };
    recipients?: Recipient[];
    senderName?: string;
    senderOptions?: MessageSenderOptions;
    /** Delivery methods in order of preference.  Will attempt to send all messages using the first delivery methods, falling back to second delivery method and so on. */
    deliveryMethods?: string[];
    facilityRid?: string;
};
export type IneligibleRecipient = {
    recipientId: string;
    reason: string;
};
export type SendMessageToRecipientIdsResponse = {
    successfullySent?: {
        recipient?: Recipient;
        /** the method by which the message was successfully delivered */
        deliveryMethod?: string;
    }[];
    failedToSend?: Recipient[];
    ineligibleRecipients?: IneligibleRecipient[];
};
export type SendMessageToRecipientIdsRequest = {
    message: string;
    messageData?: {
        [key: string]: object;
    };
    recipientIds: RidArray;
    recipientType: string;
    facilityRid: string;
    textDeliveryStrategy?: string;
    senderStrategy: string;
};
export const {
    useCreateOrUpdatePhoneNumberSubscriptionMutation,
    useGetPhoneNumberSubscriptionQuery,
    useLazyGetPhoneNumberSubscriptionQuery,
    useGetPhoneNumberSubscriptionsQuery,
    useLazyGetPhoneNumberSubscriptionsQuery,
    useFindWebPushNotificationSubscriptionsQuery,
    useLazyFindWebPushNotificationSubscriptionsQuery,
    useCreateOrUpdateWebPushNotificationSubscriptionMutation,
    useDeleteWebPushNotificationSubscriptionsMutation,
    useSendMessageMutation,
    useSendMessageToRecipientIdsMutation,
    useUpdateMessageSentMutation,
} = injectedRtkApi;
